import * as d3 from "d3";

export function parseBubblePlotData(deploymentsCsv, detCountCsv) {
  const deployments = d3.csvParse(deploymentsCsv).map(function (d) {
    return {
      lonlat: [+d.lon, +d.lat],
      deployment: +d.deployment,
      station: d.station,
    };
  });

  const hourlyDetCount = d3
    .csvParse(detCountCsv)
    .map(function (d) {
      return {
        period: d3.isoParse(d.period),
        deployment: +d.deployment,
        N: +d.N,
        fullid: d.fullid,
      };
    })
    .filter(d => d.deployment > -1);

  return {
    deployments: deployments,
    hourlyDetCount: hourlyDetCount,
  };
}
