import { isEqual } from "lodash";
import {
  DetectionAction,
  DetectionRow,
  DetectionState,
  DetectionCountHistogram,
  DetectionCountAbacus,
} from "./detection-types";

const initialState: DetectionState = {
  summary: {
    loading: false,
    error: null,
  },
  table: {
    filters: null,
    status: "UNLOADED",
    error: null,
    rows: [] as DetectionRow[],
  },
  histogram: {
    filters: null,
    status: "UNLOADED",
    error: null,
    countPerInterval: [] as DetectionCountHistogram[],
  },
  abacus: {
    filters: null,
    status: "UNLOADED",
    error: null,
    countPerGroup: [] as DetectionCountAbacus[],
  },
  count: {
    loading: false,
  },
  downloads: {},
};

export default function detectionReducer(
  state: DetectionState = initialState,
  action: DetectionAction
): DetectionState {
  switch (action.type) {
    case "DETECTION_CLEAR_DATA":
      return {
        ...initialState,
      };
    case "DETECTION_SUMMARY_START_LOADING_DATA":
      return {
        ...state,
        summary: {
          ...state.summary,
          loading: true,
        },
      };
    case "DETECTION_SUMMARY_SET":
      return {
        ...state,
        summary: {
          ...state.summary,
          loading: false,
          detectionSummary: action.payload,
        },
      };
    case "DETECTION_SUMMARY_ERROR": {
      return {
        ...state,
        summary: {
          loading: false,
          detectionSummary: null,
          error: action?.payload?.message || "Error Fetching Detection Summary",
        },
      };
    }
    case "DETECTION_SET_TABLE":
      return {
        ...state,
        table: {
          ...state.table,
          rows: action.payload.rows,
          status: action.payload.state,
        },
      };
    case "DETECTION_TABLE_START_LOADING":
      return {
        ...state,
        table: {
          ...state.table,
          status: "INITIAL_LOADING",
          rows: [],
        },
      };
    case "DETECTION_RESET_TABLE":
      return {
        ...state,
        table: {
          status: "UNLOADED",
          rows: [],
        },
      };
    case "DETECTION_TABLE_COMPLETE":
      return {
        ...state,
        table: {
          ...state.table,
          status: "COMPLETE",
        },
      };
    case "DETECTION_TABLE_CANCEL": {
      return {
        ...state,
        table: {
          ...state.table,
          status: "CANCELED",
        },
      };
    }
    case "DETECTION_TABLE_ERROR": {
      return {
        ...state,
        table: {
          ...state.table,
          status: "ERROR",
          error: action?.payload?.message,
        },
      };
    }
    case "DETECTION_SET_HISTOGRAM_LOADING": {
      return {
        ...state,
        currentFilters: action.payload.filters,
        histogram: {
          ...state.histogram,
          filters: action.payload.filters,
          status: "LOADING",
          error: null,
        },
      };
    }
    case "DETECTION_SET_HISTOGRAM_INIT_LOADING": {
      return {
        ...state,
        currentFilters: action.payload.filters,
        histogram: {
          ...state.histogram,
          filters: action.payload.filters,
          status: "INITIAL_LOADING",
          error: null,
        },
      };
    }
    case "DETECTION_HISTOGRAM_ERROR": {
      return {
        ...state,
        histogram: {
          filters: action?.payload?.filters,
          status: "ERROR",
          error: action?.payload?.message,
          countPerInterval: [],
        },
      };
    }
    case "DETECTION_SET_HISTOGRAM": {
      if (isEqual(action.payload.filters, state.currentFilters)) {
        return {
          ...state,
          histogram: {
            filters: action.payload.filters,
            status: "COMPLETE",
            error: null,
            countPerInterval: action?.payload?.detCountsHistogram,
          },
        };
      }
      return state;
    }
    case "DETECTION_SET_ABACUS_LOADING": {
      return {
        ...state,
        abacus: {
          filters: action.payload.filters,
          countPerGroup: state.abacus.countPerGroup,
          status: "LOADING",
          error: null,
        },
      };
    }
    case "DETECTION_SET_ABACUS_INIT_LOADING": {
      return {
        ...state,
        abacus: {
          filters: action.payload.filters,
          countPerGroup: [],
          status: "INITIAL_LOADING",
          error: null,
        },
      };
    }
    case "DETECTION_SET_ABACUS_DATA": {
      if (isEqual(action.payload.filters, state.abacus.filters)) {
        return {
          ...state,
          abacus: {
            filters: action.payload.filters,
            countPerGroup: action.payload.countPerGroup,
            status: "COMPLETE",
            error: null,
          },
        };
      }
      return state;
    }
    case "DETECTION_SET_COUNT": {
      return {
        ...state,
        count: { loading: false, count: action.payload.count, filters: state.count.filters },
      };
    }
    case "DETECTION_COUNT_LOADING": {
      return {
        ...state,
        currentFilters: action.payload.filters,
        count: { loading: true, filters: action.payload.filters },
      };
    }
    case "DETECTION_DOWNLOAD_STATUS": {
      const downloads = { ...state.downloads };
      const dlState = downloads[action.payload.jobId];
      downloads[action.payload.jobId] = { ...dlState, ...action.payload };
      return {
        ...state,
        downloads,
      };
    }
    case "DETECTION_DOWNLOAD_CLEAR": {
      const downloads = { ...state.downloads };
      delete downloads[action.payload.jobId];
      return {
        ...state,
        downloads,
      };
    }
    default:
      return state;
  }
}
