import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import autoBind from "auto-bind/react";
import { withStyles } from "@material-ui/core/styles";

import FormDialog from "../common/FormDialog";
import { addDeployment, addStation } from "../../redux/deployments/deployments-actions";
import { deploymentLabels, positionLabels, deploymentSizeUnits, deviceLabels } from "./constants";
import GraphFieldInput from "../common/GraphFieldInput";
import StationPicker from "./StationPicker";
import {
  initialFormState,
  validateFormFields,
  typeCastValues,
  removeEmptyFields,
} from "../../helpers/common";
import { isEmpty } from "lodash";
import {
  parseBounds,
  validateBounds,
  validateEndTime,
  validateLatitude,
  validateLongitude,
} from "./utils";
import { Typography, IconButton } from "@material-ui/core";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
} from "@material-ui/icons";

const styles = theme => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
  },
  bodyText: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  heightContainer: {
    paddingTop: theme.spacing(2),
  },
  stationPickerContainer: {
    marginTop: theme.spacing(1),
  },
});

const fields = [
  {
    dataKey: "start",
    label: deploymentLabels.start.label,
    helperText: deploymentLabels.start.helperText,
    inputType: "datetime",
    width: 220,
    isRequired: true,
    disableFuture: true,
  },
  {
    dataKey: "end",
    label: deploymentLabels.end.label,
    helperText: deploymentLabels.end.helperText,
    inputType: "datetime",
    width: 220,
    disableFuture: true,
  },
  {
    dataKey: "latitude",
    label: positionLabels.latitude.label,
    helperText: positionLabels.latitude.helperText,
    inputType: "number",
  },
  {
    dataKey: "longitude",
    label: positionLabels.longitude.label,
    helperText: positionLabels.longitude.helperText,
    inputType: "number",
  },
  {
    dataKey: "depth",
    label: positionLabels.depth.label,
    helperText: positionLabels.depth.helperText,
    inputType: "measurement",
    valueWidth: 120,
    unitWidth: 60,
    unitOptions: deploymentSizeUnits,
    unitDefault: "m",
    isDefaultSelected: true,
  },
];

const deviceAttachmentFields = [
  {
    dataKey: "deviceId",
    label: deviceLabels.device.label,
    helperText: deviceLabels.device.helperText,
    inputType: "autocomplete",
    autoCompleteOptions: [],
    autoCompleteGroupBy: option => option.inStatus,
    autoCompleteLabel: "label",
    autoCompleteValueKey: "id",
    autoCompleteMultiple: false,
    isRequired: true,
    width: 260,
  },
  {
    dataKey: "height",
    label: deviceLabels.height.label,
    helperText: deviceLabels.height.helperText,
    inputType: "measurement",
    unitOptions: deploymentSizeUnits,
    unitDefault: "m",
    valueWidth: 100,
  },
];

class AddDeploymentDialog extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    autoBind(this);
  }

  initialState() {
    return {
      ...initialFormState(fields),
      deviceAttachments: [],
      startEndError: "",
    };
  }

  validate() {
    const { values, deviceAttachments } = this.state;
    // validate the top-level fields
    const { fieldErrors, formErrors } = validateFormFields(values, fields);

    if (this.positionEntered()) {
      const { latitude, longitude } = values;
      const missingMsg = "Required if entering position";
      const latError = latitude ? validateLatitude(latitude) : missingMsg;
      if (latError) fieldErrors.latitude = latError;
      const lonError = longitude ? validateLongitude(longitude) : missingMsg;
      if (lonError) fieldErrors.longitude = lonError;
    }

    const endTimeError = validateEndTime(values);

    if (endTimeError) {
      fieldErrors.end = endTimeError;
    }

    // validate each device attachment
    let devAttachErrors = false;
    deviceAttachments.forEach((da, i) => {
      const { fieldErrors } = validateFormFields(da.values, deviceAttachmentFields);
      this.handleDevAttErrors(fieldErrors, i);
      if (!isEmpty(fieldErrors)) devAttachErrors = true;
    });

    const startEndError = validateBounds(parseBounds(values));

    this.setState({ fieldErrors, formErrors, startEndError });
    return isEmpty(fieldErrors) && isEmpty(formErrors) && !startEndError && !devAttachErrors;
  }

  handleSubmit() {
    const { studyId, dispatch } = this.props;
    const { values } = this.state;

    if (this.validate()) {
      // clean top level fields
      const parsedValues = typeCastValues(values, fields);
      const cleanedValues = removeEmptyFields(parsedValues, fields);

      // reshape position fields (if position is entered)
      const positions = [];
      if (this.positionEntered()) {
        const { depth, latitude, longitude } = cleanedValues;
        const manualLatLon = { latitude, longitude };
        positions.push({ manualLatLon, depth });
      }

      // clean device attachment fields
      const deviceAttachments = this.state.deviceAttachments.map(da => {
        let parsedValues = typeCastValues(da.values, deviceAttachmentFields);
        parsedValues = removeEmptyFields(parsedValues, deviceAttachmentFields);
        // reshape deviceId from {label, id} -> id
        parsedValues.deviceId = parsedValues.deviceId.id;
        return parsedValues;
      });

      const { start, end } = cleanedValues;
      const { stationId } = values;
      const createDeploymentInput = { start, end, positions, deviceAttachments, stationId };

      dispatch(addDeployment(createDeploymentInput, studyId));
      this.close();
    }
  }

  positionEntered() {
    const { latitude, longitude, depth } = this.state.values;
    return latitude || longitude || depth.value;
  }

  handleInput(dataKey, value) {
    this.setState({
      values: { ...this.state.values, [dataKey]: value },
      errors: { ...this.state.errors, [dataKey]: null },
    });
  }

  close() {
    this.setState(this.initialState());
    this.props.toggle();
  }

  renderInput(dataKey, selectOptions) {
    return (
      <GraphFieldInput
        field={{ ...fields.find(fld => fld.dataKey === dataKey), selectOptions }}
        fieldValue={this.state.values[dataKey]}
        fieldError={this.state.fieldErrors[dataKey]}
        handleInput={this.handleInput}
      />
    );
  }

  addDeviceAttachment() {
    const newDevAtt = initialFormState(deviceAttachmentFields);
    this.setState({ deviceAttachments: [...this.state.deviceAttachments, newDevAtt] });
  }

  removeDeviceAttachment(i) {
    this.setState({
      deviceAttachments: this.state.deviceAttachments.filter((_, idx) => idx !== i),
    });
  }

  handleDevAttInput(dataKey, value, i) {
    const deviceAttachments = [...this.state.deviceAttachments];
    const da = deviceAttachments[i];
    const values = { ...da.values, [dataKey]: value };
    deviceAttachments[i] = { ...da, values };
    this.setState({ deviceAttachments });
  }

  handleDevAttErrors(fieldErrors, i) {
    const deviceAttachments = [...this.state.deviceAttachments];
    deviceAttachments[i] = { ...deviceAttachments[i], fieldErrors };
    this.setState({ deviceAttachments });
  }

  dispatchAddStation(input) {
    this.props.dispatch(addStation(input));
  }

  render() {
    const { formErrors, deviceAttachments, startEndError } = this.state;
    const { deviceOptions, open, classes, stations } = this.props;
    return (
      <FormDialog
        onKeyDown={event => {
          if (event.key === "Escape") {
            this.close();
          }
          if (event.key === "Enter") {
            this.handleSubmit();
          }
        }}
        open={open}
        mode={"add"}
        title={"Add deployment"}
        handleSubmit={this.handleSubmit}
        handleClose={this.close}
        formErrors={formErrors}
      >
        <div className={classes.row}>
          {this.renderInput("start")}
          {this.renderInput("end")}
          <div className={classes.stationPickerContainer}>
            <StationPicker
              stations={stations}
              selectedId={this.state.values.stationId}
              addStation={this.dispatchAddStation}
              handleInput={stationId => this.handleInput("stationId", stationId)}
            />
          </div>
        </div>

        {startEndError && (
          <Typography color="error" variant="body2" paragraph>
            {startEndError}
          </Typography>
        )}

        <Typography style={{ marginLeft: 16 }} variant="h6">
          Position
        </Typography>
        <div className={classes.row}>
          {this.renderInput("latitude")}
          {this.renderInput("longitude")}
          {this.renderInput("depth")}
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ marginLeft: 16 }} variant="h6">
            Device Attachment(s)
          </Typography>
          <IconButton
            edge="end"
            color="primary"
            title={"Add a device to the the deployment"}
            onClick={this.addDeviceAttachment}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        </div>

        {deviceAttachments.map((da, i) => (
          <div key={i} style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
            <GraphFieldInput
              field={{
                ...deviceAttachmentFields.find(fld => fld.dataKey === "deviceId"),
                autoCompleteOptions: deviceOptions,
              }}
              fieldValue={da.values.deviceId}
              fieldError={da.fieldErrors.deviceId}
              handleInput={(dataKey, value) => this.handleDevAttInput(dataKey, value, i)}
            />
            <div className={classes.heightContainer}>
              <GraphFieldInput
                field={{ ...deviceAttachmentFields.find(fld => fld.dataKey === "height") }}
                fieldValue={da.values.height}
                fieldError={da.fieldErrors.height}
                handleInput={(dataKey, value) => this.handleDevAttInput(dataKey, value, i)}
              />
            </div>
            <IconButton
              edge="end"
              color="primary"
              title="Remove Device"
              onClick={() => this.removeDeviceAttachment(i)}
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </div>
        ))}
      </FormDialog>
    );
  }
}

AddDeploymentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  deviceOptions: PropTypes.array.isRequired,
  stations: PropTypes.array.isRequired,
  studyId: PropTypes.string,
};

export default connect()(withStyles(styles)(AddDeploymentDialog));
