import { formatDateTime, isTimeWithinBounds } from "../../helpers/time";
import { toTitleCase } from "../../helpers/common";
import { Serial, Transmitter } from "../../helpers/glossary";

import {
  DetectionRow,
  DetectionDataStatus,
  sensorUnits,
} from "../../redux/detections/detection-types";

import { useSelectorTyped as useSelector } from "../../redux/common";

import { WindowedTable, TableSearchBar } from "../../fathom-brella";
import FlexCol from "../../components/common/FlexCol";
import FlexRow from "../../components/common/FlexRow";
import IconMenuHoriz from "../../components/common/IconMenuHoriz";
import { SoftTitle } from "../../components/common/typography";
import { GetApp as GetAppIcon } from "@material-ui/icons";
import { CircularProgress, Tooltip } from "@material-ui/core";
import { SelectionInterval } from "./detection-types";

const DETECTION_DOWNLOAD_LIMIT = 50000000;

export function DetectionTable({
  searchText,
  setSearchText,
  detectionRows,
  detectionState,
  detectionCount,
  downloadDetections,
  tableLimitReached,
  selectionInterval,
}: {
  searchText: string;
  setSearchText: (value: string) => void;
  detectionRows: DetectionRow[];
  detectionState: DetectionDataStatus;
  detectionCount?: number;
  downloadDetections: () => void;
  tableLimitReached: boolean;
  selectionInterval: SelectionInterval;
}) {
  const { dlInProgress } = useSelector(state => {
    const jobIds = Object.keys(state.detection.downloads);
    const dlInProgress = jobIds.filter(jobId => {
      const stage = state.detection.downloads[jobId].stage;
      if (["PREPARE", "DOWNLOAD"].includes(stage)) { return true; }
    });
    return { dlInProgress };
  });

  const start = (selectionInterval?.[0] && new Date(selectionInterval[0]).toISOString()) || null;
  const end = (selectionInterval?.[1] && new Date(selectionInterval[1]).toISOString()) || null;

  const detectionRowsFiltered = detectionRows.filter(({ time }) =>
    isTimeWithinBounds({ time, start, end })
  );
  const stateLoading = detectionState === "LOADING" || detectionState === "INITIAL_LOADING";
  const tableLimitMsg = tableLimitReached ? "There are more than 1M detections in your applied filter. This table is displaying the 1st million detections." : "";
  return (
    <FlexCol fullWidth fullHeight hAlign="center" vAlign="center" paddingLevel={1} itemSpacing={1}>
      <FlexRow fullWidth spaceBetween vAlign="center">
        <IconMenuHoriz
          items={[
            {
              labelAbove: "Download ALL",
              icon: <GetAppIcon />,
              onClick: downloadDetections,
              hoverText: (
                dlInProgress.length > 0
                  ? "Please wait for the current download to finish."
                  : (detectionCount || 0) > DETECTION_DOWNLOAD_LIMIT
                    ? `Please download less than ${DETECTION_DOWNLOAD_LIMIT?.toLocaleString()} detections at a time.`
                    : `Download CSV of all detections in selected filter (${detectionCount?.toLocaleString()})`),
              disabled: detectionState === "CANCELED" || !detectionCount || dlInProgress.length > 0 || ((detectionCount || 0) > DETECTION_DOWNLOAD_LIMIT),
            },
          ]}
        />
        <FlexRow vAlign="center" hAlign="left" itemSpacing={2}>
          {stateLoading && <CircularProgress size={14} />}
          <Tooltip title={tableLimitMsg}>
            <span>
              <SoftTitle>
                {detectionRows?.length > 0 &&
                  `${detectionRows?.length.toLocaleString()} of ${detectionCount?.toLocaleString()} detections loaded `}
                {(detectionRowsFiltered.length < detectionRows.length &&
                  ` (${detectionRowsFiltered.length.toLocaleString()} selected)`) ||
                  ""}
              </SoftTitle>
            </span>
          </Tooltip>
        </FlexRow>
        <FlexCol vAlign="center">
          <div style={{ minWidth: 400, height: 40 }}>
            <TableSearchBar
              searchText={searchText}
              handleSearch={searchText => setSearchText(searchText)}
            />
          </div>
        </FlexCol>
      </FlexRow>

      <FlexCol fullWidth fullHeight>
        <WindowedTable
          searchText={searchText}
          rows={detectionRowsFiltered}
          rowIdKey={"id"}
          columns={[
            {
              width: 150,
              label: "Date Time",
              dataKey: "time",
              renderFn: time => formatDateTime(time),
            },
            {
              width: 125,
              label: Transmitter.title,
              dataKey: "full_id",
            },
            {
              width: 125,
              label: Serial.title,
              dataKey: "serial",
            },
            {
              width: 125,
              label: "Sensor Value",
              dataKey: "sensor_value",
              sortFn: (a, b) => {
                const numberA = parseFloat(a);
                const numberB = parseFloat(b);
                return !isNaN(numberB) && !isNaN(numberA) ? numberA - numberB : a > b ? 1 : -1;
              },
              renderFn: (_, row) => {
                if (row.sensor_value === null) return "";
                const unit = sensorUnits[row.sensor_type] || "";
                const sensorValueNumber = parseFloat(row.sensor_value);
                return `${!isNaN(sensorValueNumber)
                  ? Number(sensorValueNumber.toFixed(4))
                  : row.sensor_value?.constructor === String
                    ? row.sensor_value
                    : ""
                  } ${unit}`;
              },
            },
            {
              width: 100,
              label: "Sensor",
              dataKey: "sensor_type",
              renderFn: type => (type ? (type === "ADC" ? "ADC" : toTitleCase(type)) : ""),
            },
          ]}
        />
      </FlexCol>
    </FlexCol>
  );
}

export default DetectionTable;
