import { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "auto-bind/react";

import { connect } from "react-redux";
import { updateDeviceAttachment } from "../../redux/deployments/deployments-actions";

import { withStyles } from "@material-ui/core/styles";
import { TextField, IconButton, Tooltip } from "@material-ui/core";
import { Delete as IconDelete } from "@material-ui/icons";
import { WindowedTable } from "../../fathom-brella";
import TableTitleWithIcon from "../common/TableTitleWithIcon";
import { formatDevice } from "../../helpers/device";
import InlineEditor from "../common/InlineEditor";
import { validateFormField, parseFormField } from "../../helpers/common";
import { formatDateTime } from "../../helpers/time";
import DateTimePicker from "../common/DateTimePicker";
import MeasurementInput from "../common/MeasurementInput";
import WarningIcon from "../common/WarningIcon";
import { detailRowHeight, deploymentSizeUnits } from "./constants";
import { Autocomplete } from "@material-ui/lab";
import { ifEnterOrEsc } from "../../helpers/input";

const styles = {
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
  },
};

class DeploymentDevicesTable extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      editAnchorEl: null, // the anchor element for the edit field popup
      editValue: "", // the current edited value
      editKey: null, // the datakey for the column being edited
      editId: null, // the id of the deployment being edited
      editError: null, // the error message displayed in the edit field popup
    };
  }

  startEdit(el, rowData, dataKey) {
    const editValue =
      dataKey === "deviceId"
        ? rowData.device.id
        : dataKey === "height"
        ? rowData.height || { value: "", unit: "m" }
        : rowData[dataKey];

    this.setState({
      editAnchorEl: el,
      editValue,
      editKey: dataKey,
      editId: rowData.id,
      editError: null,
    });
  }

  stopEdit() {
    this.setState({ editAnchorEl: null });
  }

  submitEdit() {
    const { editValue, editKey, editId } = this.state;

    const inputType = {
      deviceId: "select",
      height: "measurement",
      start: "datetime",
      end: "datetime",
    }[editKey];

    const isRequired = editKey === "deviceId";
    const editError = validateFormField(editValue, inputType, isRequired);

    this.setState({ editError });
    if (!editError) {
      let parsedValue = parseFormField(inputType, editValue);
      if (inputType === "measurement" && parsedValue.value === "") {
        parsedValue = null;
      }
      this.props.dispatch(updateDeviceAttachment(editId, { [editKey]: parsedValue }));
      this.stopEdit();
    }
  }

  handleEditChange(value) {
    this.setState({ editValue: value });
  }

  handleDeleteClick(event, deviceAttachment) {
    event.preventDefault();
    event.stopPropagation(); //prevent parent element click event
    this.props.onDeleteClick(deviceAttachment);
  }

  render() {
    const { classes, deviceAttachments, handleAddClick, deviceOptions, selectedStudyId } =
      this.props;
    const { editAnchorEl, editValue, editError, editKey } = this.state;

    const anyConflicts = (
      selectedStudyId
        ? deviceAttachments.map(da => da.studyConflicts.find(sc => sc.studyId === selectedStudyId))
        : deviceAttachments.map(da => da.conflict)
    ).some(x => Boolean(x));
    const rows = deviceAttachments.map(d => ({
      ...d,
      start: formatDateTime(d.start),
      end: formatDateTime(d.end),
    }));
    const columns = [
      {
        width: 30,
        dataKey: "delete",
        renderFn: (_, rowData) => (
          <Tooltip title="Delete device attachment">
            <IconButton size="small" onClick={event => this.handleDeleteClick(event, rowData)}>
              <IconDelete fontSize="small" />
            </IconButton>
          </Tooltip>
        ),
        flexShrink: 1,
        flexGrow: 0,
      },
      {
        dataKey: "device",
        label: "Device",
        width: 60,
        renderFn: device => formatDevice(device),
        editable: true,
        onEdit: (event, rowData) => this.startEdit(event, rowData, "deviceId"),
        noEllipses: true,
      },
      {
        dataKey: "height",
        label: "Height",
        width: 50,
        renderFn: height => height && `${height.value} ${height.unit}`,
        editable: true,
        noEllipses: true,
        onEdit: (event, rowData) => this.startEdit(event, rowData, "height"),
        flexShrink: 1,
      },
      {
        dataKey: "start",
        label: "Start time",
        width: 100,
        noEllipses: true,
        editable: true,
        onEdit: (event, rowData) => this.startEdit(event, rowData, "start"),
      },
      {
        dataKey: "end",
        label: "End time",
        width: 100,
        noEllipses: true,
        editable: true,
        onEdit: (event, rowData) => this.startEdit(event, rowData, "end"),
      },
    ];

    if (anyConflicts) {
      const icon = (
        <WarningIcon tooltip="Device is attached to more than one deployment at the same time " />
      );

      let dataKey, renderFn;
      if (selectedStudyId) {
        dataKey = "studyConflicts";
        renderFn = studyConflicts =>
          studyConflicts.find(sc => sc.studyId === selectedStudyId) && icon;
      } else {
        dataKey = "conflict";
        renderFn = conflict => conflict && icon;
      }

      columns.push({
        dataKey,
        label: "",
        renderFn,
        width: 30,
        noEllipses: true,
        disableSort: true,
        flexShrink: 1,
      });
    }

    return (
      <div className={classes.root}>
        <div>
          <TableTitleWithIcon
            title={"Device attachments"}
            addClick={handleAddClick}
            toolTip={"Add device attachment"}
          />
        </div>
        {deviceAttachments && deviceAttachments.length > 0 && (
          <WindowedTable rows={rows} columns={columns} rowHeight={detailRowHeight} />
        )}
        <InlineEditor anchorEl={editAnchorEl} onSubmit={this.submitEdit} onCancel={this.stopEdit}>
          {editKey === "start" || editKey === "end" ? (
            <div style={{ padding: 10 }}>
              <DateTimePicker
                value={editValue}
                onChange={this.handleEditChange}
                helperText={editError}
                error={Boolean(editError)}
                onSubmit={this.submitEdit}
                onCancel={this.stopEdit}
                autoFocus
                disableFuture={true}
              />
            </div>
          ) : editKey === "height" ? (
            <MeasurementInput
              valueWidth={40}
              unitWidth={40}
              unitOptions={deploymentSizeUnits}
              unitDefault={"m"}
              helperText={editError}
              value={editValue}
              error={Boolean(editError)}
              onChange={this.handleEditChange}
              onSubmit={this.submitEdit}
              onCancel={this.stopEdit}
              autoFocus={true}
            />
          ) : editKey === "deviceId" ? (
            <div style={{ width: 250, margin: 16 }}>
              <Autocomplete
                autoComplete
                autoHighlight
                closeIcon={false}
                options={deviceOptions}
                groupBy={option => option.inStatus}
                renderInput={params => (
                  <TextField
                    {...params}
                    onKeyDown={keyEvent =>
                      ifEnterOrEsc({
                        keyEvent,
                        onEnter: this.submitEdit,
                        onEsc: this.stopEdit,
                      })
                    }
                    error={Boolean(editError)}
                    helperText={editError}
                    variant="standard"
                    autoFocus
                  />
                )}
                value={deviceOptions.find(option => editValue === option.id)}
                getOptionLabel={option => option.label}
                getOptionSelected={option => option.id === editValue}
                onChange={(_, option) => this.handleEditChange(option ? option.id : null)}
              />
            </div>
          ) : null}
        </InlineEditor>
      </div>
    );
  }
}

DeploymentDevicesTable.propTypes = {
  deviceAttachments: PropTypes.arrayOf(PropTypes.object),
  handleAddClick: PropTypes.func,
  deviceOptions: PropTypes.array,
  onDeleteClick: PropTypes.func,
  selectedStudyId: PropTypes.string,
};

const mapStateToProps = ({ study }) => {
  return {
    selectedStudyId: study.selectedId,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(DeploymentDevicesTable));
