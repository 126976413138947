export const detailRowHeight = 48;

export const deploymentSizeUnits = [{ value: "m", label: "m" }];

export const deploymentLabels = {
  station: { label: "Station", helperText: "Station where deployment occured" },
  start: { label: "Start time", helperText: "Time deployment began" },
  end: { label: "End time", helperText: "Time deployment ended" },
};

export const deviceLabels = {
  device: { label: "Device", helperText: "The device to attach" },
  start: { label: "Start time", helperText: "Time device was attached" },
  end: { label: "End time", helperText: "Time the device was removed" },
  height: { label: "Height", helperText: "Above ref. depth" },
};

export const positionLabels = {
  start: { label: "Start time", helperText: "Time position began" },
  end: { label: "End time", helperText: "Time position ended" },
  latitude: { label: "Latitude", helperText: "Latitude (decimal degrees)" },
  longitude: { label: "Longitude", helperText: "Longitude (decimal degrees)" },
  depth: { label: "Depth", helperText: "Reference depth" },
};
