import React from "react";
import Dropdown from "../../Dropdown";
import { Paper, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import IconLayers from "@material-ui/icons/Layers";

const mapStyles = [
  { name: "Satellite", url: "mapbox://styles/mapbox/satellite-v9" },
  { name: "Map", url: "mapbox://styles/mapbox/outdoors-v11" },
  { name: "Light", url: "mapbox://styles/mapbox/light-v10" },
  { name: "Dark", url: "mapbox://styles/mapbox/dark-v10" },
];

function Toggle() {
  return (
    <ListItem button>
      <ListItemIcon>
        <IconLayers />
      </ListItemIcon>
      <ListItemText>Map style</ListItemText>
    </ListItem>
  );
}

const Contents = React.forwardRef(({ mapStyle, mapStyleHandler }, ref) => (
  <Paper ref={ref}>
    <List open={true}>
      {mapStyles.map(d => (
        <ListItem
          key={d.name}
          onClick={() => mapStyleHandler(d.url)}
          selected={d.url === mapStyle}
          button
        >
          {d.name}
        </ListItem>
      ))}
    </List>
  </Paper>
));

export default Dropdown(Toggle, Contents);
