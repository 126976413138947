import { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import autoBind from "auto-bind/react";
import { connect } from "react-redux";
import { compose } from "redux";

import { Typography } from "@material-ui/core";
import {
  Add as IconAdd,
  Delete as IconDelete,
  Straighten as IconStraighten,
  Link as IconLink,
  Description as IconDescription,
  LinkOff as IconLinkOff,
} from "@material-ui/icons";
import DeleteWarning from "../components/common/DeleteWarning";
import AnimalsTable from "../components/animals/AnimalsTable";
import AnimalDetailsPanel from "../components/animals/AnimalDetailsPanel";
import AnimalSubObjectDialog from "../components/animals/AnimalSubObjectDialog";
import AnimalAddDialog from "../components/animals/AnimalAddDialog";
import AnimalEventIcon from "../components/animals/AnimalEventIcon";
import SpecUploadDialogs from "../components/common/SpecUploadDialogs/SpecUploadDialogs";
import MenuMultiButton from "../components/common/MenuMultiButton";
import DialogWrapper from "../components/common/DialogWrapper";
import { SpinnerInnovasea, TableSearchBar } from "../fathom-brella";
import IconMenuHoriz from "../components/common/IconMenuHoriz";
import MenuBar from "../components/common/MenuBar";
import { studyVisibleObjects } from "../helpers/study";
import { formatDevice, formatDeviceOptions, addDeviceLabels } from "../helpers/device";
import DeleteDialogButtons from "../components/common/DeleteDialogButtons";
import {
  readAnimalList,
  addAnimal,
  deleteAnimals,
  releaseAnimals,
  captureAnimals,
  recoverTags,
  measureAnimal,
  tagAnimal,
  updateReleaseEvent,
  updateCaptureEvent,
  updateTaggingEvent,
  updateRecoveryEvent,
  updateMeasurementSet,
  updateAnimal,
  deleteAnimalMeasurementSets,
  deleteAnimalEvents,
} from "../redux/animals/animals-actions";
import { readDeviceList } from "../redux/devices/devices-actions";
import { snackbarError } from "../redux/snackbar/snackbar-actions";
import { removeFromStudy } from "../redux/study/study-actions";
import { readDeploymentList } from "../redux/deployments/deployments-actions";

import {
  subObjectConfig,
  subObjNames,
  addAnimalConfig,
} from "../components/animals/animal-forms-config";
import { animalFields } from "../components/animals/constants";
import StudyEmptyMessage from "../components/study/StudyEmptyMessage";

import StudyChooseDialog from "./StudyChooseDialog";
import StudyUnlinkDialog from "./StudyUnlinkDialog";
import StudyLinkDialog from "./StudyLinkDialog";
import { queryDetectionSummary } from "../redux/detections/detection-actions";
import ResizableSplitPanel from "../components/common/ResizableSplitPanel";

const createActions = {
  measurementSet: measureAnimal,
  captureEvent: captureAnimals,
  releaseEvent: releaseAnimals,
  taggingEvent: tagAnimal,
  recoveryEvent: recoverTags,
};

const updateActions = {
  animal: updateAnimal,
  measurementSet: updateMeasurementSet,
  captureEvent: updateCaptureEvent,
  releaseEvent: updateReleaseEvent,
  taggingEvent: updateTaggingEvent,
  recoveryEvent: updateRecoveryEvent,
};

const styles = () => ({
  main: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  animalTableContainer: {
    flexGrow: 1,
    height: "100%",
  },
  loading: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
});

class Animals extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      selection: [],
      animalDialogMode: "closed", // closed | add
      confirmDialogMode: "closed", //animal | event | measurement
      animalSubObjectMode: "closed", // closed, add, edit
      animalSubObjectType: "releaseEvent", //  releaseEvent, captureEvent, taggingEvent, measurementSet
      animalSubObjectToEdit: null,
      eventId: "",
      measurementSetId: "",
      searchText: "",
      uploadDialogOpen: false,
      studyChooseOpen: false,
      studyLinkOpen: false,
      studyUnlinkOpen: false,
    };
  }

  componentDidMount() {
    this.fetchData();
    // Page load & wants an animal initially selected
    if (this.props.location && this.props.location.search) {
      const qParams = new URLSearchParams(this.props.location.search);
      const selectedAnimalId = qParams.get("initAnimalId");
      qParams.delete("initAnimalId");
      if (selectedAnimalId) {
        this.setState({ selection: [selectedAnimalId] });
      }
      this.props.history.replace({
        search: qParams.toString() ? `?${qParams.toString()}` : null,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedWorkspace !== prevProps.selectedWorkspace ||
      this.props.selectedStudy !== prevProps.selectedStudy
    ) {
      this.setState({ selection: [] });
    }
    if (this.props.selectedWorkspace !== prevProps.selectedWorkspace) {
      this.fetchData();
    }
  }

  fetchData() {
    this.props.dispatch(readAnimalList());
    this.props.dispatch(readDeviceList());
    this.props.dispatch(readDeploymentList()); // for filtering tags based on deployment status
    this.props.dispatch(queryDetectionSummary());
  }

  openAnimalDialog(mode) {
    this.setState({ animalDialogMode: mode });
  }

  closeAnimalDialog() {
    this.setState({ animalDialogMode: "closed" });
  }

  handleAddAnimal(animalInput) {
    const { selectedStudy } = this.props;
    this.closeAnimalDialog();
    // reshapes animal section up and create deviceIds
    animalInput = { ...animalInput.animal, ...animalInput };
    if (animalInput.taggingEvent) {
      animalInput.taggingEvent.deviceIds = animalInput.taggingEvent.devices
        ? animalInput.taggingEvent.devices.map(d => d.id)
        : [];
      delete animalInput.taggingEvent.devices;
    }
    delete animalInput.animal;

    Object.values(animalInput).forEach(this.reshapeLatLon);

    // populate missing measurement set time with tagging time
    if (animalInput.measurementSet && !animalInput.measurementSet.time) {
      animalInput.measurementSet.time = animalInput.taggingEvent.time;
    }

    return this.props.dispatch(addAnimal(animalInput, selectedStudy && selectedStudy.id));
  }

  openAnimalSubObjDialog(type, mode = "add") {
    this.setState({ animalSubObjectMode: mode, animalSubObjectType: type });
  }

  closeAnimalSubObjDialog() {
    this.setState({ animalSubObjectMode: "closed" });
  }

  handleSubObjectAdd(input, type) {
    const { selection } = this.state;
    if (input.devices) {
      input.deviceIds = input.devices.map(d => d.id);
      delete input.devices;
    }

    this.reshapeLatLon(input);
    // release and capture events accept arrays of animal ids. the other subobjects take only a single id
    const typesTakingArrays = ["releaseEvent", "captureEvent"];
    const animalIds = typesTakingArrays.includes(type) ? selection : selection[0];

    this.props.dispatch(createActions[type](animalIds, input));
    this.closeAnimalSubObjDialog();
  }

  // Work around to use changed API. A better solution would be to treat lat/lon as a single input
  reshapeLatLon(obj) {
    if (populated(obj.latitude) || populated(obj.longitude)) {
      obj.latLon = { latitude: obj.latitude, longitude: obj.longitude };
    }
    delete obj.latitude;
    delete obj.longitude;
  }

  openDeleteAnimalDialog(event) {
    event.preventDefault();
    this.setState({
      confirmDialogMode: "animal",
    });
  }

  closeDeleteConfirmDialog() {
    this.setState({
      confirmDialogMode: "closed",
    });
  }

  actionUnlink() {
    this.props.dispatch(
      removeFromStudy({ studyId: this.props.selectedStudy.id, animalIds: this.state.selection }),
      true
    );
    this.setState({
      confirmDialogMode: "closed",
    });
  }

  deleteAction(mode, animalsSelected) {
    switch (mode) {
      case "animal":
        this.props.dispatch(deleteAnimals(animalsSelected, this.clearSelection));
        break;
      case "event":
        this.props.dispatch(deleteAnimalEvents([this.state.eventId]));
        break;
      case "measurement":
        this.props.dispatch(deleteAnimalMeasurementSets([this.state.measurementSetId]));
        break;
      default:
        break;
    }
    this.closeDeleteConfirmDialog();
    this.setState({
      eventId: "",
      measurementSetId: "",
    });
  }

  clearSelection() {
    this.setState({ selection: [] });
  }

  handleSubobjectEdit(input, type) {
    const id = this.state.animalSubObjectToEdit.id;

    // reshape devices into deviceIds
    if (input.devices) {
      input.deviceIds = input.devices.map(d => d.id);
      delete input.devices;
    }

    // reshape latLon
    this.reshapeLatLon(input);

    this.props.dispatch(updateActions[type](id, input));
    this.closeAnimalSubObjDialog();
  }

  handleSubObjectSubmit(mode, input, type) {
    if (mode === "edit") {
      this.handleSubobjectEdit(input, type);
    } else {
      this.handleSubObjectAdd(input, type);
    }
  }

  onEventEdit(eventId) {
    const eventToEdit = this.getUniqueAnimalSelected().events.find(e => e.id === eventId);
    const subObjName = subObjNames[eventToEdit.__typename];

    if (!subObjName) {
      this.props.dispatch(snackbarError("Not supported yet"));
      return;
    }

    if (eventToEdit.devices) {
      addDeviceLabels(eventToEdit.devices);
    }

    this.setState({ animalSubObjectToEdit: eventToEdit });
    this.openAnimalSubObjDialog(subObjName, "edit");
  }

  onMeasurementSetDelete(measurementSetId) {
    this.setState({ confirmDialogMode: "measurement" });
    this.setState({ measurementSetId });
  }

  onMeasurementSetEdit(measurementSetId) {
    const animalSelected = this.getUniqueAnimalSelected();
    if (!animalSelected) {
      return; // should not happen
    }
    const setToEdit = animalSelected.measurementSets.find(m => m.id === measurementSetId);

    this.setState({ animalSubObjectToEdit: setToEdit });
    this.openAnimalSubObjDialog("measurementSet", "edit");
  }

  onEventDelete(event) {
    if (event.__typename === "AnimalEventTagging") {
      const animalSelected = this.getUniqueAnimalSelected();
      if (!animalSelected) {
        return; // should not happen
      }
      if (!animalSelected.name) {
        return this.props.dispatch(
          snackbarError("An animal nickname is required to delete all tagging events")
        );
      }
    }
    this.setState({ confirmDialogMode: "event" });
    this.setState({ eventId: event.id });
  }
  onAnimalEdit(animalId) {
    const animalSelected = this.getUniqueAnimalSelected();
    if (!animalSelected) {
      return; // should not happen
    }
    const animalToEdit = Object.keys(animalSelected)
      .filter(key => Object.keys(animalFields).includes(key))
      .reduce((obj, key) => {
        obj[key] = animalSelected[key];
        return obj;
      }, {});
    animalToEdit.id = animalId;
    this.setState({ animalSubObjectToEdit: animalToEdit });
    this.openAnimalSubObjDialog("animal", "edit");
  }

  getUniqueAnimalSelected() {
    const { selection } = this.state;
    if (selection.length === 0) {
      console.warn(
        "An action was fired expecting exactly one animal to be selected, but no animals were found."
      );
      return null;
    }
    if (selection.length > 1) {
      console.warn(
        "An action was fired expecting exactly one animal to be selected, but multiple animals were found."
      );
      return null;
    }

    return this.props.animals.find(animal => animal.id === selection[0]);
  }

  handleRemoveFromStudy(studyId, animalId) {
    if (studyId && animalId) {
      this.props.dispatch(removeFromStudy({ studyId, animalIds: [animalId] }, true));
    }
    return;
  }

  render() {
    const {
      selection,
      searchText,
      animalSubObjectMode,
      animalSubObjectType,
      uploadDialogOpen,
      studyChooseOpen,
      studyLinkOpen,
      studyUnlinkOpen,
    } = this.state;
    const {
      animalsError,
      animalsLoading,
      classes,
      animals,
      selectedStudy,
      isUserAdmin,
      devicesLoading,
      deviceOptions,
      detectionsLoading,
    } = this.props;
    const loading = animalsLoading || devicesLoading;

    const animalsSelected = [];
    selection.forEach(animalId => {
      const animal = animals.find(animal => animal.id === animalId);
      if (animal) {
        animalsSelected.push(animal);
      }
    });

    if (loading) {
      return (
        <div className={classes.loading}>
          <SpinnerInnovasea />
        </div>
      );
    }

    const singleDisabled = selection.length !== 1;
    const multipleDisabled = selection.length <= 0;

    // only show attached but not recovered devices for recoveryEvent config
    let configTags = deviceOptions;
    if (animalSubObjectType === "recoveryEvent" && animalsSelected.length > 0) {
      // filter already tagged tags
      configTags = deviceOptions.filter(tag =>
        animalsSelected[0].devices.map(device => device.id).includes(tag.id)
      );
      // exclude already recovered tags from the list
      animalsSelected[0].events.forEach(event => {
        if (event.__typename === "AnimalEventDeviceRecovery") {
          configTags = configTags.filter(
            tag => !event.devices.map(device => device.id).includes(tag.id)
          );
        }
      });
    }

    return (
      <>
        <ResizableSplitPanel
          direction="horizontal"
          firstMin={"60%"}
          secondMin={"25%"}
          firstInit={"70%"}
          firstContent={
            <div className={classes.main}>
              <MenuBar
                itemSpacing={1}
                contentLeft={
                  <IconMenuHoriz
                    items={[
                      {
                        labelAbove: "Add",
                        icon: <IconAdd />,
                        onClick: () => this.openAnimalDialog("add"),
                        disabled: devicesLoading,
                        hoverText: "Add new animals",
                      },
                      {
                        labelAbove: "Import",
                        icon: <IconDescription />,
                        onClick: () => this.setState({ uploadDialogOpen: true }),
                        hoverText: "Import Animals from File",
                      },
                      {
                        labelAbove: "Delete",
                        icon: <IconDelete />,
                        disabled: multipleDisabled,
                        onClick: e => this.openDeleteAnimalDialog(e),
                        hoverText: multipleDisabled ? "Select animals to delete" : "Delete animals",
                      },
                      { divider: true },
                      {
                        // (only shown outside of study context) for selecting a study to link to the currently selected animals
                        visible: !selectedStudy,
                        labelAbove: "Link",
                        icon: <IconLink />,
                        onClick: () => this.setState({ studyChooseOpen: true }),
                        disabled: selection.length === 0,
                        hoverText:
                          selection.length === 0
                            ? "Please select animals to link to a study"
                            : "Link the selected animals to a study",
                      },
                      {
                        // (only shown within a study context) for selecting animals to link to the currently selected study
                        visible: Boolean(selectedStudy),
                        labelAbove: "Link",
                        icon: <IconLink />,
                        onClick: () => this.setState({ studyLinkOpen: true }),
                        hoverText: "Link animals to this study from the workspace",
                      },
                      {
                        // (only shown within a study context) for unlinking currently selected animals from the currently selected study
                        visible: Boolean(selectedStudy),
                        labelAbove: "Unlink",
                        icon: <IconLinkOff />,
                        onClick: () => this.setState({ studyUnlinkOpen: true }),
                        disabled: selection.length === 0,
                        hoverText:
                          selection.length === 0
                            ? "Please select animals to unlink from this study"
                            : "Unlink the currently selected animals from this study",
                      },
                      { divider: true },
                      {
                        labelAbove: "Capture",
                        icon: (
                          <AnimalEventIcon
                            eventType="AnimalEventCapture"
                            disabled={multipleDisabled}
                            light
                          />
                        ),
                        disabled: multipleDisabled,
                        onClick: () => this.openAnimalSubObjDialog("captureEvent"),
                        hoverText: multipleDisabled
                          ? "Select animals to add capture event"
                          : "Add capture event to animals",
                      },
                      {
                        labelAbove: "Tag",
                        icon: (
                          <AnimalEventIcon
                            eventType="AnimalEventTagging"
                            light
                            disabled={singleDisabled}
                          />
                        ),
                        disabled: singleDisabled,
                        onClick: () => this.openAnimalSubObjDialog("taggingEvent"),
                        hoverText:
                          selection.length > 1
                            ? "You can only add a tagging event to one animal at a time"
                            : singleDisabled
                            ? "Select an animal to add tagging event"
                            : "Add tagging event to animal",
                      },
                      {
                        labelAbove: "Measure",
                        icon: <IconStraighten />,
                        disabled: singleDisabled,
                        onClick: () => this.openAnimalSubObjDialog("measurementSet"),
                        hoverText:
                          selection.length > 1
                            ? "You can only add a measurement set to one animal at a time"
                            : singleDisabled
                            ? "Select an animal to add a measurement set"
                            : "Add a measurement set to animal",
                      },
                      {
                        labelAbove: "Release",
                        icon: (
                          <AnimalEventIcon
                            eventType="AnimalEventRelease"
                            light
                            disabled={multipleDisabled}
                          />
                        ),
                        disabled: multipleDisabled,
                        onClick: () => this.openAnimalSubObjDialog("releaseEvent"),
                        hoverText: multipleDisabled
                          ? "Select animals to add release events"
                          : "Add release events to animals",
                      },
                      {
                        labelAbove: "Recover tags",
                        icon: (
                          <AnimalEventIcon
                            eventType="AnimalEventDeviceRecovery"
                            light
                            disabled={singleDisabled}
                          />
                        ),
                        disabled: singleDisabled,
                        onClick: () => this.openAnimalSubObjDialog("recoveryEvent"),
                        hoverText:
                          selection.length > 1
                            ? "You can only add a device recovery event to one animal at a time"
                            : singleDisabled
                            ? "Select an animal to add device recovery event"
                            : "Add device recovery event to animal",
                      },
                    ]}
                  />
                }
                contentRight={
                  <>
                    <TableSearchBar
                      searchText={searchText}
                      handleSearch={searchText => {
                        this.setState({ searchText });
                      }}
                    />
                    {isUserAdmin && (
                      <MenuMultiButton
                        options={[
                          {
                            text: "Import OTN Tagging Metadata",
                            onClick: () => this.setState({ uploadDialogOpen: true }),
                          },
                        ]}
                      />
                    )}
                  </>
                }
              />
              {selectedStudy && animals.length <= 0 ? (
                <div style={{ padding: 20 }}>
                  <StudyEmptyMessage
                    typeName="animals"
                    studyName={selectedStudy.name}
                    onLinkClicked={() => this.setState({ studyLinkOpen: true })}
                    onNewClicked={() => this.openAnimalDialog("add")}
                  />
                </div>
              ) : (
                <div className={classes.animalTableContainer}>
                  <AnimalsTable
                    animals={animals}
                    error={animalsError}
                    loading={animalsLoading}
                    detectionsLoading={detectionsLoading}
                    selection={selection}
                    setSelection={selection => {
                      this.setState({ selection });
                    }}
                    searchText={searchText}
                    selectedStudyId={selectedStudy?.id}
                    handleRemoveFromStudy={this.handleRemoveFromStudy}
                  />
                </div>
              )}
            </div>
          }
          secondContent={
            <AnimalDetailsPanel
              onEventDelete={this.onEventDelete}
              onEventEdit={this.onEventEdit}
              animalsSelected={animalsSelected}
              deleteMeasurementSet={this.onMeasurementSetDelete}
              editMeasurementSet={measurementSetId => this.onMeasurementSetEdit(measurementSetId)}
              onAnimalEdit={animalId => this.onAnimalEdit(animalId)}
              onSubObjAdd={subObjType => this.openAnimalSubObjDialog(subObjType)}
            />
          }
        />

        {/* Add Animal */}
        <AnimalAddDialog
          open={this.state.animalDialogMode !== "closed"}
          handleAdd={this.handleAddAnimal}
          handleClose={this.closeAnimalDialog}
          handleAnother={() => setTimeout(() => this.setState({ animalDialogMode: "add" }), 500)}
          formConfig={addAnimalConfig(deviceOptions)}
        />

        <SpecUploadDialogs
          open={uploadDialogOpen}
          dataType="animal"
          close={() => this.setState({ uploadDialogOpen: false })}
        />

        {/* Add / Edit Release, Capture, Tagging, MeasurementSet */}
        <AnimalSubObjectDialog
          mode={animalSubObjectMode}
          type={animalSubObjectType}
          handleClose={this.closeAnimalSubObjDialog}
          handleSubmit={input =>
            this.handleSubObjectSubmit(animalSubObjectMode, input, animalSubObjectType)
          }
          animalsSelected={animalsSelected}
          formConfig={subObjectConfig(configTags)[animalSubObjectType]}
          editState={this.state.animalSubObjectToEdit}
        />

        <DialogWrapper
          open={this.state.confirmDialogMode !== "closed"}
          title={deleteDialogTitle(this.state.confirmDialogMode)}
          okAction={() => this.deleteAction(this.state.confirmDialogMode, animalsSelected)}
          cancelAction={this.closeDeleteConfirmDialog}
          buttons={({ cancelAction, okAction }) => (
            <DeleteDialogButtons
              cancelAction={cancelAction}
              okAction={okAction}
              isStudy={Boolean(selectedStudy)}
              unlinkAction={this.actionUnlink}
            />
          )}
        >
          <Typography gutterBottom>
            {animalsSelected && animalsSelected.length > 0 && (
              <>
                <div>
                  {getConfirmDialogText(this.state.confirmDialogMode, animalsSelected)}
                  {(animalsSelected.length > 1 || !animalsSelected[0].name) && (
                    <ul>
                      {animalsSelected.map(animal => (
                        <li key={animal.id}>
                          {animal.name || getAnimalTagLabels(animal).join(", ")}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </>
            )}
          </Typography>
          {Boolean(selectedStudy) && <DeleteWarning type="animal" />}
        </DialogWrapper>

        {studyChooseOpen && (
          <StudyChooseDialog
            open={true}
            handleClose={() => this.setState({ studyChooseOpen: false })}
            objectType="animalIds"
            objectTypeLabel="Animal"
            objectIds={selection}
          />
        )}

        {studyLinkOpen && (
          <StudyLinkDialog
            open={true}
            handleClose={() => this.setState({ studyLinkOpen: false })}
            objectType="animalIds"
            objectTypeLabel="Animal"
            selectedStudyId={selectedStudy?.id}
          />
        )}

        {studyUnlinkOpen && (
          <StudyUnlinkDialog
            open={true}
            handleClose={() => this.setState({ studyUnlinkOpen: false })}
            objectType="animalIds"
            objectTypeLabel="Animal"
            objectIds={selection}
            selectedStudyId={selectedStudy?.id}
          />
        )}
      </>
    );
  }
}

const deleteDialogTitle = mode => {
  if (mode === "closed") return;
  return {
    animal: "Delete Animal",
    event: "Delete Animal Event",
    measurement: "Delete Animal Measurement",
  }[mode];
};

function getConfirmDialogText(mode, animalsSelected) {
  if (mode === "closed") return;
  let str =
    "Are you sure you want to delete " +
    {
      animal: "",
      event: "event for",
      measurement: "measurement for",
    }[mode];
  if (animalsSelected.length > 1) {
    str += " animals with the following nicknames or tag IDs?";
  } else {
    if (animalsSelected[0].name) {
      str += " animal " + animalsSelected[0].name + "?";
    } else {
      str += " animals with the following tag IDs?";
    }
  }
  return str;
}

function getAnimalTagLabels(animal) {
  const labels = [];
  animal.events?.forEach(event => {
    event.devices?.forEach(device => {
      labels.push(formatDevice(device));
    });
  });
  return labels;
}

function populated(field) {
  return field !== undefined && field !== null;
}

Animals.propTypes = {
  animals: PropTypes.array,
  animalsLoading: PropTypes.bool,
  animalsError: PropTypes.object,
  deviceOptions: PropTypes.array,
  devicesLoading: PropTypes.bool,
  devicesError: PropTypes.object,
  selectedStudy: PropTypes.object,
  history: PropTypes.any,
  location: PropTypes.any,
  selectedWorkspace: PropTypes.object,
};

const mapStateToProps = ({ animals, devices, study, workspaces, user, deployments, detection }) => {
  const studySelectedId = study.selectedId;
  const selectedStudy =
    studySelectedId && study.studies.find(study => study.id === studySelectedId);
  const { detectionSummary } = detection.summary;
  const detectionsFromAnimals = detectionSummary?.idSummary?.filter(
    d => d.idCount.animalIds.length === 1
  );

  let _animals = studyVisibleObjects({
    studies: study.studies,
    selectedStudy,
    objects: animals.animals,
    objectKey: "animals",
  });

  if (detectionsFromAnimals) {
    _animals = _animals.map(a => {
      return {
        ...a,
        detSummary: detectionsFromAnimals.find(d => {
          if (d.idCount.animalIds.length === 1 && d.idCount.animalIds[0] === a.id) {
            return d.idCount;
          }
        }),
      };
    });
  }

  // Format device options based on status:
  const deviceOptions = formatDeviceOptions({
    devices: devices.devices || [],
    selectedStudy,
    deployments:
      selectedStudy && deployments.deployments
        ? selectedStudy.deployments?.map(deploymentInStudy =>
            deployments.deployments?.find(deployment => deployment.id === deploymentInStudy.id)
          )
        : deployments.deployments,
    // deployments: deployments.deployments,
    animals: _animals,
    restrictToClass: "TAG",
  });

  return {
    animals: _animals,
    animalsLoading: animals.loading,
    animalsError: animals.error,
    devicesLoading: devices.loading,
    devicesError: devices.error,
    deviceOptions,
    selectedStudy,
    selectedWorkspace: workspaces.selectedWorkspace,
    isUserAdmin: user.isAdmin,
    detectionsLoading: detection.loading,
  };
};

export default compose(connect(mapStateToProps), withStyles(styles))(Animals);
