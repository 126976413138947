import { useState, useEffect } from "react";
import { set } from "lodash";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Button,
} from "@material-ui/core";
import { Warning } from "@material-ui/icons";

import DialogWrapper from "../common/DialogWrapper";
import InputWrapper from "../common/InputWrapper";
import FlexRow from "../common/FlexRow";
import FlexCol from "../common/FlexCol";

import { Transmitter, TxSpec, DevicePosition } from "../../containers/DetectionAnalysis";
import PositionSummary from "./PositionSummary";
import HelpPopover from "../common/HelpPopover";
import { SpinnerInnovasea } from "../../fathom-brella";
import { Serial } from "../../helpers/glossary";

type Props = {
  /** All transmitters avaliable in workspace */
  transmitters: Transmitter[];
  /** The transmitters already included in the range test, in the form required by it */
  txList: TxSpec[];
  /** Function to set the new list of transmitters for range test */
  setTxList: (newTxList: TxSpec[]) => void;
  closeFn: () => void;
  dataReady: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiFormControlLabel-root": { margin: 0 },
    "& .MuiIconButton-root": { padding: 7 },
  },
  header: {
    alignItems: "center",
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  heading: {
    // paddingLeft: theme.spacing(1),
  },
  row: {
    alignItems: "flex-start",
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: "relative",
  },
  gridText: {
    padding: theme.spacing(1),
    paddingLeft: 0,
  },
  manualInput: {
    "& .MuiInput-root": {
      marginTop: 0,
    },
    "& .MuiInputBase-input": {
      padding: 0,
      fontSize: "0.875rem",
      textAlign: "center",
    },
  },
  error: {
    fontWeight: "bold",
    color: theme.palette.error.main,
    paddingLeft: theme.spacing(1),
  },
  inputError: {
    boxShadow: "0px 0px 10px -4px red",
    borderRadius: 10,
  },
  disabled: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "silver",
    opacity: 0.6,
    zIndex: 10,
    paddingRight: theme.spacing(2),
  },
}));

export default function SelectTx({ transmitters, txList, setTxList, closeFn, dataReady }: Props) {
  const classes = useStyles();
  const [transmittersTemp, setTransmittersTemp] = useState<Transmitter[]>([]);
  const [selectedFullIds, setSelectedFullIds] = useState<string[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const devicesNeedMoreData = transmitters.filter(tx => !tx.positions?.length);

  useEffect(() => {
    if (dataReady) {
      // Dialog only includes transmitters that aren't selected already:
      const fullIds = txList.map(tx => tx.fullId);
      // Only render devices that are possible to use (require position)
      const txTemp = transmitters.filter(
        tx => !fullIds.includes(tx.fullId) && tx.positions?.length
      );
      setTransmittersTemp(txTemp);
    }
  }, [dataReady, txList, transmitters]);

  function removeError(key) {
    if (errors[key]) {
      setErrors(prev => {
        const next = { ...prev };
        delete next[key];
        return next;
      });
    }
  }

  function toggleSelect(fullId: string) {
    removeError("dialog");
    const newSelection = [...selectedFullIds];
    if (newSelection.includes(fullId)) {
      newSelection.splice(
        newSelection.findIndex(id => id == fullId),
        1
      );
    } else {
      newSelection.push(fullId);
    }
    setSelectedFullIds(newSelection);
  }

  // This always adds if not there
  function selectRow(fullId: string) {
    removeError("dialog");
    if (!selectedFullIds.includes(fullId)) {
      const newSelection = [...selectedFullIds];
      newSelection.push(fullId);
      setSelectedFullIds(newSelection);
    }
  }

  function handleChange({ idx, key, value }) {
    removeError(transmittersTemp[idx].fullId);
    setTransmittersTemp(prev => {
      const changed = [...prev];
      set(changed[idx], key, value);
      return changed;
    });
  }

  function submit() {
    const newTxList: TxSpec[] = [];
    const txMap = {};
    transmittersTemp.forEach((t, i) => {
      txMap[t.fullId] = i;
    });
    let pass = true;

    selectedFullIds.forEach(fullId => {
      const tx = transmittersTemp[txMap[fullId]];
      let position: DevicePosition | null = null;
      tx.positions?.forEach(p => {
        if (p.positionId == tx.selectedPositionId) {
          position = p;
        }
      });

      const newTx: TxSpec = {
        deviceId: tx.deviceId,
        fullId,
        delaySequenceStep: tx.selectedDelay?.number,
        delayMin:
          (tx.selectedDelay?.number === 0 ? tx.selectedDelay?.minMan : tx.selectedDelay?.min) || 0,
        delayMax:
          (tx.selectedDelay?.number === 0 ? tx.selectedDelay?.maxMan : tx.selectedDelay?.max) || 0,
        position,
      };
      if (newTx.delayMin < 1 || newTx.delayMax < 1) {
        setErrors(prev => ({ ...prev, [fullId]: "Delays must be greater than 0" }));
        pass = false;
      } else {
        newTxList.push(newTx);
      }
    });

    if (selectedFullIds.length < 1) {
      setErrors(prev => ({ ...prev, dialog: "Please select a transmitter to continue" }));
      return;
    }

    if (newTxList.length > 0 && pass) {
      setTxList([...txList, ...newTxList]);
      closeFn();
    }
  }

  return (
    <DialogWrapper
      title="Select Transmitters"
      open={true}
      cancelAction={closeFn}
      okAction={submit}
      maxWidth="md"
      buttons={({ cancelAction, okAction }) => (
        <FlexRow fullWidth vAlign="center" spaceBetween itemSpacing={1}>
          <div className={classes.error}>{errors.dialog && errors.dialog}</div>
          <div>
            <Button onClick={cancelAction} variant="outlined">
              Cancel
            </Button>
            <Button onClick={okAction} color="primary" variant="contained">
              Add
            </Button>
          </div>
        </FlexRow>
      )}
    >
      {!dataReady ? (
        <SpinnerInnovasea text="Loading device data ..." />
      ) : (
        <>
          <Grid container spacing={0} className={classes.root}>
            <Grid container item xs={12} spacing={0} className={classes.header}>
              <Grid item xs={1}>
                <Checkbox
                  size="small"
                  checked={selectedFullIds.length == transmittersTemp.length}
                  onChange={event => {
                    let newSelection: string[] = [];
                    if (event.target.checked) {
                      newSelection = transmittersTemp.map(tx => tx.fullId) || [];
                    }
                    setSelectedFullIds(newSelection);
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <Typography variant="subtitle2" className={classes.heading}>
                  Model
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="subtitle2" className={classes.heading}>
                  {Serial.title}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2" className={classes.heading}>
                  Transmitter ID
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle2" className={classes.heading}>
                  Min / Max
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2" className={classes.heading}>
                  Position
                </Typography>
              </Grid>
            </Grid>
            {transmittersTemp.map((tx, idx) => {
              return (
                <Grid
                  key={tx.fullId}
                  container
                  item
                  xs={12}
                  spacing={0}
                  className={classes.row}
                  onClick={() => selectRow(tx.fullId)}
                >
                  <Grid item xs={1}>
                    <Checkbox
                      size="small"
                      checked={selectedFullIds.includes(tx.fullId)}
                      onClick={() => toggleSelect(tx.fullId)}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="body2" className={classes.gridText}>
                      {tx.model}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography variant="body2" className={classes.gridText}>
                      {tx.serial}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2" className={classes.gridText}>
                      {tx.fullId}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <RadioGroup
                      value={null}
                      onChange={event => {
                        const [number, min, max] = event.target.value.split(",");
                        handleChange({
                          idx,
                          key: "selectedDelay",
                          value: { number: Number(number), min, max },
                        });
                      }}
                    >
                      {tx.delays?.map(s => {
                        return (
                          <FlexCol key={`${s.number},${s.min},${s.max}`}>
                            <FormControlLabel
                              value={`${s.number},${s.min},${s.max}`}
                              checked={s.number === tx.selectedDelay?.number}
                              control={<Radio size="small" />}
                              label={
                                <Typography variant="body2">{`${s.min} / ${s.max}`}</Typography>
                              }
                            />
                          </FlexCol>
                        );
                      })}
                      <FormControlLabel
                        value={0}
                        checked={tx.selectedDelay?.number === 0}
                        control={<Radio size="small" />}
                        label={
                          <FlexRow
                            vAlign="center"
                            className={errors[tx.fullId] && classes.inputError}
                            onClick={() => {
                              handleChange({
                                idx,
                                key: "selectedDelay.number",
                                value: 0,
                              });
                            }}
                          >
                            <InputWrapper
                              title="Manual MIN delay"
                              inputType="number"
                              width={30}
                              value={tx.selectedDelay?.minMan || ""}
                              onChange={value =>
                                handleChange({ idx, key: "selectedDelay.minMan", value })
                              }
                              className={classes.manualInput}
                            />
                            <span style={{ marginLeft: 4, marginRight: 4 }}> / </span>
                            <InputWrapper
                              title="Manual MAX delay"
                              inputType="number"
                              width={30}
                              value={tx.selectedDelay?.maxMan || ""}
                              onChange={value =>
                                handleChange({ idx, key: "selectedDelay.maxMan", value })
                              }
                              className={classes.manualInput}
                            />
                          </FlexRow>
                        }
                      />
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={4}>
                    <RadioGroup
                      name="positions"
                      value={null}
                      onChange={event =>
                        handleChange({ idx, key: "selectedPositionId", value: event.target.value })
                      }
                    >
                      {tx.positions?.map(p => (
                        <PositionSummary
                          key={p.positionId}
                          position={p}
                          selectedPositionId={tx.selectedPositionId}
                          selectable={true}
                        />
                      ))}
                    </RadioGroup>
                  </Grid>
                  {errors[tx.fullId] && (
                    <FlexRow
                      hAlign="center"
                      fullWidth
                      className={classes.error}
                      style={{ padding: 8, paddingTop: 0 }}
                    >
                      <span>{errors[tx.fullId]}</span>
                    </FlexRow>
                  )}
                  {!tx.selectedPositionId && (
                    <FlexRow
                      vAlign="bottom"
                      hAlign="right"
                      itemSpacing={1}
                      className={classes.disabled}
                      onClick={event => event.stopPropagation()}
                    >
                      <Warning />
                      <span>Please add a deployment with a position</span>
                    </FlexRow>
                  )}
                </Grid>
              );
            })}
          </Grid>
          {devicesNeedMoreData.length > 0 && (
            <FlexRow vAlign="center" paddingLevel={3}>
              <Typography variant="subtitle1" color="error">
                {devicesNeedMoreData.length} other transmitters need a deployment record to be
                included for selection.
              </Typography>
              <HelpPopover
                tooltip="more info"
                helpContent="Detection analysis requires positions to function. Please ensure there is a deployment with position for each device you wish to include."
              />
            </FlexRow>
          )}
        </>
      )}
    </DialogWrapper>
  );
}
