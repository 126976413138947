import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import { SpinnerInnovasea } from "../../fathom-brella";
import DevicesTable from "./DevicesTable";
import DialogWrapper from "../common/DialogWrapper";
import { deviceCopyMessage } from "../../containers/Devices";
import HelpPopover from "../common/HelpPopover";
import { CONTACT_SUPPORT_URL } from "../../constants";
import { Serial } from "../../helpers/glossary";

const useStyles = makeStyles(theme => ({
  centerCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  loadingText: {
    position: "absolute",
    paddingBottom: 250,
  },
  instructions: {
    fontSize: "larger",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  info: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  content: {
    height: 400,
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
}));

function DevicesAddDialog({
  open,
  handleClose,
  selectedWorkspace,
  personalDevices,
  personalDevicesError,
  personalDevicesLoading,
  selection,
  setSelection,
  copyDevicesToWorkspace,
  switchToManual,
  switchToSerial,
}) {
  const classes = useStyles();

  return (
    <DialogWrapper
      okAction={copyDevicesToWorkspace}
      cancelAction={handleClose}
      title={`Add devices to workspace: ${selectedWorkspace.name}`}
      open={open}
      onClose={handleClose}
      maxWidth={selectedWorkspace.isPersonal ? "sm" : "md"}
      buttons={({ cancelAction, okAction }) =>
        selectedWorkspace.isPersonal ? (
          <div className={classes.buttonContainer}>
            {switchToSerial && (
              <Button onClick={switchToSerial} variant="outlined">
                Add by {Serial.text}
              </Button>
            )}
            <Button onClick={switchToManual}>Add manual device</Button>
            <Button onClick={cancelAction} variant="outlined">
              Cancel
            </Button>
          </div>
        ) : (
          <>
            {switchToSerial && (
              <Button onClick={switchToSerial} variant="outlined">
                Add by {Serial.text}
              </Button>
            )}
            <Button onClick={switchToManual} variant="text">
              Add manual device instead
            </Button>
            <HelpPopover
              tooltip="What is a manual device?"
              helpContent={
                <>
                  <Typography paragraph>
                    If your device is not listed here, you may define its specifications manually.
                  </Typography>
                  <Typography paragraph>
                    However, it is possible that the email address used to log in is different from
                    the one on the billing account. Please{" "}
                    <a href={CONTACT_SUPPORT_URL}>Contact Support</a> if you suspect this is the
                    case.
                  </Typography>
                  <Typography>
                    Alternatively, someone else on your team may have access to the device(s).
                    Please invite them to add the devices here.
                  </Typography>
                </>
              }
              maxWidth={350}
            />
            <div style={{ flexGrow: 1 }}></div>
            <Button onClick={cancelAction} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={okAction}
              color="primary"
              variant="contained"
              disabled={
                selection.length === 0 ||
                personalDevicesLoading ||
                personalDevicesError ||
                selectedWorkspace.isPersonal
              }
            >
              {`Add ${selection.length} Device${selection.length === 1 ? "" : "s"}`}
            </Button>
          </>
        )
      }
    >
      {personalDevicesLoading ? (
        <div className={classes.centerCenter}>
          <Typography variant="h5" className={classes.loadingText}>
            Loading devices from your personal workspace ...
          </Typography>
          <SpinnerInnovasea />
        </div>
      ) : personalDevicesError ? (
        <Alert severity="error">
          <AlertTitle>There was an error loading personal devices</AlertTitle>
          There may be an issue with the fathom server or authentication. Try signing in again.
        </Alert>
      ) : selectedWorkspace.isPersonal ? (
        <>
          <Typography className={classes.instructions}>
            This is your personal workspace, so any devices you’ve purchased from Innovasea should
            be visible.
          </Typography>
          <Typography component="div" color="textSecondary">
            <Typography gutterBottom>
              If your device is not listed here, you may define its specifications manually.
            </Typography>
            <Typography gutterBottom>
              However, it is possible that the email address used to log in is different from the
              one on the billing account. Please{" "}
              <a href="https://support.fishtracking.innovasea.com/s/contactsupport">
                contact support
              </a>{" "}
              if you suspect this is the case.
            </Typography>
            <Typography component="div">
              Alternatively, someone else on your team may have access to the device(s). Please{" "}
              <a href="/workspaces?create=true">create a workspace</a> and invite them to add the
              devices there.
            </Typography>
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="subtitle1" className={classes.instructions}>
            Select devices from your personal workspace to add to the current workspace: "
            {selectedWorkspace.name}"
          </Typography>
          <div className={classes.content}>
            <DevicesTable
              devices={personalDevices}
              selection={selection}
              setSelection={setSelection}
            />
          </div>
          <Typography className={classes.info}>
            Selected: {selection.length} of {personalDevices.length}
          </Typography>
          {deviceCopyMessage}
        </>
      )}
    </DialogWrapper>
  );
}

DevicesAddDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedWorkspace: PropTypes.object,
  personalDevices: PropTypes.array,
  personalDevicesLoading: PropTypes.bool,
  personalDevicesError: PropTypes.object,
  selection: PropTypes.array,
  setSelection: PropTypes.func,
  copyDevicesToWorkspace: PropTypes.func,
  switchToManual: PropTypes.func,
};

export default DevicesAddDialog;
