/* The objects exported from this file are designed to help share code
 * wherever API calls are being made, whether as part of a  Redux action
 * or otherwise. */

import axiosBase from "axios";
import history from "./history";
import Auth from "@aws-amplify/auth";
import { GQL_SERVER_URL } from "../config";
import { set } from "lodash";

const GRAPHQL_URL = `${GQL_SERVER_URL}/graphql`;

/* A custom paramsSerializer is necessary because axios's default serializer
 * encodes ' ' as '+' instead of '%20', which differs from the js built-in
 * encodeURIComponent. The file server uses decodeURIComponent on each param
 * so this should always work. */
axiosBase.defaults.paramsSerializer = function (params) {
  return Object.entries(params)
    .filter(d => d[0] !== undefined && d[1] !== null)
    .map(d => d[0] + "=" + encodeURIComponent(d[1]))
    .join("&");
};

/*
  This gets run before every axios request - if they import axios from this file

  Adds the authorization header Bearer with the users cognito access token required
  by the Data API and Files API
  
  This ensures that we're using a non - expired token. Amplify automatically refreshes tokens
  in the background and we're going to get good token if we retrieve it from 
  Auth.currentAuthenticatedUser()
*/
axiosBase.interceptors.request.use(
  async function (config) {
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      config.headers.Authorization = "Bearer " + user.signInUserSession.accessToken.jwtToken;
      config.headers.common["feature-flags"] = localStorage.getItem("feature-flags") || "";
    } else {
      history.push("/learn");
    }
    return config;
  },
  function (error) {
    // Just let axios handle error
    return Promise.reject(error);
  }
);

export const axios = axiosBase;

export async function getActiveToken() {
  const user = await Auth.currentAuthenticatedUser();
  return user.signInUserSession.accessToken.jwtToken;
}

/**
 *
 * @param {string} workspaceId
 *
 * All of File Server, Data Server & Data Graph api requests need to get the workspace-id from
 * the http headers. This file creates a single instance of the axios that is
 * shared by all the api requests. This function allows setting the workpace-id header on that instance.
 * Changing the workspace context for all subsequent api calls
 */
export function setApiWorkspace(workspaceId) {
  axios.defaults.headers.common["workspace-id"] = workspaceId;
}

export function getToken(user) {
  /* Extract authentication token from a user, if the user is authenticated.
   * Otherwise update history to reflect redirection to /learn */
  if (user.isAuthenticated && user.user) {
    return user.user.signInUserSession.accessToken.jwtToken;
  } else {
    history.push("/learn");
  }
}

export function extractMessage(error) {
  return error.response ? error.response.data.message : error.message;
}
/**
 * Function that simplifies requesting to a GQL API with axios
 *
 * We've been having intermittent issues with axios/JS resetting connections
 * with our GQL server before the request could be completed. This has a retry loop
 * to catch if that error occures sleep and retry up to 3 times.
 */
export async function callGqlApi(query, variables, url = GRAPHQL_URL) {
  let retries = 3;
  while (retries > 0) {
    try {
      return await new Promise((resolve, reject) => {
        axios({
          url: url,
          method: "post",
          data: {
            query: query,
            variables: variables,
          },
        })
          .then(response => {
            if (!response.data.data) {
              reject(response.data.errors);
            } else {
              resolve(response.data.data);
            }
          })
          .catch(async error => {
            if (error.code === "ENOTFOUND") {
              console.error({ message: "DNS Error retrying request in 1 sec" });
              return;
            }
            if (error.response && error.response.data) {
              reject(error.response.data.errors);
            } else {
              reject({ ...error, message: error?.message });
            }
          });
      });
    } catch (e) {
      if (e.message === "Network Error") {
        console.error("Caught Network Error - Sleeping 1 sec then retrying");
      } else {
        throw e;
      }
    }
    await new Promise(res => setTimeout(res, 1000)); // sleep
    retries--;
  }
}

export function uploadFileGqlApi(query, fileVariablePath, file, variables = {}) {
  const formData = new FormData();

  // set the variable to null
  set(variables, fileVariablePath, null);

  /**
   * We upload the file to the API making a post request with form data.
   * Basically we provide the files in the form data and the mapping of the files
   * to the GraphQL mutation & variables. See the spec apollo follows here:
   *  https://github.com/jaydenseric/graphql-multipart-request-spec
   */
  formData.append("operations", JSON.stringify({ query, variables }));
  formData.append("map", JSON.stringify({ 0: [`variables.${fileVariablePath}`] }));
  formData.append("0", file, file.name);

  return new Promise((resolve, reject) => {
    axios({
      url: GRAPHQL_URL,
      method: "post",
      data: formData,
      headers: { "Apollo-Require-Preflight": true },
    })
      .then(response => {
        if (!response.data.data) {
          reject(response.data.errors);
        } else {
          resolve(response.data.data);
        }
      })
      .catch(error => {
        if (error.response && error.response.data) {
          reject(error.response.data.errors);
        } else {
          reject([error]);
        }
      });
  });
}
