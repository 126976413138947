/* Based on example provided in notistack documentation at
 * https://codesandbox.io/s/github/iamhosseindhv/notistack/tree/master/examples/redux-example
 * This component sits on top of the whole app and controls a single notistack of Snackbars,
 * keeping them in sync with redux.snackbar.notifications state.
 *
 * This component is the only component in the app wrapped in notistack's withSnackBar higher-
 * order-component. The rest will use redux actions to add notifications to the redux state, and
 * this component will render them. */

import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { Button } from "@material-ui/core";

import { removeSnackbar } from "../redux/snackbar/snackbar-actions";
import { downloadFilesApi } from "../redux/files/files-actions";
import { showStudyExportDetails } from "../redux/study/study-actions";
import { unretireDevices } from "../redux/devices/devices-actions";
import ProgressBarSnack from "../components/notifications/ProgressBarSnack";
import FileDownloadSnack from "../components/notifications/FileDownloadSnack";
import FileProcessingSnack from "../components/notifications/FileProcessingSnack";
import { DetectionDownloadSnack } from "../components/notifications/DetectionDownloadSnack";

class Notifier extends Component {
  constructor(props) {
    super(props);
    this.displayed = [];
  }

  shouldComponentUpdate({ notifications: newSnacks }) {
    const newKeys = newSnacks.map(snack => snack.key);

    /* Iterate through currently displayed snackbars and close any
     * that have been removed from redux. */
    this.displayed.forEach(key => {
      if (!newKeys.includes(key)) {
        this.props.closeSnackbar(key);
        this.displayed = this.displayed.filter(d => d !== key);
      }
    });

    /* Trigger an update if any new snacks not already displayed. */
    return newKeys.some(key => !this.displayed.includes(key));
  }

  componentDidUpdate() {
    // Iterate through each notification that exists in redux but is not yet displayed.
    this.props.notifications
      .filter(d => !this.displayed.includes(d.key))
      .forEach(({ type, message, variant, options = {}, key, componentId, componentProps }) => {
        if (type === "basic") {
          this.enqueueBasic(message, variant, options, key);
        } else if (type === "custom") {
          this.enqueueCustom(componentId, componentProps, key);
        }

        this.displayed = [...this.displayed, key];
      });
  }

  /* Interface to notistack's enqueuSnackbar function for a simple snackbar, that justs
   * consists of a Snackbar with a text message. */
  enqueueBasic(message, variant, options, key) {
    this.props.enqueueSnackbar(message, {
      variant: variant,
      key: key,
      preventDuplicate: true,
      onClose: () => this.props.dispatch(removeSnackbar(key)),
      ...options,
    });
  }

  /* Interface to notistack's enqueuSnackbar function for a more complex custom snackbar.
   * We want to avoid storing react components in redux, so instead the redux store records
   * an id that represents which component should be rendered in the notification, along with
   * any props required. */
  enqueueCustom(id, customProps, key) {
    const { dispatch, enqueueSnackbar } = this.props;

    function onClose() {
      dispatch(removeSnackbar(key));
    }

    switch (id) {
      case "progress":
        enqueueSnackbar(
          <ProgressBarSnack
            message={customProps.message}
            variant={customProps.progressVariant}
            snackbarKey={key}
          />,
          {
            variant: "info",
            key: key,
            persist: true,
          }
        );
        break;
      case "file-download":
        enqueueSnackbar("file-download", {
          persist: true,
          key,
          content: <FileDownloadSnack id={key} message={"Downloads"} onClose={onClose} />,
        });
        break;
      case "detection-download": {
        enqueueSnackbar("detection-download", {
          persist: true,
          key,
          content: <DetectionDownloadSnack id={key} message={"Downloads"} onClose={onClose} />,
        });
        break;
      }
      case "device-retire-success":
        enqueueSnackbar("Devices have been retired.", {
          variant: "success",
          key: key,
          autoHideDuration: 6000,
          onClose,
          action: (
            <Button onClick={() => dispatch(unretireDevices(customProps.deviceIds))}>Undo</Button>
          ),
        });
        break;

      case "study-export-complete":
        enqueueSnackbar("OTN has received your files and can begin processing the data.", {
          variant: "success",
          key: key,
          persist: true,
          action: (
            <>
              <Button onClick={onClose}>Dismiss</Button>
              <Button onClick={() => dispatch(downloadFilesApi("", [customProps.filename]))}>
                Download
              </Button>
            </>
          ),
        });
        break;

      case "study-export-validation-error":
        enqueueSnackbar("Study submission failed: Validation Error", {
          variant: "error",
          key: key,
          persist: true,
          action: (
            <>
              <Button onClick={onClose}>Dismiss</Button>
              <Button
                onClick={() => {
                  onClose();
                  dispatch(showStudyExportDetails(customProps.studyId));
                }}
              >
                Details
              </Button>
            </>
          ),
        });
        break;

      case "file-processing": {
        enqueueSnackbar("file-processing", {
          persist: true,
          key,
          content: <FileProcessingSnack id={key} onClose={onClose} />,
        });
        break;
      }

      case "study-export-generic-error":
      case "study-export-generic-warnings":
        enqueueSnackbar(customProps.message, {
          variant: id.indexOf("warning") !== -1 ? "warning" : "error",
          key: key,
          persist: true,
          action: (
            <>
              <Button onClick={onClose}>Dismiss</Button>
              <Button
                onClick={() => {
                  onClose();
                  dispatch(showStudyExportDetails(customProps.studyId));
                }}
              >
                Details
              </Button>
            </>
          ),
        });
        break;
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  notifications: state.snackbar.notifications,
});

Notifier.propTypes = {
  notifications: PropTypes.array,
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func,
};

export default withSnackbar(connect(mapStateToProps)(Notifier));
