import { useEffect, useState } from "react";
import { set } from "lodash";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Typography, RadioGroup, Checkbox, Button } from "@material-ui/core";
import { Warning } from "@material-ui/icons";

import DialogWrapper from "../common/DialogWrapper";
import FlexRow from "../common/FlexRow";

import { Receiver } from "../../containers/DetectionAnalysis";
import PositionSummary from "./PositionSummary";
import HelpPopover from "../common/HelpPopover";
import { SpinnerInnovasea } from "../../fathom-brella";
import { Serial } from "../../helpers/glossary";

type Props = {
  /** All transmitters avaliable in workspace */
  receivers: Receiver[];
  /** The transmitters already included in the range test, in the form required by it */
  rxList: Receiver[];
  /** Function to set the new list of transmitters for range test */
  setRxList: (newRxList: Receiver[]) => void;
  closeFn: () => void;
  dataReady: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiFormControlLabel-root": { margin: 0 },
    "& .MuiIconButton-root": { padding: 7 },
  },
  header: {
    alignItems: "center",
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  heading: {
    // paddingLeft: theme.spacing(1),
  },
  row: {
    alignItems: "flex-start",
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: "relative",
  },
  gridText: {
    padding: theme.spacing(1),
    paddingLeft: 0,
  },
  manualInput: {
    "& .MuiInput-root": {
      marginTop: 0,
    },
    "& .MuiInputBase-input": {
      padding: 0,
      fontSize: "0.875rem",
      textAlign: "center",
    },
  },
  error: {
    fontWeight: "bold",
    color: theme.palette.error.main,
    paddingLeft: theme.spacing(1),
  },
  inputError: {
    boxShadow: "0px 0px 10px -4px red",
    borderRadius: 10,
  },
  disabled: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "silver",
    opacity: 0.6,
    zIndex: 10,
    paddingRight: theme.spacing(2),
  },
}));
export default function SelectRx({ receivers, rxList, setRxList, closeFn, dataReady }: Props) {
  const classes = useStyles();
  const [receiversTemp, setReceiversTemp] = useState<Receiver[]>([]);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState<string[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const devicesNeedMoreData = receivers.filter(
    rx => !rx.positions?.length || !rx.rxLogFiles?.length
  );
  const noHrDetections = receivers.filter(rx => rx.model?.indexOf("HR") > -1);
  useEffect(() => {
    if (dataReady) {
      // Dialog only includes transmitters that aren't selected already:
      const deviceIds = rxList.map(rx => rx.deviceId);
      const rxTemp = receivers.filter(
        rx =>
          !deviceIds.includes(rx.deviceId) &&
          rx.positions?.length &&
          rx.rxLogFiles?.length &&
          rx.model?.indexOf("HR") < 0
      );
      setReceiversTemp(rxTemp);
    }
  }, [dataReady, rxList, receivers]);
  function numOfRxs(noHrDetections) {
    if (noHrDetections.length === 1) {
      return "receiver";
    } else {
      return "receivers";
    }
  }

  function removeError(key) {
    if (errors[key]) {
      setErrors(prev => {
        const next = { ...prev };
        delete next[key];
        return next;
      });
    }
  }

  function toggleSelect(deviceId: string) {
    removeError("dialog");
    const newSelection = [...selectedDeviceIds];
    if (newSelection.includes(deviceId)) {
      newSelection.splice(
        newSelection.findIndex(id => id == deviceId),
        1
      );
    } else {
      newSelection.push(deviceId);
    }
    setSelectedDeviceIds(newSelection);
  }

  // This always adds if not there
  function selectRow(deviceId: string) {
    removeError("dialog");
    if (!selectedDeviceIds.includes(deviceId)) {
      const newSelection = [...selectedDeviceIds];
      newSelection.push(deviceId);
      setSelectedDeviceIds(newSelection);
    }
  }

  function handleChange({ idx, key, value }) {
    removeError(receiversTemp[idx].deviceId);
    setReceiversTemp(prev => {
      const changed = [...prev];
      set(changed[idx], key, value);
      return changed;
    });
  }

  function submit() {
    const newRxList: Receiver[] = [];
    const rxMap = {};
    receiversTemp.forEach((t, i) => {
      rxMap[t.deviceId] = i;
    });

    selectedDeviceIds.forEach(deviceId => {
      const rx = receiversTemp[rxMap[deviceId]];
      rx.position = rx.positions?.find(p => p.positionId == rx.selectedPositionId);
      newRxList.push(rx);
    });

    if (selectedDeviceIds.length < 1) {
      setErrors(prev => ({ ...prev, dialog: "Please select a receiver to continue" }));
      return;
    }

    if (newRxList.length > 0) {
      setRxList([...rxList, ...newRxList]);
      closeFn();
    }
  }

  const selectableIds = receiversTemp
    ?.filter(rx => rx.positions?.length && rx.rxLogFiles?.length)
    ?.map(rx => rx.deviceId);

  return (
    <DialogWrapper
      title="Select receivers"
      open={true}
      cancelAction={closeFn}
      okAction={submit}
      maxWidth="md"
      buttons={({ cancelAction, okAction }) => (
        <FlexRow fullWidth vAlign="center" spaceBetween itemSpacing={1}>
          <div className={classes.error}>{errors.dialog && errors.dialog}</div>
          <div>
            <Button onClick={cancelAction} variant="outlined">
              Cancel
            </Button>
            <Button onClick={okAction} color="primary" variant="contained">
              Add
            </Button>
          </div>
        </FlexRow>
      )}
    >
      {!dataReady ? (
        <SpinnerInnovasea text="Loading device data ..." />
      ) : (
        <Grid container spacing={0} className={classes.root}>
          <Grid container item xs={12} spacing={0} className={classes.header}>
            <Grid item xs={1}>
              <Checkbox
                size="small"
                checked={selectedDeviceIds.length == selectableIds.length}
                onChange={event => {
                  let newSelection: string[] = [];
                  if (event.target.checked) {
                    newSelection = [...selectableIds];
                  }
                  setSelectedDeviceIds(newSelection);
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Typography variant="subtitle2" className={classes.heading}>
                Model
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle2" className={classes.heading}>
                {Serial.title}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" className={classes.heading}>
                Log files
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" className={classes.heading}>
                Position
              </Typography>
            </Grid>
          </Grid>
          {receiversTemp.map((rx, idx) => {
            return (
              <Grid
                key={rx.deviceId}
                container
                item
                xs={12}
                spacing={0}
                className={classes.row}
                onClick={() => selectRow(rx.deviceId)}
              >
                <Grid item xs={1}>
                  <Checkbox
                    size="small"
                    checked={selectedDeviceIds.includes(rx.deviceId)}
                    onClick={() => toggleSelect(rx.deviceId)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2" className={classes.gridText}>
                    {rx.model}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body2" className={classes.gridText}>
                    {rx.serial}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  {rx.rxLogFiles?.length ? (
                    <Typography variant="body2" className={classes.gridText}>
                      {rx.rxLogFiles?.join(", ")}
                    </Typography>
                  ) : (
                    <FlexRow vAlign="center" itemSpacing={1}>
                      <Warning />
                      <Typography variant="body2">Please upload receiver log</Typography>
                    </FlexRow>
                  )}
                </Grid>

                <Grid item xs={4}>
                  {rx.positions?.length && (
                    <RadioGroup
                      name="positions"
                      value={null}
                      onChange={event =>
                        handleChange({ idx, key: "selectedPositionId", value: event.target.value })
                      }
                    >
                      {rx.positions.map(p => (
                        <PositionSummary
                          key={p.positionId}
                          position={p}
                          selectedPositionId={rx.selectedPositionId}
                          selectable={true}
                        />
                      ))}
                    </RadioGroup>
                  )}
                </Grid>
                {!selectableIds.includes(rx.deviceId) && (
                  <FlexRow
                    className={classes.disabled}
                    onClick={event => event.stopPropagation()}
                  ></FlexRow>
                )}
              </Grid>
            );
          })}
        </Grid>
      )}
      {noHrDetections.length > 0 && (
        <FlexRow vAlign="center" paddingLevel={3}>
          <Typography variant="subtitle1">
            {noHrDetections.length} {numOfRxs(noHrDetections)} cannot be included because support
            for working with detection data from HR2 and HR3 receivers is under development.
          </Typography>
        </FlexRow>
      )}
      {devicesNeedMoreData.length > 0 && (
        <FlexRow vAlign="center" paddingLevel={3}>
          <Typography variant="subtitle1" color="error">
            {devicesNeedMoreData.length} other receivers need a log file to be uploaded and a
            deployment record with a position to be included for selection.
          </Typography>
          <HelpPopover
            tooltip="more info"
            helpContent="Detection analysis requires positions and log files to function. Please ensure there is a log file and a deployment with position for each device you wish to include."
          />
        </FlexRow>
      )}
    </DialogWrapper>
  );
}
