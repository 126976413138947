import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BubbleColorPicker from "./BubbleColorPicker";
import MapStylePicker from "./MapStylePicker";
import TagIdPicker from "./TagIdPicker";
import SideBar from "../../SideBar";
import ListItemSwitch from "../../ListItemSwitch";
import { List, FormHelperText, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Equalizer as IconEqualizer } from "@material-ui/icons";
const useStyles = makeStyles(theme => ({
  helperText: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    whiteSpace: "normal",
  },
  listItemIcon: {
    maxWidth: 56,
    marginLeft: -16,
    marginRight: 16,
  },
}));

function BubblePlotSideBar({
  demoMode,
  singleTagMode,
  tagList,
  idSelection,
  onlyUserAnimals,
  mapStyle,
  mapStyleHandler,
  animalIds,
  dataAvailable,
  colorHandler,
  idHandler,
  handleOnlyAnimalSwitch,
  handleBinSizeClicked,
  minBinSize,
}) {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const classes = useStyles();

  const noAnimalsMarked = animalIds && !animalIds.length;

  const helperMessage =
    singleTagMode || !drawerOpen || !dataAvailable ? (
      ""
    ) : demoMode ? (
      <span>
        <strong>Demo mode active.</strong> You are viewing a detection map of sample data from the{" "}
        <a
          href="https://glatos.glos.us/home/project/HECWL"
          rel="noopener noreferrer"
          target="_blank"
        >
          Huron Erie Walleye Spatial Ecology
        </a>{" "}
        study.
      </span>
    ) : noAnimalsMarked ? (
      <>
        There are no tags associated with animals in your study. To enable automatic animal tag
        filtering, please go to Animals and add the relevant tagging data.
      </>
    ) : onlyUserAnimals ? (
      <>
        Only displaying detections animals linked to your current study. To edit the list of animals
        and their tagging events, please go to Animals.
      </>
    ) : (
      "Displaying all tags."
    );

  return (
    <SideBar
      open={drawerOpen}
      handleDrawerClose={() => setDrawerOpen(false)}
      handleDrawerOpen={() => setDrawerOpen(true)}
    >
      <List>
        <BubbleColorPicker colorHandler={colorHandler} />
        <MapStylePicker mapStyle={mapStyle} mapStyleHandler={mapStyleHandler} />
        {!singleTagMode && (
          <TagIdPicker selection={idSelection} selectionHandler={idHandler} tagList={tagList} />
        )}
        <ListItem button onClick={handleBinSizeClicked}>
          <ListItemIcon>
            <IconEqualizer />
          </ListItemIcon>
          <ListItemText>
            Bin size: {` ${minBinSize} ${minBinSize === 1 ? "hour" : "hours"}`}
          </ListItemText>
        </ListItem>
        {!demoMode && !singleTagMode && (
          <ListItemSwitch
            text="Only study animals"
            checked={onlyUserAnimals}
            onChange={handleOnlyAnimalSwitch}
            disabled={!dataAvailable || noAnimalsMarked}
            tooltip={{
              text: "Toggle automatic filtering of marked animals tags.",
              placement: "right",
            }}
            listItemIconStyle={classes.listItemIcon}
          />
        )}
        <div className={classes.helperText}>
          <FormHelperText>{helperMessage}</FormHelperText>
        </div>
      </List>
    </SideBar>
  );
}

export default BubblePlotSideBar;
