import { Thunk } from "../common";
import {
  DetectionAction,
  DetectionRow,
  DetectionFilterInput,
  DetectionCountOptions,
  DetectionCountHistogram,
  DetectionCountAbacus,
  DetectionCountAPIResAbacus,
  DetectionDownloadJob,
} from "./detection-types";
import { RxList } from "../../components/detection/detection-types";
import { customSnackbar, removeSnackbar, snackbarError } from "../snackbar/snackbar-actions";
import { axios, callGqlApi } from "../../helpers/api";
import gql from "../gqlTag";
import * as d3 from "d3";
import { handleGqlErrors } from "../gql-error/gql-error-actions";
import { createDownloadFromBlob } from "../files/files-actions";
import { isEqual } from "lodash";
import { sleep } from "../../helpers/common";
import { formatDateTimeFilename } from "../../helpers/time";
import { GQL_SERVER_URL } from "../../config";
import { TEMP_TABLE_ROW_LIMIT } from "../../components/detection/detection-consts";
import { SnackBarAction } from "../snackbar/snackbar-types";
const DOWNLOAD_SNACKBAR_KEY = "detection-download";

export function queryDetectionSummary(): Thunk<void, DetectionAction> {
  return async dispatch => {
    dispatch({ type: "DETECTION_SUMMARY_START_LOADING_DATA" });
    const summaryQuery = gql`
      query {
        detectionSummary {
          totalDetections
          uniqueIds
          uniqueReceivers
          idSummary {
            idCount {
              displayId
              count
              animalIds
            }
            receivers {
              serial
              deviceId
            }
          }
          receiverSummary {
            count
            receiver {
              serial
              deviceId
            }
            idCounts {
              displayId
              count
              animalIds
            }
          }
        }
      }
    `;
    callGqlApi(summaryQuery)
      .then(res => dispatch({ type: "DETECTION_SUMMARY_SET", payload: res.detectionSummary }))
      .catch(errors => {
        console.error(errors);
        dispatch({ type: "DETECTION_SUMMARY_ERROR" });
        dispatch(
          snackbarError(`There was an error fetching detection data. Please try again later`) as any
        );
      });
  };
}

export function resetTable(): Thunk<void, DetectionAction> {
  return dispatch => {
    dispatch({
      type: "DETECTION_RESET_TABLE",
    });
  };
}

export function queryDetections(
  filters: DetectionFilterInput,
  rxs: RxList
): Thunk<void, DetectionAction> {
  return async (dispatch, getState) => {
    let resDet;
    let currentRows: DetectionRow[] = [];
    let start = 0;
    const fileList = getState().files.fileList;
    const rxSerials = rxs.map(rx => rx.serial);
    const filesWithRelevantRxs = fileList.filter(
      f => f.rxLogProperties && rxSerials?.includes(f.rxLogProperties.serial)
    );

    // Get the files to populate file name column
    const relevantFiles =
      filters?.includeReceiverSerials?.length === 0
        ? filesWithRelevantRxs
        : filesWithRelevantRxs.filter(
            f =>
              f.rxLogProperties &&
              filters.includeReceiverSerials?.includes(f.rxLogProperties.serial)
          );
    // Fetch loop
    dispatch({ type: "DETECTION_TABLE_START_LOADING" });
    try {
      do {
        resDet = await callGqlApi(
          gql`
            query allDet($start: Int!, $pageSize: Int!, $filters: DetectionFilterInput) {
              allDetections(start: $start, pageSize: $pageSize, filters: $filters) {
                data
                nextPageStart
              }
            }
          `,
          { start, pageSize: 100000, filters }
        );

        // Stop loop if this query has been cancelled
        if (!isEqual(getState().detection.currentFilters, filters)) {
          break;
        }

        // parse data and link file ids
        const data = d3.csvParse(resDet.allDetections.data) as any[];
        currentRows = [
          ...currentRows,
          ...data.map(r => ({
            ...r,
            id: `${r.time}-${r.full_id}`,
            sensor_value:
              r.sensor_value?.constructor === Number ? parseFloat(r.sensor_value) : r.sensor_value,
            fileNames: relevantFiles
              .filter(f => r.files.includes(f.id))
              .map(f => f.name)
              .join(", "),
          })),
        ];

        dispatch({
          type: "DETECTION_SET_TABLE",
          payload: {
            rows: currentRows,
            state: "LOADING",
          },
        });

        start = resDet.allDetections.nextPageStart; // start will be null once all detections are fetched
      } while (currentRows.length < TEMP_TABLE_ROW_LIMIT && start !== null);
      dispatch({
        type: "DETECTION_TABLE_COMPLETE",
      });
    } catch (e) {
      dispatch({
        type: "DETECTION_TABLE_ERROR",
      });
      dispatch(handleGqlErrors(e));
    }
  };
}

export function cancelCurrentQuery(): Thunk<void, DetectionAction> {
  return (dispatch, getState) => {
    if (getState().detection.table.status === "LOADING") {
      dispatch({
        type: "DETECTION_TABLE_CANCEL",
      });
    }
  };
}

/** Queries the detectionCountsPerInterval gql endpoint and returns the result. Errors are
 * intentionally not caught so that the calling function can handle it with dispatch.
 *
 * The result is left in its "pure" state because the caller may wish to parse results differently
 */
async function fetchDetectionCountsPerIntervalGql(
  filters: DetectionFilterInput,
  options: DetectionCountOptions
) {
  const detCountsResult: {
    detectionCountsPerInterval: {
      periodMs: number[];
      count: number[];
      serial?: string[];
      fullId?: string[];
    };
  } = await callGqlApi(
    gql`
      query fetchDetectionCountsPerInterval(
        $filters: DetectionFilterInput!
        $options: DetectionCountOptions!
      ) {
        detectionCountsPerInterval(filters: $filters, options: $options) {
          periodMs
          count
          serial
          fullId
        }
      }
    `,
    { filters, options }
  );
  return detCountsResult.detectionCountsPerInterval;
}

export function fetchDetectionCountsHistogram(
  filters: DetectionFilterInput,
  binIntervalSeconds = 3600
): Thunk<void, DetectionAction> {
  // this may move into state if we decide on dynamic bin size:
  const histogramDataOptions: DetectionCountOptions = {
    binIntervalSeconds,
    byReceiverSerial: false,
    byTransmitterIDs: false,
  };
  return async (dispatch, getState) => {
    if (getState().detection.histogram.status === "UNLOADED") {
      dispatch({ type: "DETECTION_SET_HISTOGRAM_INIT_LOADING", payload: { filters } });
    } else {
      dispatch({ type: "DETECTION_SET_HISTOGRAM_LOADING", payload: { filters } });
    }
    const detCountsHistogram: DetectionCountHistogram[] = [];

    let detCountsFetched: {
      periodMs: number[];
      count: number[];
    };

    try {
      detCountsFetched = await fetchDetectionCountsPerIntervalGql(filters, histogramDataOptions);
    } catch (ex) {
      dispatch(handleGqlErrors(ex));
      return;
    }

    try {
      if (detCountsFetched?.periodMs?.length && detCountsFetched?.count?.length) {
        detCountsFetched.periodMs.forEach((dt, idx) => {
          detCountsHistogram.push({
            dt,
            count: detCountsFetched.count[idx],
          });
        });
      }

      dispatch({
        type: "DETECTION_SET_HISTOGRAM",
        payload: { detCountsHistogram, filters },
      });
    } catch (ex) {
      console.error("fetchDetectionCountsHistogram failed to process data", ex);
    }
  };
}

export function queryAbacus(
  filters: DetectionFilterInput,
  abacusOptions: DetectionCountOptions
): Thunk<void, DetectionAction> {
  return async (dispatch, getState) => {
    if (getState().detection.abacus.status === "UNLOADED") {
      dispatch({ type: "DETECTION_SET_ABACUS_INIT_LOADING", payload: { filters } });
    } else {
      dispatch({ type: "DETECTION_SET_ABACUS_LOADING", payload: { filters } });
    }

    try {
      const detCountsFetched = (await fetchDetectionCountsPerIntervalGql(
        filters,
        abacusOptions
      )) as DetectionCountAPIResAbacus;

      const countPerGroup: DetectionCountAbacus[] = [];

      if (detCountsFetched?.periodMs?.length && detCountsFetched?.count?.length) {
        detCountsFetched.periodMs.forEach((dt, idx) =>
          countPerGroup.push({
            dt,
            count: detCountsFetched.count[idx],
            rx: detCountsFetched.serial[idx],
            tx: detCountsFetched.fullId[idx],
          })
        );
      }
      dispatch({ type: "DETECTION_SET_ABACUS_DATA", payload: { countPerGroup, filters } });
    } catch (ex) {
      dispatch(handleGqlErrors(ex));
      return;
    }
  };
}

export function clearDetectionData(): Thunk<void, DetectionAction> {
  return dispatch => {
    dispatch({
      type: "DETECTION_CLEAR_DATA",
    });
  };
}

export function getDetCountApi(filters: DetectionFilterInput): Thunk<void, DetectionAction> {
  return (dispatch, getState) => {
    dispatch({ type: "DETECTION_COUNT_LOADING", payload: { filters } });
    const query = gql`
      query allDetCount($filters: DetectionFilterInput) {
        allDetectionCount(filters: $filters)
      }
    `;
    callGqlApi(query, {
      filters,
    })
      .then(data => {
        const lastFilters = getState().detection.count.filters;
        if (isEqual(lastFilters, filters)) {
          dispatch({ type: "DETECTION_SET_COUNT", payload: { count: data.allDetectionCount } });
        }
      })
      .catch(e => console.error("GQL ERROR: ", e));
  };
}

/** Shows or hides the download notification depending on the downloads state */
function toggleDetectionDownloadSnack(): Thunk<void, SnackBarAction> {
  return (dispatch, getState) => {
    const notifications = getState().snackbar.notifications;
    const downloads = getState().detection.downloads;
    // if there are downloads in the state and the notification isn't present, invoke it
    if (Object.keys(downloads).length) {
      if (notifications && !notifications.some(n => n.componentId === DOWNLOAD_SNACKBAR_KEY)) {
        dispatch(customSnackbar(DOWNLOAD_SNACKBAR_KEY, {}, DOWNLOAD_SNACKBAR_KEY));
      }
    }
    // otherwise clear the notification
    else {
      dispatch(removeSnackbar(DOWNLOAD_SNACKBAR_KEY));
    }
  };
}

export function clearDownloadDetection(jobId: string): Thunk<void, DetectionAction> {
  return dispatch => {
    dispatch({ type: "DETECTION_DOWNLOAD_CLEAR", payload: { jobId } });
    dispatch(toggleDetectionDownloadSnack());
  };
}

export function startDownloadDetections(
  filters: DetectionFilterInput,
  detCount: number
): Thunk<void, DetectionAction | SnackBarAction> {
  return async dispatch => {
    let dlJob = {} as DetectionDownloadJob;
    let jobId = "";
    const fileName = `detections_${formatDateTimeFilename()}.csv`;

    try {
      const { startDownloadDetections } = await callGqlApi(
        gql`
          mutation startDownloadDetections($filters: DetectionFilterInput) {
            startDownloadDetections(filters: $filters) {
              id
              percentComplete
              complete
              apiPath
              error
              errorMsg
              creationTime
            }
          }
        `,
        { filters }
      );
      dlJob = (startDownloadDetections || {}) as DetectionDownloadJob;
      jobId = dlJob.id?.toString() || "";

      // prepare download:
      dispatch({
        type: "DETECTION_DOWNLOAD_STATUS",
        payload: {
          jobId,
          fileName,
          detCount,
          stage: "PREPARE",
          percentComplete: dlJob.percentComplete,
          errorMsg: dlJob.errorMsg,
        },
      });
      dispatch(toggleDetectionDownloadSnack());

      // if not complete, poll server for status
      while (dlJob.error === false && dlJob.complete === false) {
        const { downloadJob } = await callGqlApi(
          gql`
            query getJob($jobId: ID!) {
              downloadJob(jobId: $jobId) {
                id
                percentComplete
                complete
                error
                errorMsg
                apiPath
              }
            }
          `,
          { jobId }
        );
        dlJob = (downloadJob || {}) as DetectionDownloadJob;

        // Update download prepare progress:
        dispatch({
          type: "DETECTION_DOWNLOAD_STATUS",
          payload: {
            jobId,
            stage: "PREPARE",
            percentComplete: dlJob.percentComplete,
            errorMsg: dlJob.errorMsg,
          },
        });

        // wait a bit and loop around unless complete or error
        if (dlJob.complete || dlJob.error === true || dlJob.error === undefined) {
          break;
        } else {
          await sleep(500);
        }
      }

      // Bail conditions:
      if (dlJob.error === true || dlJob.error === undefined) {
        console.error(`Detection download error preparing file:`, dlJob.errorMsg);
        dispatch({
          type: "DETECTION_DOWNLOAD_STATUS",
          payload: {
            jobId,
            stage: "ERROR",
            percentComplete: 0,
            errorMsg: dlJob.errorMsg,
          },
        });
        return;
      }

      // All good, start download
      if (dlJob.complete === true) {
        const url = `${GQL_SERVER_URL}${dlJob.apiPath}`;
        // update status: file download start:
        dispatch({
          type: "DETECTION_DOWNLOAD_STATUS",
          payload: {
            jobId,
            stage: "DOWNLOAD",
            percentComplete: 0,
            errorMsg: null,
          },
        });

        axios
          .get(url, {
            responseType: "blob",
            onDownloadProgress: progressEvent => {
              try {
                const { loaded } = progressEvent;
                const total = progressEvent.srcElement.getResponseHeader("x-file-size");

                if (total) {
                  const percentComplete = Math.floor((loaded / total) * 100);

                  //Update download progress:
                  dispatch({
                    type: "DETECTION_DOWNLOAD_STATUS",
                    payload: {
                      jobId,
                      stage: "DOWNLOAD",
                      percentComplete,
                    },
                  });
                }
              } catch (ex) {
                console.error(`Detection download error downloading file: `, ex);
                dispatch({
                  type: "DETECTION_DOWNLOAD_STATUS",
                  payload: {
                    jobId,
                    stage: "ERROR",
                    percentComplete: 0,
                    errorMsg: ex,
                  },
                });
              }
            },
          })
          .then(({ data }) => {
            dispatch({
              type: "DETECTION_DOWNLOAD_STATUS",
              payload: {
                jobId,
                stage: "COMPLETE",
                percentComplete: 100,
                errorMsg: null,
              },
            });
            // remove the item from the store once complete
            setTimeout(() => {
              dispatch(clearDownloadDetection(jobId));
            }, 2000);
            createDownloadFromBlob(data, fileName);
          });
      }
    } catch (ex) {
      console.error(`Detection download uncaught error: `, ex);
      dispatch({
        type: "DETECTION_DOWNLOAD_STATUS",
        payload: {
          jobId,
          stage: "ERROR",
          errorMsg: ex,
        },
      });
    }
  };
}
