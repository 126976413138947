import { Tooltip } from "@material-ui/core";
import { WindowedTable } from "../../../fathom-brella";
import { formatDateTime } from "../../../helpers/time";
import { PipelineFailure, FailedFile } from "../../../redux/admin/pipeline/pipeline-types";

export function FailedFilesTable({
  searchText,
  failedFiles,
  showHistoryFn,
  selection,
  setSelection,
  disabledIds,
}: {
  failedFiles: PipelineFailure[] | FailedFile[];
  searchText?: string;
  showHistoryFn?: (history: PipelineFailure[]) => void;
  selection?: any;
  setSelection?: any;
  disabledIds?: number[];
}) {
  const showHistory = showHistoryFn ? showHistoryFn : () => {};
  const isDetails = !showHistoryFn; // if no showHistoryFn then this is history which is workspace specific
  return (
    <WindowedTable
      searchText={searchText}
      rows={failedFiles}
      rowIdKey={"id"}
      disabledIds={disabledIds}
      selectable={!isDetails}
      selection={selection}
      onSelect={setSelection}
      columns={[
        {
          width: 400,
          dataKey: "fileName",
          label: "File name",
          renderFn: (fileName, row) => (
            <Tooltip
              title={`${!isDetails ? "Click to see failure history for file:  " : ""}${fileName}`}
            >
              <span style={{ width: "100%" }} onClick={() => showHistory(row.history)}>
                {fileName}
              </span>
            </Tooltip>
          ),
        },
        {
          width: 90,
          dataKey: "resolved",
          label: "Resolved?",
          renderFn: resolved => (resolved ? "yes" : "no"),
        },
        {
          width: 90,
          dataKey: "workspaceId",
          label: !isDetails ? "Workspaces" : "WorkspaceId",
          renderFn: (_, row) => (!isDetails ? row.workspaces.join(", ") : row.workspaceId),
        },
        { width: 80, dataKey: "errorPhase", label: "Phase" },
        { width: 90, dataKey: "errorStep", label: "Step" },
        {
          width: 150,
          dataKey: "updateTime",
          label: "Update time",
          renderFn: updateTime => formatDateTime(updateTime),
        },
        {
          width: 350,
          dataKey: "errorMessage",
          label: "Error message",
          renderFn: errorMessage => (
            <Tooltip title={errorMessage}>
              <div>{errorMessage}</div>
            </Tooltip>
          ),
        },
      ]}
    />
  );
}
