import PropTypes from "prop-types";
import { WindowedTable } from "../../fathom-brella";
import { formatFileSize } from "../../helpers/common";
import { formatDateTime } from "../../helpers/time";
import StudyLinkChips from "../../components/study/StudyLinkChips";
import { CircularProgress, Tooltip } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { studyNames } from "../../helpers/study";

function FilesTable({
  selection,
  setSelection,
  files,
  searchText,
  showLinkedStudies,
  removeFromStudy,
  activeTab,
}) {
  const visibleFiles = files.filter(file => {
    return (
      activeTab == "All" ||
      (activeTab == "Processing" && file.processing) ||
      (activeTab == "Processed" && !file.processing)
    );
  });

  const fileTableData = visibleFiles.map(f => ({
    ...f,
    offloadTime: f.rxLogProperties ? f.rxLogProperties.offloadTime : null,
  }));

  const disabledSelectIds = fileTableData.reduce((ids, f) => {
    if (f.processing || f.error) {
      ids.push(f.name);
    }
    return ids;
  }, []);

  const columns = [
    {
      width: 35,
      label: "",
      dataKey: "processing",
      flexGrow: 0,
      searchTextFn: () => "",
      renderFn: (processing, row) => {
        if (row.error) {
          return (
            <Tooltip title="There was an error processing this receiver log. The Fathom team is looking into it.">
              <ErrorOutlineIcon size={20} style={{ marginTop: 5 }} />
            </Tooltip>
          );
        } else if (processing) {
          return (
            <Tooltip title="Processing file. Data will be available shortly">
              <CircularProgress size={20} style={{ marginTop: 5 }} />
            </Tooltip>
          );
        } else {
          return "";
        }
      },
    },
    {
      width: 200,
      label: "File name",
      dataKey: "name",
      searchTextFn: name => name,
      renderFn: (name, row) =>
        row.processing ? (
          <Tooltip title="Processing file. Data will be available shortly">
            <span>{name}</span>
          </Tooltip>
        ) : (
          name
        ),
    },
    {
      width: 80,
      label: "Size",
      dataKey: "size",
      searchTextFn: () => "",
      renderFn: formatFileSize,
      numeric: true,
      flexShrink: 1,
    },
    {
      width: 150,
      label: "Offload time",
      dataKey: "offloadTime",
      renderFn: time => formatDateTime(time, false),
      flexGrow: 0,
    },
    {
      width: 150,
      label: "Upload time",
      dataKey: "lastModified",
      renderFn: time => formatDateTime(time, false),
      flexGrow: 0,
    },
    ...(showLinkedStudies
      ? [
          {
            width: 150,
            label: "In studies",
            dataKey: "inStudies",
            searchTextFn: studyNames,
            renderFn: (studies, rowData) => (
              <StudyLinkChips
                studies={studies}
                removeFromStudy={removeFromStudy}
                objectId={rowData.name}
              />
            ),
            noEllipses: true,
          },
        ]
      : []),
  ];

  return (
    <WindowedTable
      rows={fileTableData}
      rowIdKey="name"
      selectable={true}
      headerHeight={60}
      onSelect={setSelection}
      selection={selection}
      searchText={searchText}
      selectOnRowClick
      columns={columns}
      disabledIds={disabledSelectIds}
    />
  );
}

FilesTable.propTypes = {
  selection: PropTypes.array.isRequired,
  setSelection: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  searchText: PropTypes.string,
  showLinkedStudies: PropTypes.bool,
};
export default FilesTable;
