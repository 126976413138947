import { useEffect, useState } from "react";
import {
  IconButton,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
  Tooltip,
} from "@material-ui/core";
import {
  Refresh as IconRefresh,
  Error as IconError,
  PlayArrow as IconPlayArrow,
  DeleteSweep as IconDeleteSweep,
  Stop as IconStop,
} from "@material-ui/icons";

import FlexCol from "../../components/common/FlexCol";
import { TableSearchBar, WindowedTable } from "../../fathom-brella";
import FlexRow from "../../components/common/FlexRow";
import { SoftTitle, Title } from "../../components/common/typography";
import DialogWrapper from "../../components/common/DialogWrapper";

import { useSelectorTyped as useSelector, useThunkDispatch } from "../../redux/common";
import {
  queryPipelineFailures,
  reprocessFiles,
  actionClearDone,
  checkReprocessing,
  actionStopMonitoring,
} from "../../redux/admin/pipeline/pipeline-actions";
import {
  FailedFile,
  PipelineFailure,
  REPROCESS_STATUS_COLOR,
} from "../../redux/admin/pipeline/pipeline-types";
import { FailedFilesTable } from "../../components/admin/pipeline/FailedFilesTable";
import ResizableSplitPanel from "../../components/common/ResizableSplitPanel";

export function Pipeline() {
  const dispatch = useThunkDispatch();
  const [searchText, setSearchText] = useState<string>("");
  const [showResolved, setShowResolved] = useState<boolean>(false);
  const [showHistory, setShowHistory] = useState<PipelineFailure[]>([]);
  const [selection, setSelection] = useState<number[]>([]);

  useEffect(() => {
    dispatch(queryPipelineFailures());
  }, [dispatch]);

  const {
    pipelineFailedFiles,
    pipelineFailuresError,
    pipelineFailuresLoading,
    reprocessing,
    reprocessingErrorIds,
  } = useSelector(({ adminPipeline }) => {
    const pipelineFailuresLoading = adminPipeline.failures.loading;
    const pipelineFailuresError = adminPipeline.failures.error;
    const pipelineFailuresList = adminPipeline.failures.list;
    const pipelineFailedFiles = [] as FailedFile[];

    // group the pipeline failures by fileId:
    const fileFailureMap: { [key: string]: PipelineFailure[] } = {};
    pipelineFailuresList.forEach(pipelineFailure => {
      if (!fileFailureMap[pipelineFailure.fileId]) {
        fileFailureMap[pipelineFailure.fileId] = [];
      }
      fileFailureMap[pipelineFailure.fileId].push(pipelineFailure);
    });
    // include reprocessing status in table to disable selection for re-processing:
    const { reprocessing } = adminPipeline;
    const reprocessingErrorIds = reprocessing.files
      .filter(q => q.status == "PROCESSING")
      .map(q => q.id);

    // process failure messages per fileId:
    const fileIds = Object.keys(fileFailureMap);
    fileIds.forEach(fileId => {
      const history = fileFailureMap[fileId];
      const workspaces = {};
      // sort the failures per file by updateTime descending
      history.sort((t1, t2) =>
        t1.updateTime == t2.updateTime ? 0 : t1.updateTime > t2.updateTime ? -1 : 1
      );
      // list workspaces the file is in:
      history.forEach(f => (workspaces[f.workspaceId] = 1));

      // check if the most recent processing of the file has failed:
      const mostRecentFailure = history[0];
      const resolved = mostRecentFailure?.mergeInfo?.mergeStatus?.phaseStatus == "READY";
      if (!resolved || showResolved) {
        // if it has, add it to the failed files list along with history of failures:
        pipelineFailedFiles.push({
          ...mostRecentFailure,
          workspaces: Object.keys(workspaces),
          history,
          resolved,
        });
      }
    });

    return {
      pipelineFailuresLoading,
      pipelineFailuresError,
      pipelineFailedFiles,
      reprocessing,
      reprocessingErrorIds,
    };
  });

  function doReprocess() {
    const files = pipelineFailedFiles.filter(f => selection.includes(f.id));
    console.log({ selection, files });
    dispatch(reprocessFiles(files));
    setSelection([]);
  }

  return (
    <FlexCol fullWidth fullHeight>
      <ResizableSplitPanel
        direction="horizontal"
        firstInit="80%"
        secondMin={300}
        firstContent={
          <FlexCol paddingLevel={1} style={{ flexGrow: 1 }}>
            <FlexRow vAlign="center" paddingLevel={1} itemSpacing={3}>
              <Title>File Pipeline Failures</Title>
              {pipelineFailuresLoading ? (
                <CircularProgress size={16} />
              ) : (
                <Title>{pipelineFailedFiles.length}</Title>
              )}
              <Button
                variant="contained"
                size="small"
                disabled={!selection?.length}
                onClick={doReprocess}
                role="re-process"
              >
                Re-process Selected
              </Button>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={showResolved} role="show-resolved" />}
                  label="Show resolved"
                  onClick={() => setShowResolved(!showResolved)}
                  style={{ flexGrow: 0, flexWrap: "nowrap" }}
                  title="include files that ultimately had success"
                />
              </FormGroup>
              <TableSearchBar
                searchText={searchText}
                handleSearch={searchText => setSearchText(searchText)}
              />
              <IconButton
                size="small"
                onClick={() => dispatch(queryPipelineFailures())}
                title="Refetch"
              >
                <IconRefresh />
              </IconButton>
            </FlexRow>
            <FlexCol style={{ flexGrow: 1, position: "relative" }}>
              <FailedFilesTable
                searchText={searchText}
                failedFiles={pipelineFailedFiles}
                showHistoryFn={setShowHistory}
                selection={selection}
                setSelection={setSelection}
                disabledIds={reprocessingErrorIds}
              />
              {Boolean(pipelineFailuresError) && (
                <div
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    backdropFilter: "blur(2px)",
                  }}
                >
                  <span title={pipelineFailuresError || ""}>
                    <IconError />
                  </span>
                </div>
              )}
            </FlexCol>
          </FlexCol>
        }
        secondContent={
          <FlexCol fullWidth fullHeight paddingLevel={1}>
            <Title style={{ paddingTop: 8 }}>
              File Reprocessing Queue ({reprocessing.files.length})
            </Title>
            <FlexRow fullWidth vAlign="center" paddingLevel={1} itemSpacing={2}>
              <SoftTitle>{reprocessing.active ? "Polling for changes" : "Not polling"}</SoftTitle>
              {reprocessing.active && <CircularProgress size={24} variant="indeterminate" />}
              <IconButton
                title="Start polling the file failures for changes. This only has an effect if there are still items being reprocessed."
                size="small"
                disabled={reprocessing.active}
                onClick={() => dispatch(checkReprocessing(true))}
              >
                <IconPlayArrow />
              </IconButton>
              <IconButton
                title="Stop polling the file failures for changes. This does not change what the file server is currently doing, just stops checking"
                size="small"
                disabled={!reprocessing.active || reprocessing.stop}
                onClick={() => dispatch(actionStopMonitoring())}
              >
                <IconStop />
              </IconButton>
              <IconButton
                title="Clears all items that the file server is not surrently reprocessing. This only affects the list below."
                size="small"
                disabled={!(reprocessingErrorIds.length < reprocessing.files.length)}
                onClick={() => dispatch(actionClearDone())}
              >
                <IconDeleteSweep />
              </IconButton>
            </FlexRow>
            <FlexCol fullWidth style={{ flexGrow: 1 }}>
              {reprocessing.files.length > 0 && (
                <WindowedTable
                  rowIdKey="id"
                  rows={reprocessing.files}
                  columns={[
                    {
                      width: 200,
                      dataKey: "fileName",
                      label: "File name",
                      renderFn: (fileName, row) => (
                        <Tooltip title={fileName}>
                          <span
                            style={{ width: "100%" }}
                            onClick={() => setShowHistory(row.history)}
                          >
                            {fileName}
                          </span>
                        </Tooltip>
                      ),
                    },
                    {
                      width: 90,
                      dataKey: "status",
                      label: "Status",
                      renderFn: status => (
                        <div
                          style={{
                            padding: 2,
                            borderRadius: 3,
                            backgroundColor: REPROCESS_STATUS_COLOR[status],
                          }}
                        >
                          {status}
                        </div>
                      ),
                    },
                  ]}
                />
              )}
            </FlexCol>
          </FlexCol>
        }
      />
      <DialogWrapper
        title="File failure history"
        open={showHistory.length > 0}
        cancelAction={() => setShowHistory([])}
        maxWidth="xl"
      >
        <FlexCol style={{ minHeight: 500 }}>
          <FailedFilesTable failedFiles={showHistory} />
        </FlexCol>
      </DialogWrapper>
    </FlexCol>
  );
}
