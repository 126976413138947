import { useState } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  SkipNext as IconSkipNext,
  NavigateNext as IconNavigateNext,
  NavigateBefore as IconNavigateBefore,
  SkipPrevious as IconSkipPrevious,
  ZoomIn as IconZoomIn,
  ZoomOut as IconZoomOut,
} from "@material-ui/icons";

import {
  DetectionRow,
  DetectionDataStatus,
  DetectionHistogramState,
  DetectionAbacusState,
} from "../../redux/detections/detection-types";
import FlexCol from "../common/FlexCol";
import FlexRow from "../common/FlexRow";
import ResizableSplitPanel from "../common/ResizableSplitPanel";
import { DetectionOverview } from "./DetectionOverview";
import DetectionTable from "./DetectionTable";
import DetectionAbacus from "./DetectionAbacus";
import {
  ZoomOverride,
  AbacusGroupMode,
  SelectionInterval,
  TimeRangeTs,
  DevicesInScope,
} from "./detection-types";
import { AbacusPlot } from "../../helpers/glossary";
import { SpinnerInnovasea } from "../../fathom-brella";

type Mode = "table" | "abacus";

/** having this defined within the function prevented the brush interval from working properly */
const VEGA_ACTIONS = {
  export: { png: true, svg: false },
  compiled: false,
  editor: false,
  source: false,
};

const useStyles = makeStyles(theme => ({
  main: {
    backgroundColor: "white",
  },
  visArea: {
    padding: theme.spacing(1),
    flexGrow: 1,
    overflow: "hidden",
    position: "relative",
  },
  toolBarGroup: {
    flexWrap: "wrap",
  },
  toolBarCaption: {
    paddingRight: theme.spacing(1),
    whiteSpace: "nowrap",
  },
}));

export function NavDivider() {
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        borderRight: "1px solid lightgray",
        marginLeft: "16px",
        marginRight: "16px",
      }}
    ></div>
  );
}

export function DetectionVisualizer({
  detectionState,
  detectionRows,
  detectionCount,
  downloadDetections,
  displayMode = "abacus",
  setDisplayMode,
  histogram,
  abacus,
  abacusEnabled,
  tableLimitReached,
  overviewBinSeconds,
  filterInterval,
  dataInterval,
  selectionInterval,
  setSelectionInterval,
  devicesInScope,
  abacusGroupBy,
  setAbacusGroupBy,
}: {
  detectionState: DetectionDataStatus;
  detectionRows: DetectionRow[];
  detectionCount?: number;
  downloadDetections: () => void;
  displayMode: Mode;
  setDisplayMode: (mode: Mode) => void;
  histogram: DetectionHistogramState;
  abacus: DetectionAbacusState;
  abacusEnabled: boolean;
  tableLimitReached: boolean;
  overviewBinSeconds: number;
  filterInterval: TimeRangeTs;
  dataInterval: TimeRangeTs;
  selectionInterval: SelectionInterval;
  devicesInScope: DevicesInScope;
  setSelectionInterval: (interval: SelectionInterval) => void;
  abacusGroupBy: AbacusGroupMode;
  setAbacusGroupBy: (groupBy: AbacusGroupMode) => void;
}) {
  const classes = useStyles();
  const [zoomOverride, setZoomOverride] = useState(null as ZoomOverride);
  const [searchText, setSearchText] = useState<string>("");

  displayMode = abacusEnabled ? displayMode : "table";

  const histogramReady = histogram.status === "COMPLETE";
  const histogramSelection =
    histogram.status === "COMPLETE" &&
    !(
      !selectionInterval ||
      selectionInterval.length === 0 ||
      selectionInterval[0] == selectionInterval[1]
    );

  const selectionExceedsTimeframe = filterInterval.max <= (selectionInterval?.[1] || 0);
  const selectionPreceedsTimeframe = filterInterval.min >= (selectionInterval?.[0] || 0);
  const selectionAtBeginning = filterInterval.min === (selectionInterval?.[0] || 0);
  const selectionAtMax =
    selectionInterval?.length &&
      selectionInterval[1] - selectionInterval[0] >= filterInterval.max - filterInterval.min
      ? true
      : false;

  const needSelectionMsg =
    "Please make a selection (click and drag) on the overview plot below first";

  return (
    <FlexCol fullWidth fullHeight className={classes.main}>
      <ResizableSplitPanel
        direction="vertical"
        firstInit={150}
        firstMin={150}
        firstContent={
          <FlexCol className={classes.visArea} style={{ paddingBottom: 0 }}>
            <FlexRow style={{ paddingBottom: 8 }}>
              {abacusEnabled && (
                <>
                  <FlexRow vAlign="center" className={classes.toolBarGroup}>
                    <Typography variant="caption" className={classes.toolBarCaption}>
                      Visualize by:
                    </Typography>
                    <RadioGroup
                      row
                      value={displayMode}
                      onChange={e => setDisplayMode(e.target.value as Mode)}
                    >
                      <FormControlLabel
                        value="abacus"
                        control={<Radio size="small" />}
                        label={AbacusPlot.title}
                      />
                      <FormControlLabel
                        value="table"
                        control={<Radio size="small" />}
                        label="Table"
                      />
                    </RadioGroup>
                  </FlexRow>
                  <NavDivider />
                </>
              )}
              <FlexRow vAlign="center" className={classes.toolBarGroup}>
                <Typography variant="caption" className={classes.toolBarCaption}>
                  Selected time frame:
                </Typography>
                <FlexRow
                  itemSpacing={1}
                  paddingLevel={0.5}
                  vAlign="center"
                  title={
                    histogramReady
                      ? "You can select an area of the overview histogram (click and drag) to specify a time frame for the visualization below it"
                      : "Please wait for data to load"
                  }
                >
                  <Button
                    variant="outlined"
                    size="small"
                    style={{ textTransform: "none" }}
                    onClick={() => setZoomOverride("in")}
                    disabled={!histogramReady}
                  >
                    <IconZoomIn />
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    style={{ textTransform: "none" }}
                    onClick={() => setZoomOverride("out")}
                    disabled={!histogramSelection || selectionAtMax}
                  >
                    <IconZoomOut />
                  </Button>
                  <span
                    title={`${!histogramSelection
                      ? needSelectionMsg
                      : selectionAtBeginning
                        ? "Already at the beginning"
                        : "Move the selection window to the beginning of the data"
                      }`}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      style={{ textTransform: "none" }}
                      onClick={() => setZoomOverride("beginning")}
                      disabled={!histogramSelection || selectionAtBeginning}
                    >
                      <IconSkipPrevious />
                    </Button>
                  </span>
                  <span
                    title={`${!histogramSelection
                      ? needSelectionMsg
                      : selectionPreceedsTimeframe
                        ? "Cannot go previous since the selection extends before the beginning of the data"
                        : "Move the selection window to the previous adjacent time frame"
                      }`}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      style={{ textTransform: "none" }}
                      onClick={() => setZoomOverride("prev")}
                      disabled={!histogramSelection || selectionPreceedsTimeframe}
                    >
                      <IconNavigateBefore />
                    </Button>
                  </span>
                  <span
                    title={`${!histogramSelection
                      ? needSelectionMsg
                      : selectionExceedsTimeframe
                        ? "Cannot go next since the selection extends past the end of the time frame"
                        : "Move the selection window to the next adjacent time frame"
                      }`}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      style={{ textTransform: "none" }}
                      onClick={() => setZoomOverride("next")}
                      disabled={!histogramSelection || selectionExceedsTimeframe}
                    >
                      <IconNavigateNext />
                    </Button>
                  </span>
                  <span
                    title={`${!histogramSelection
                      ? needSelectionMsg
                      : selectionExceedsTimeframe
                        ? "Already at the end"
                        : "Move the selection window to the end of the time frame"
                      }`}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      style={{ textTransform: "none" }}
                      onClick={() => setZoomOverride("end")}
                      disabled={!histogramSelection || selectionExceedsTimeframe}
                    >
                      <IconSkipNext />
                    </Button>
                  </span>
                  <span
                    title={`${!histogramSelection ? needSelectionMsg : "Clear the selection window"}
                    `}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      style={{ textTransform: "none" }}
                      onClick={() => setZoomOverride("clear")}
                      disabled={!histogramSelection}
                    >
                      Clear
                    </Button>
                  </span>
                </FlexRow>
              </FlexRow>
            </FlexRow>
            {histogram.status === "UNLOADED" ? (
              <div></div>
            ) : ["INITIAL_LOADING"].includes(histogram.status) ? (
              <FlexCol fullWidth fullHeight vAlign="center" hAlign="center">
                <CircularProgress />
              </FlexCol>
            ) : ["LOADING", "COMPLETE"].includes(histogram.status) ? (
              <DetectionOverview
                loading={histogram.status === "LOADING"}
                countPerInterval={histogram.countPerInterval}
                onSelectInterval={setSelectionInterval}
                actions={VEGA_ACTIONS}
                zoomOverride={zoomOverride}
                resetZoomOverride={() => setZoomOverride(null)}
                setZoomOverrideManual={() => setZoomOverride("manual")}
                binSeconds={overviewBinSeconds}
                filterInterval={filterInterval}
                dataInterval={dataInterval}
              />
            ) : (
              <div>An unexpected error occured</div>
            )}
          </FlexCol>
        }
        secondContent={
          <FlexCol className={classes.visArea} style={{ paddingTop: 0 }}>
            {displayMode === "table" && (
              <DetectionTable
                tableLimitReached={tableLimitReached}
                detectionState={detectionState}
                detectionRows={detectionRows}
                searchText={searchText}
                setSearchText={setSearchText}
                selectionInterval={selectionInterval}
                detectionCount={detectionCount}
                downloadDetections={downloadDetections}
              />
            )}
            {displayMode === "abacus" &&
              (abacus.status === "UNLOADED" ? (
                <div></div>
              ) : ["INITIAL_LOADING"].includes(abacus.status) ? (
                <SpinnerInnovasea />
              ) : ["LOADING", "COMPLETE"].includes(abacus.status) ? (
                <DetectionAbacus
                  loading={abacus.status === "LOADING"}
                  selectionInterval={selectionInterval}
                  detectionCounts={abacus.countPerGroup}
                  actions={VEGA_ACTIONS}
                  setZoomWindow={setZoomOverride}
                  filterInterval={filterInterval}
                  dataInterval={dataInterval}
                  devicesInScope={devicesInScope}
                  abacusGroupBy={abacusGroupBy}
                  setAbacusGroupBy={setAbacusGroupBy}
                />
              ) : (
                <div>An unexpected error occured</div>
              ))}
          </FlexCol>
        }
      />
    </FlexCol>
  );
}
