import { useEffect, useState } from "react";
import * as d3 from "d3";
import {} from "../redux/common";
import { makeStyles } from "@material-ui/core/styles";

import LandingPage from "../components/rx-diag/LandingPage";
import CheckboxGroup from "../components/common/CheckboxGroup";
import ChartList, { ChartListSeries } from "../components/rx-diag/ChartList";
import LogFileSelector from "../components/rx-diag/LogFileSelector";
import ColorSample from "../components/common/ColorSample";
import DeploymentMap from "../components/deployments/DeploymentMap";
import { deploymentOverlapsTimeBounds } from "../components/deployments/utils";
import ResizableSplitPanel from "../components/common/ResizableSplitPanel";

import { useSelectorTyped as useSelector, useThunkDispatch } from "../redux/common";

import { listFilesApi } from "../redux/files/files-actions";
import { readDeploymentList } from "../redux/deployments/deployments-actions";
import { selectFiles, selectSeriesTypes, selectEventTypes } from "../redux/rxdiag/rxdiag-actions";
import { snackbarError } from "../redux/snackbar/snackbar-actions";
import { getOffsetInMinutes, HOUR_IN_MS } from "../helpers/time";
import { usePrevious } from "../helpers/react";
import {
  RxSeries,
  RxSeriesCategory,
  RxSeriesType,
  RxEvent,
  RxEventType,
  RX_EVENT_TYPES,
} from "../redux/rxdiag/rx-diag-types";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import FlexCol from "../components/common/FlexCol";
import useFileProcessingWarning from "../components/hooks/useFileProcessingWarning";

//#region TYPES

type SeriesSpec = { label: string; category: RxSeriesCategory };
export type RxLog = {
  rxName: string;
  fileName: string;
  /** serial is used for matching deployments */
  serial: string;
  error: boolean;
  processing: boolean;
};

//#endregion TYPES

//#region CONSTANTS
const SERIES_SPECS: Record<RxSeriesType, SeriesSpec> = {
  tilt: { label: "Tilt", category: "sensor" },
  depth: { label: "Depth", category: "sensor" },
  noise: { label: "Noise", category: "sensor" },
  temp: { label: "Temperature", category: "sensor" },
  detects: { label: "Detections", category: "count" },
  pings: { label: "Pings", category: "count" },
};

const SENSOR_TYPE_OPTIONS: { value: RxSeriesType; label: string }[] = [];
const COUNT_TYPE_OPTIONS: { value: RxSeriesType; label: string }[] = [];
for (const [type, { label, category }] of Object.entries(SERIES_SPECS)) {
  (category === "sensor" ? SENSOR_TYPE_OPTIONS : COUNT_TYPE_OPTIONS).push({
    value: type as RxSeriesType,
    label,
  });
}

export const COUNT_PERIOD_LOOKUP: { [yHeader: string]: number | undefined } = {
  Dets_1H: HOUR_IN_MS,
  Pings_1H: HOUR_IN_MS,
  Pings_24H: HOUR_IN_MS * 24,
};

export const eventColorScale = d3
  .scaleOrdinal<string>()
  .domain(["INIT", "OFFLOAD"])
  .range(["green", "red"]);

const EVENT_TYPE_OPTIONS: { value: RxEventType; label: string; color: string }[] = [
  { value: "INIT", label: "Initializations", color: eventColorScale("INIT") },
  { value: "OFFLOAD", label: "Offloads", color: eventColorScale("OFFLOAD") },
];

const TIME_PADDING = HOUR_IN_MS * 8;

//#endregion CONSTANTS

//#region FUNCTIONS

function groupSeriesByYVar(displayedSeries: RxSeries[]): ChartListSeries[] {
  const seriesByYVar: ChartListSeries[] = [];

  for (const { filename, type, yVariable, data, serial } of displayedSeries) {
    const existing = seriesByYVar.find(r => r.yVariable === yVariable);
    const fileData = { filename, data, serial };

    if (existing) {
      existing.dataByFile.push(fileData);
    } else {
      seriesByYVar.push({
        yVariable,
        type,
        dataByFile: [fileData],
        countPeriod: COUNT_PERIOD_LOOKUP[yVariable] || null,
        ...SERIES_SPECS[type],
      });
    }
  }

  return seriesByYVar;
}

function getFullTimeExtent(
  seriesList: RxSeries[],
  events: RxEvent[],
  buffer: number
): Date[] | null {
  const haveSeries = seriesList.length > 0;
  const haveEvents = events.length > 0;

  if (!haveSeries && !haveEvents) {
    return null;
  }

  let start: Date | null = null;
  let end: Date | null = null;

  if (haveSeries) {
    for (const series of seriesList) {
      if (series.data.length > 0) {
        const seriesStart = series.data[0].x;
        if (!start || seriesStart < start) {
          start = seriesStart;
        }

        const seriesEnd = series.data[series.data.length - 1].x;
        if (!end || seriesEnd > end) {
          end = seriesEnd;
        }
      }
    }
  }

  if (haveEvents) {
    for (const event of events) {
      if (!start || event.time < start) {
        start = event.time;
      }
      if (!end || event.time > end) {
        end = event.time;
      }
    }
  }

  if (!start || !end) {
    return null;
  }

  return [new Date(start.getTime() - buffer), new Date(end.getTime() + buffer)];
}

function getUniqueTypes<T, R extends { type: T }>(arr: R[]): R["type"][] {
  return arr.reduce<T[]>((uniqueTypes, { type }) => {
    return uniqueTypes.includes(type) ? uniqueTypes : [...uniqueTypes, type];
  }, []);
}

// Apply a given time offset to a Date object
function applyOffset(t: Date, offsetMs: number): Date {
  return new Date(t.getTime() + offsetMs);
}

// apply a given time offset to an RxEvent
function adjustEventTime(event: RxEvent, offsetMs: number): RxEvent {
  const time = applyOffset(event.time, offsetMs);
  return { ...event, time };
}

/* Apply a given time offset to an RxSeries
 *
 * This function is memoized on the series for performance reasons. Notes on cache:
 *   - cache is keyed by the RxSeries object by shallow equality
 *   - cache is completely cleared when the time offset changes. This keeps implementation simple. */
const adjustSeriesTimes: (series: RxSeries, timeOffset: number) => RxSeries = (() => {
  let cache = new WeakMap<RxSeries, RxSeries>();
  let cachedTimeOffset = 0;

  // internal non-memoized function that does the actual computation
  function _adjustSeriesTimes(series: RxSeries, timeOffsetMs: number): RxSeries {
    if (timeOffsetMs === 0) return series;
    const data = series.data.map(d => ({ x: applyOffset(d.x, timeOffsetMs), y: d.y }));
    return { ...series, data };
  }

  return function (series: RxSeries, timeOffset: number) {
    if (timeOffset !== cachedTimeOffset) {
      // time offset has changed -- invalidate entire cache
      cache = new WeakMap();
      cachedTimeOffset = timeOffset;
    }

    const cachedResult = cache.get(series);

    if (cachedResult) {
      return cachedResult;
    } else {
      const result = _adjustSeriesTimes(series, timeOffset);
      cache.set(series, result);
      return result;
    }
  };
})();

//#endregion FUNCTIONS

//#region COMPONENTS
function NoiseGuideControl({ checked, onChange, seriesTypeSelection }) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          size="small"
          checked={checked}
          onChange={e => onChange(e.target.checked)}
          disabled={!seriesTypeSelection.includes("noise")}
        />
      }
      label={<Typography variant="body2">Show guide</Typography>}
    />
  );
}
//#endregion COMPONENTS

//#region REACT COMPONENT

const useStyles = makeStyles(
  theme =>
    ({
      chartListWrapper: {
        position: "absolute",
        top: 20,
        bottom: 20,
        left: 15,
        right: 10,
      },
      main: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
      },
      helperTextContainer: {
        position: "absolute",
        left: theme.spacing(2),
        bottom: theme.spacing(0.5),
        width: 150,
        opacity: 0.6,
        transitionDuration: theme.transitions.duration.shorter,
        "&:hover": {
          opacity: 1,
          width: "unset",
          backgroundColor: "white",
          boxShadow: "0px 0px 10px 2px white",
        },
      },
      metricSelections: {
        flexGrow: 1,
      },
    } as any)
);

function RxDiag() {
  const dispatch = useThunkDispatch();
  const classes: any = useStyles();

  useEffect(() => {
    dispatch(listFilesApi());
  }, [dispatch]);

  useEffect(() => {
    dispatch(readDeploymentList());
  }, [dispatch]);

  // Check for workspace change to re-fire data fetch:
  const workspaceId = useSelector(({ workspaces }: any) => workspaces.selectedWorkspace?.id);
  const prevWorkspaceId = usePrevious(workspaceId);
  if (workspaceId !== prevWorkspaceId) {
    dispatch(listFilesApi());
    dispatch(readDeploymentList());
  }

  const [showNoiseGuide, setShowNoiseGuide] = useState(true);
  const [selectedDeploymentIds, setSelectedDeploymentIds] = useState([] as string[]);
  const [highlightedFileNames, setHighlightedFileNames] = useState([] as string[]);
  const [zoomSelectionUtc, setZoomSelection] = useState<Date[] | null>(null);

  //#region REDUX
  const fileListReady = useSelector(state => state.files.isLoaded && state.study.isLoaded);
  const selectedStudyId = useSelector(state => state.study.selectedId);
  /** List of receiver log files organized by "receiver name" (i.e. "model-serial") */
  const [rxLogOptions, hrFilesPresent] = useSelector(state => {
    if (!fileListReady || !state.files.fileList) return [[], false, false];

    const studies = state.study.studies as { id: string }[];
    const selectedStudy = selectedStudyId && studies.find(s => s.id === selectedStudyId);
    const studyFileNames = (selectedStudy?.files && selectedStudy.files.map(f => f.name)) || [];
    const filesAllTypes = selectedStudy
      ? studyFileNames.map(name => state.files.fileList.find(f => f.name == name))
      : state.files.fileList;

    const list: RxLog[] = [];
    let hrFilesPresent = false;
    for (const f of filesAllTypes) {
      if (!f.rxLogProperties) continue;

      if (f.rxLogStatus.parquet === "unsupported") {
        if (f.rxLogProperties.model.includes("HR")) {
          hrFilesPresent = true;
        }
        continue;
      }

      if (f.rxLogProperties !== null) {
        const rxName = `${f.rxLogProperties.model.split("-")[0]}-${f.rxLogProperties.serial}`;
        list.push({
          rxName,
          fileName: f.name,
          serial: f.rxLogProperties.serial,
          processing: f.procesing,
          error: f.error,
        });
      }
    }

    list
      .sort((l1, l2) => l1.rxName.localeCompare(l2.rxName))
      .sort((l1, l2) => l1.fileName.localeCompare(l2.fileName));

    return [list, hrFilesPresent];
  });

  const logFileSelectorHelperText = getUnsupportedFileHelperText(hrFilesPresent);

  useFileProcessingWarning();

  const seriesTypeSelection = useSelector(state => state.rxdiag.selectedSeriesTypes);
  const eventTypeSelection = useSelector(state => state.rxdiag.selectedEventTypes);
  /** List of file names selected in redux state */
  const rxLogFiles = useSelector(state => state.rxdiag.selectedFiles);
  const availableSeriesTypes = useSelector(state => getUniqueTypes(state.rxdiag.seriesStatuses));
  const availableEventTypes = useSelector(state => {
    return state.rxdiag.eventsStatuses.length > 0 ? [...RX_EVENT_TYPES] : [];
  });
  const selectedOffset = useSelector(state => state.user.selectedOffset || "UTC") as string;
  const displayedSeriesUtc = useSelector(state => {
    return state.rxdiag.loadedSeries.filter(
      series => seriesTypeSelection.includes(series.type) && rxLogFiles.includes(series.filename)
    );
  });
  const displayedEventsUtc = useSelector(state => {
    return state.rxdiag.loadedEvents.filter(
      event => eventTypeSelection.includes(event.type) && rxLogFiles.includes(event.filename)
    );
  });

  /** The log file options that are selected */
  const rxLogOptionsSelected: RxLog[] = [];
  rxLogFiles.forEach(fileName => {
    const opt = rxLogOptions.find(opt => opt.fileName == fileName);
    opt && rxLogOptionsSelected.push(opt);
  });

  /** colors per serial */
  const serialColorScale = d3
    .scaleOrdinal(d3.schemeTableau10)
    .domain(rxLogOptionsSelected.map(opt => opt.serial));

  /** Deduped list of serial numbers in selected log files */
  const serialsSelected = {};
  rxLogOptionsSelected.forEach(s => {
    if (s?.serial) {
      serialsSelected[s.serial] = 1;
    }
  });

  /** Deployments of devices matched by the selected log files' serial */
  const deploymentsOfSerials = useSelector(({ deployments, files }) => {
    const deploymentsSelected: any[] = [];

    if (rxLogOptionsSelected.length) {
      // need the files to check time frame of deployment:
      const filesSelected = files.fileList.filter(f => rxLogFiles.includes(f.name));
      const fileTimesBySerial: { [serial: string]: { start: string; end: string }[] } = {};
      filesSelected.forEach(file => {
        const serial = file.rxLogProperties?.serial;
        if (serial) {
          if (!fileTimesBySerial[serial]) {
            fileTimesBySerial[serial] = [];
          }
          fileTimesBySerial[serial].push({
            start: file.rxLogProperties?.initializationTime || "",
            end: file.rxLogProperties?.offloadTime || "",
          });
        }
      });
      deployments.deployments?.forEach(deployment => {
        deployment?.deviceAttachments?.forEach(da => {
          const serial = da?.device?.serial;
          if (serial && Object.keys(serialsSelected).includes(serial)) {
            // deployment is for serial, now check if deployment / position time is within log file window:
            if (deploymentOverlapsTimeBounds(deployment, fileTimesBySerial[serial])) {
              deployment.pinColor = serialColorScale(serial);
              deploymentsSelected.push(deployment);
            }
          }
        });
      });
    }
    return deploymentsSelected;
  });
  //#endregion REDUX

  // Takes a deployment id and returns an array of deployment ids with the same serial
  function handleSelectDeploymentIds(deploymentId: string) {
    // deselect all if it is one that is currently selected:
    if (selectedDeploymentIds.includes(deploymentId)) {
      setSelectedDeploymentIds([]);
      setHighlightedFileNames([]);
      return;
    }
    const serials: string[] = [];
    const deploymentIds: string[] = [];
    const logFileNames: string[] = [];

    // get serial(s) of given deployments' device attachments
    deploymentsOfSerials
      .find(d => d.id === deploymentId)
      .deviceAttachments.forEach(da => {
        if (Object.keys(serialsSelected).includes(da.device.serial)) {
          serials.push(da.device.serial);
        }
      });

    // Find other deployments with the given serial(s):
    deploymentsOfSerials.forEach(d => {
      d.deviceAttachments.forEach(da => {
        if (serials.includes(da.device.serial)) {
          deploymentIds.push(d.id);
        }
      });
    });

    // list of log file names that represent this serial:
    rxLogOptionsSelected.forEach(l => {
      if (serials.includes(l.serial)) {
        logFileNames.push(l.fileName);
      }
    });

    setHighlightedFileNames(logFileNames);
    setSelectedDeploymentIds(deploymentIds);
  }

  //#region VARIABLES
  const timeOffsetMs = (getOffsetInMinutes(selectedOffset) || 0) * 60000;

  const displayedSeries = displayedSeriesUtc.map(series => adjustSeriesTimes(series, timeOffsetMs));
  const displayedEvents = displayedEventsUtc.map(event => adjustEventTime(event, timeOffsetMs));

  const seriesByYVar = groupSeriesByYVar(displayedSeries);
  const fullTimeExtent = getFullTimeExtent(displayedSeries, displayedEvents, TIME_PADDING);
  const anythingSelected = displayedSeries.length > 0 || displayedEvents.length > 0;

  const eventTypeOptions = EVENT_TYPE_OPTIONS.map(option => {
    return { ...option, extra: <ColorSample size={24} color={option.color} /> };
  });

  const sensorOptions = SENSOR_TYPE_OPTIONS.map(option => {
    return {
      ...option,
      extra:
        option.value === "noise" ? (
          <NoiseGuideControl
            checked={showNoiseGuide}
            onChange={setShowNoiseGuide}
            seriesTypeSelection={seriesTypeSelection}
          />
        ) : null,
    };
  });
  //#endregion VARIABLES

  return (
    <ResizableSplitPanel
      direction="horizontal"
      firstInit="30%"
      firstMin={300}
      firstContent={
        <ResizableSplitPanel
          direction="vertical"
          firstInit="70%"
          firstMin={300}
          firstContent={
            <FlexCol fullHeight style={{ overflowY: "auto" }}>
              <LogFileSelector
                helperText={logFileSelectorHelperText}
                options={rxLogOptions}
                selection={rxLogOptionsSelected}
                selectionHandler={optionsSelected => {
                  if (optionsSelected.length <= 10) {
                    // Limit until data server / gql can handle more requests at once
                    dispatch(selectFiles(optionsSelected.map(opt => opt.fileName)));
                  } else {
                    dispatch(snackbarError("You can only select up to 10 logs at once"));
                  }
                }}
                serialColorScale={serialColorScale}
              />
              <div className={classes.metricSelections}>
                <CheckboxGroup
                  label="Sensor readings"
                  options={sensorOptions}
                  selection={seriesTypeSelection}
                  selectionHandler={seriesTypes => dispatch(selectSeriesTypes(seriesTypes))}
                  enabledValues={availableSeriesTypes}
                />
                <CheckboxGroup
                  label="Counts"
                  options={COUNT_TYPE_OPTIONS}
                  selection={seriesTypeSelection}
                  selectionHandler={seriesTypes => dispatch(selectSeriesTypes(seriesTypes))}
                  enabledValues={availableSeriesTypes}
                />
                <CheckboxGroup
                  label="Events"
                  options={eventTypeOptions}
                  selection={eventTypeSelection}
                  selectionHandler={eventTypes => dispatch(selectEventTypes(eventTypes))}
                  enabledValues={availableEventTypes}
                />
              </div>
            </FlexCol>
          }
          secondContent={
            <DeploymentMap
              deployments={deploymentsOfSerials}
              selectedDeploymentIds={selectedDeploymentIds}
              handleSelectDeployment={handleSelectDeploymentIds}
              fitBoundsOnChange
              showLegend={false}
              useDeploymentColors={true}
              timeBound={zoomSelectionUtc}
            />
          }
        />
      }
      secondContent={
        <div className={classes.main}>
          {anythingSelected ? (
            <>
              <div className={classes.chartListWrapper}>
                <ChartList
                  seriesByYVar={seriesByYVar}
                  events={displayedEvents}
                  fullExtent={fullTimeExtent}
                  serialColorScale={serialColorScale}
                  selectedOffset={selectedOffset}
                  timeOffsetMs={timeOffsetMs}
                  showNoiseGuide={showNoiseGuide}
                  highlightedFileNames={highlightedFileNames}
                  zoomSelectionUtc={zoomSelectionUtc}
                  setZoomSelection={setZoomSelection}
                />
              </div>
              <div className={classes.helperTextContainer}>
                <Typography variant="body2" noWrap>
                  Tip: You can <strong>drag on the chart to zoom in</strong> on a certain time
                  period. <strong>Double click to zoom out</strong>.
                </Typography>
              </div>
            </>
          ) : (
            <LandingPage
              rxLogsReady={fileListReady}
              rxLogOptions={rxLogOptions}
              rxLogOptionsSelected={rxLogOptionsSelected}
              dataListLoaded={true}
              selectedStudyId={selectedStudyId}
            />
          )}
        </div>
      }
    />
  );
}

function getUnsupportedFileHelperText(hrFilesPresent: boolean): string | undefined {
  if (!hrFilesPresent) return undefined;

  return `Files offloaded from HR receivers cannot be selected. Support is under development.`;
}

export default RxDiag;

//#endregion REACT COMPONENT
