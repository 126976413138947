import moment from "moment";

export const sensorUnits = {
  TEMPERATURE: "°C",
  DEPTH: "m",
  ACCELERATION: "m/s²",
};

export type DetectionDataStatus =
  | "UNLOADED"
  | "INITIAL_LOADING"
  | "LOADING"
  | "COMPLETE"
  | "CANCELED"
  | "ERROR";

export type DetectionRow = {
  time: string;
  full_id: string;
  serial: string;
  files: string;
  fileNames: string;
  sensor_value: number | null;
  sensor_type: string;
};

export type DetectionDataSet = {
  /** Having the filter definition here allows us to know to clear the data when a new set of
   * filters is requested */
  filters?: DetectionFilterInput | null; //@TODO: this probably can be removed now that filters are explicitly invoked by the user
  status: DetectionDataStatus;
  error?: string | null;
};

export type DetectionTableState = DetectionDataSet & {
  rows: DetectionRow[];
  // queryId?: string | null;
  // cancelledQueryIds: string[];
};

/* HISTOGRAM */

export type DetectionCountHistogram = {
  dt: number;
  count: number;
};

export type DetectionHistogramState = DetectionDataSet & {
  countPerInterval: DetectionCountHistogram[];
};

/* ABACUS */
/** Shape of the Abacus / HeatMap data */
export type DetectionCountAbacus = {
  dt: number;
  count: number;
  /** receiver serial */
  rx?: string;
  /** transmitter full id */
  tx?: string;
};

export type DetectionAbacusState = DetectionDataSet & {
  countPerGroup: DetectionCountAbacus[];
};

export type DetectionFilterInput = {
  includeTransmitterIDs?: string[];
  includeReceiverSerials?: string[];
  includeStartTime?: Date | moment.Moment;
  includeEndTime?: Date | moment.Moment;
};

export type DetectionCountOptions = {
  binIntervalSeconds: number;
  byReceiverSerial: boolean;
  byTransmitterIDs: boolean;
};

export type DetectionCountAPIResAbacus = {
  periodMs: number[];
  count: number[];
  serial: string[];
  fullId: string[];
};

export type DetectionDownloadJob = {
  id: number;
  apiPath: string;
  percentComplete: number;
  complete: boolean;
  error?: boolean | undefined;
  errorMsg?: string | null;
  creationTime?: string;
};

export type DetectionDownloadStage = "PREPARE" | "DOWNLOAD" | "COMPLETE" | "ERROR";

export type DetectionDownloadState = {
  jobId: string;
  fileName?: string;
  detCount?: number;
  stage: DetectionDownloadStage;
  percentComplete?: number;
  /** truthy indicates error */
  errorMsg?: string | null;
};

export type DetectionAction =
  | {
      type: "DETECTION_SUMMARY_START_LOADING_DATA";
    }
  | {
      type: "DETECTION_SUMMARY_SET";
      payload: any;
    }
  | {
      type: "DETECTION_SUMMARY_ERROR";
      payload?: { message: string };
    }
  | {
      type: "DETECTION_SET_TABLE";
      payload: { rows: DetectionRow[]; state: DetectionDataStatus };
    }
  | {
      type: "DETECTION_RESET_TABLE";
    }
  | {
      type: "DETECTION_TABLE_START_LOADING";
    }
  | {
      type: "DETECTION_TABLE_COMPLETE";
    }
  | {
      type: "DETECTION_TABLE_CANCEL";
    }
  | {
      type: "DETECTION_TABLE_ERROR";
      payload?: { message: string };
    }
  | {
      type: "DETECTION_SET_HISTOGRAM_LOADING";
      payload: { filters: DetectionFilterInput };
    }
  | {
      type: "DETECTION_SET_HISTOGRAM_INIT_LOADING";
      payload: { filters: DetectionFilterInput };
    }
  | {
      type: "DETECTION_SET_HISTOGRAM";
      payload: { filters: DetectionFilterInput; detCountsHistogram: DetectionCountHistogram[] };
    }
  | {
      type: "DETECTION_HISTOGRAM_ERROR";
      payload: { filters: DetectionFilterInput; message: string };
    }
  | {
      type: "DETECTION_SET_ABACUS_ERROR";
      payload: { filters: DetectionFilterInput };
    }
  | {
      type: "DETECTION_SET_ABACUS_DATA";
      payload: { filters: DetectionFilterInput; countPerGroup: DetectionCountAbacus[] };
    }
  | {
      type: "DETECTION_SET_ABACUS_LOADING";
      payload: { filters: DetectionFilterInput };
    }
  | {
      type: "DETECTION_SET_ABACUS_INIT_LOADING";
      payload: { filters: DetectionFilterInput };
    }
  | {
      type: "DETECTION_CLEAR_DATA";
    }
  | {
      type: "DETECTION_SET_COUNT";
      payload: {
        count: number;
      };
    }
  | {
      type: "DETECTION_COUNT_LOADING";
      payload: {
        filters: DetectionFilterInput;
      };
    }
  | {
      type: "DETECTION_DOWNLOAD_STATUS";
      payload: DetectionDownloadState;
    }
  | {
      type: "DETECTION_DOWNLOAD_CLEAR";
      payload: { jobId: string };
    };

/** Top level redux state for detections */
export type DetectionState = {
  currentFilters?: DetectionFilterInput | null;
  summary: {
    loading: boolean;
    detectionSummary?: any;
    error?: string | null;
  };
  table: DetectionTableState;
  histogram: DetectionHistogramState;
  abacus: DetectionAbacusState;
  count: {
    loading: boolean;
    count?: number;
    filters?: DetectionFilterInput;
  };
  downloads: Record<string, DetectionDownloadState>;
};
