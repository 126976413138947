import { createRef, Component } from "react";
import autoBind from "auto-bind/react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import Dropzone from "react-dropzone";
import classNames from "classnames";
import history from "../helpers/history";

// MATERIAL UI
import { withStyles } from "@material-ui/core/styles";
import { Typography, IconButton, Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import {
  CloudUpload as IconCloudUpload,
  CloudDownload as IconCloudDownload,
  Delete as IconDelete,
  Link as IconLink,
  LinkOff as IconLinkOff,
  Refresh as IconRefresh,
} from "@material-ui/icons";

import IconExport from "../components/common/IconExport";

// COMPONENTS
import FilesTable from "../components/files/FilesTable";
import FilesDetailsPanel from "../components/files/FilesDetailsPanel";
import { TableSearchBar, SpinnerInnovasea } from "../fathom-brella";
import DialogWrapper from "../components/common/DialogWrapper";
import FilesExportDialog from "../components/files/FilesExportDialog";
import FilesDuplicateDialog from "../components/files/FilesDuplicateDialog";
import IconMenuHoriz from "../components/common/IconMenuHoriz";

import MenuBar from "../components/common/MenuBar";
import StudyChooseDialog from "./StudyChooseDialog";
import StudyUnlinkDialog from "./StudyUnlinkDialog";
import StudyLinkDialog from "./StudyLinkDialog";
import UploadLinkStudyDialog from "../components/files/UploadLinkStudyDialog";
import StudyEmptyMessage from "../components/study/StudyEmptyMessage";
import { selectFiles } from "../redux/rxdiag/rxdiag-actions";
import { studyVisibleObjects } from "../helpers/study";
import DeleteDialogButtons from "../components/common/DeleteDialogButtons";
import DeleteWarning from "../components/common/DeleteWarning";
import FlexCol from "../components/common/FlexCol";

// redux
import {
  listFilesApi,
  deleteFilesApi,
  downloadFilesApi,
  exportFilesApi,
  uploadFilesApi,
} from "../redux/files/files-actions";
import { removeFromStudy } from "../redux/study/study-actions";

import { snackbarError } from "../redux/snackbar/snackbar-actions";
import ResizableSplitPanel from "../components/common/ResizableSplitPanel";

const styles = theme => ({
  dropzone: {
    height: "100%",
    width: "100%",
  },
  dropZoneIndicator: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    "&.dropzone-isActive": {
      boxShadow: "0 0 55px -10px green inset",
    },
    pointerEvents: "none",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  fileListContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    position: "relative",
    height: "100%",
  },
  iconRefresh: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1,
  },
  fileTableContainer: {
    flexGrow: 1,
    height: "100%",
  },
  loading: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
});

class Files extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      selection: [],
      open: true,
      showConfirmDeleteDialog: false,
      studyDialogMode: "closed", // link | unlink
      filesToUpload: [], // array of File objects
      dupUploadNames: [], // array of string file names
      searchTest: "",
      studyChooseOpen: false,
      studyLinkOpen: false,
      studyUnlinkOpen: false,
      exportDialogOpen: false,
      activeTab: "All",
    };
    this.dropzoneRef = createRef();
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedWorkspace !== prevProps.selectedWorkspace ||
      this.props.selectedStudy !== prevProps.selectedStudy
    ) {
      this.setState({ selection: [] });
    }
    if (this.props.selectedWorkspace !== prevProps.selectedWorkspace) {
      this.fetchData();
    }
  }

  fetchData() {
    this.props.dispatch(listFilesApi());
  }

  handleDrawerOpen() {
    this.setState({ open: true });
  }

  handleDrawerClose() {
    this.setState({ open: false });
  }

  downloadFiles() {
    if (this.state.selection.length === 1) {
      this.props.dispatch(downloadFilesApi("", this.state.selection));
    } else {
      this.props.dispatch(exportFilesApi(this.state.selection, "default"));
    }
  }

  exportFiles(fileType, exportFiles) {
    this.props.dispatch(exportFilesApi(exportFiles, fileType));
    this.setState({ exportDialogOpen: false });
  }

  confirmDelete() {
    this.setState({ showConfirmDeleteDialog: true });
  }

  actionDelete() {
    this.props.dispatch(deleteFilesApi("", this.state.selection, this.props.studies));
    this.setState({ showConfirmDeleteDialog: false });
    this.setState({ selection: [] });
  }

  actionUnlink() {
    this.props.dispatch(
      removeFromStudy(
        { studyId: this.props.selectedStudy.id, filePaths: this.state.selection },
        true
      )
    );
    this.setState({ showConfirmDeleteDialog: false });
  }

  actionCancel() {
    this.setState({ showConfirmDeleteDialog: false });
  }

  getLastFileSelected() {
    const allSelected = this.props.files.filter(file => this.state.selection.includes(file.name));
    return allSelected.length === 1 ? allSelected[0] : allSelected[allSelected.length - 1];
  }

  handleUploadSelection(acceptedFiles, rejectedFiles) {
    if (rejectedFiles.length) {
      this.props.dispatch(
        snackbarError(
          "File format not supported. Accepted file formats are .csv, .gpx, .vrl, .vdat"
        )
      );
      return;
    }

    /* file.uploadName is the name the file will have upon upload to s3.
     * It may be different than its name on disk due to deduplication */
    acceptedFiles.forEach(file => {
      file.uploadName = file.name;
    });

    const uploadedFileNames = acceptedFiles.map(file => file.name);
    const dupUploadNames = this.props.fileNamesInWorkspace.filter(file =>
      uploadedFileNames.includes(file)
    );

    this.setState({ filesToUpload: acceptedFiles, dupUploadNames });
    this.checkStartUpload(dupUploadNames, acceptedFiles); // Check if we can start the upload right now
  }

  /* The next three functions (skipFilesUpload, replaceFilesUpload, keepBothFilesUpload)
   * are used to pare down dupFileNames. */

  // Removes the filesToSkip from the equation entirely, from both filesToUpload and dupUploadNames
  skipFilesUpload(filesToSkip) {
    const dupUploadNames = filterOut(this.state.dupUploadNames, filesToSkip);
    const filesToUpload = this.state.filesToUpload.filter(file => !filesToSkip.includes(file.name));
    this.setState({
      filesToUpload,
      dupUploadNames,
    });
    this.checkStartUpload(dupUploadNames, filesToUpload); // Check if we can start the upload now
  }

  /* Simply removes the filename from dupUploadNames, which will allow it to be uploaded overtop of the
   * existing file of that name. */
  replaceFilesUpload(filesToReplace) {
    const dupUploadNames = filterOut(this.state.dupUploadNames, filesToReplace);
    this.setState({
      dupUploadNames,
    });
    this.checkStartUpload(dupUploadNames, this.state.filesToUpload); // Check if we can start the upload now
  }

  /* Removes the filename from dupUploadNames, and modifies its name in filesToUpload, so that it will
   * be uploaded along side the existing file of that name. */
  keepBothFilesUpload(filesToDuplicate) {
    const dupUploadNames = filterOut(this.state.dupUploadNames, filesToDuplicate);
    const filesToUpload = dedupUploadNames(
      this.state.filesToUpload,
      filesToDuplicate,
      this.props.fileNamesInWorkspace
    );
    this.setState({
      filesToUpload,
      dupUploadNames,
    });
    this.checkStartUpload(dupUploadNames, filesToUpload); // Check if we can start the upload now
  }

  /* Checks if a study is selected and */
  checkStartUpload(dupUploadNames, filesToUpload) {
    const { selectedStudy } = this.props;

    if (filesToUpload.length > 0 && dupUploadNames.length === 0 && selectedStudy) {
      this.startUpload(selectedStudy.id, filesToUpload);
    }
  }

  startUpload(studyIdToLink, filesToUpload = null) {
    filesToUpload = filesToUpload ? filesToUpload : this.state.filesToUpload;
    this.props.dispatch(uploadFilesApi(filesToUpload, studyIdToLink));
    this.setState({ filesToUpload: [] });
  }

  closeStudyDialog() {
    this.setState({ studyDialogMode: "closed" });
  }

  handleRemoveStudy(studyId, fileName) {
    if (studyId && fileName) {
      this.props.dispatch(removeFromStudy({ studyId, filePaths: [fileName] }, true));
    }
    return;
  }

  selectLogForRxDiagnostics(fileName) {
    this.props.dispatch(selectFiles([fileName]));
    history.push("/rx-diag");
  }

  render() {
    const { files = [], classes, studies, selectedStudy, isLoaded } = this.props;
    const {
      searchText,
      selection,
      studyChooseOpen,
      studyLinkOpen,
      studyUnlinkOpen,
      showConfirmDeleteDialog,
      dupUploadNames,
      filesToUpload,
      exportDialogOpen,
      activeTab,
    } = this.state;

    if (!isLoaded) {
      return (
        <div className={classes.loading}>
          <SpinnerInnovasea />
        </div>
      );
    }

    const multipleDisabled = selection.length <= 0;
    const thereAreFilesToUpload = filesToUpload.length > 0;
    const thereAreNoDuplicates = dupUploadNames.length === 0;
    const uploadReadyToLink = thereAreFilesToUpload && thereAreNoDuplicates && !selectedStudy;

    return (
      <>
        <ResizableSplitPanel
          direction="horizontal"
          firstMin={"60%"}
          secondMin={"25%"}
          firstInit={"70%"}
          firstContent={
            <div className={classes.main}>
              <MenuBar
                contentLeft={
                  <>
                    <IconMenuHoriz
                      items={[
                        {
                          // (only shown outside of study context) for selecting a study to link to the currently selected files
                          visible: !selectedStudy,
                          labelAbove: "Link",
                          icon: <IconLink />,
                          onClick: () => this.setState({ studyChooseOpen: true }),
                          disabled: selection.length === 0,
                          hoverText:
                            selection.length === 0
                              ? "Select files to link to a study"
                              : "Link the selected files to a study",
                        },
                        {
                          // (only shown within study context) for selecting files to link to the currently selected study
                          visible: Boolean(selectedStudy),
                          labelAbove: "Link",
                          icon: <IconLink />,
                          onClick: () => this.setState({ studyLinkOpen: true }),
                          hoverText: "Link files to this study from the workspace",
                        },
                        {
                          // (only shown within a study context) for unlinking currently selected objects from the currently selected study
                          visible: Boolean(selectedStudy),
                          labelAbove: "Unlink",
                          icon: <IconLinkOff />,
                          onClick: () => this.setState({ studyUnlinkOpen: true }),
                          disabled: selection.length === 0,
                          hoverText:
                            selection.length === 0
                              ? "Select devices to unlink from this study"
                              : "Unlink the currently selected devices from this study",
                        },
                        { divider: true },
                        {
                          labelAbove: "Upload",
                          icon: <IconCloudUpload />,
                          onClick: () => this.dropzoneRef.current.open(),
                          hoverText: "Upload files",
                        },
                        {
                          labelAbove: "Download",
                          icon: <IconCloudDownload />,
                          onClick: this.downloadFiles,
                          disabled: multipleDisabled,
                          hoverText: multipleDisabled
                            ? "Select files to download"
                            : "Download selected files",
                        },
                        {
                          labelAbove: "Delete",
                          icon: <IconDelete />,
                          onClick: this.confirmDelete,
                          disabled: multipleDisabled,
                          hoverText: multipleDisabled
                            ? "Select files to delete"
                            : "Delete selected files",
                        },
                        { divider: true },
                        {
                          labelAbove: "Export",
                          icon: <IconExport />,
                          onClick: () => this.setState({ exportDialogOpen: true }),
                          disabled: multipleDisabled,
                          hoverText: multipleDisabled ? "Select files to export" : "Export files",
                        },
                        { divider: true },
                      ]}
                    />
                    <FlexCol fullHeight>
                      <Typography variant="caption">File state</Typography>
                      <RadioGroup
                        row
                        name="FileStates"
                        value={activeTab}
                        onChange={e => this.setState({ activeTab: e.target.value })}
                      >
                        <FormControlLabel
                          value="All"
                          control={<Radio size="small" />}
                          label="All"
                        />
                        <FormControlLabel
                          value="Processing"
                          control={<Radio size="small" />}
                          label="Processing"
                        />
                        <FormControlLabel
                          value="Processed"
                          control={<Radio size="small" />}
                          label="Processed"
                        />
                      </RadioGroup>
                    </FlexCol>
                  </>
                }
                contentRight={
                  <>
                    <TableSearchBar
                      searchText={searchText}
                      handleSearch={searchText => {
                        this.setState({ searchText });
                      }}
                    />
                  </>
                }
              />

              <Dropzone onDrop={this.handleUploadSelection} ref={this.dropzoneRef}>
                {dropZoneProps => {
                  return (
                    <>
                      <div
                        className={classes.dropzone}
                        {...dropZoneProps.getRootProps({
                          onClick: evt => evt.preventDefault(),
                        })}
                      >
                        <input {...dropZoneProps.getInputProps()} />
                        {files.length === 0 ? (
                          <div style={{ padding: 20 }}>
                            {selectedStudy ? (
                              <StudyEmptyMessage
                                typeName="files"
                                onLinkClicked={() => this.setState({ studyLinkOpen: true })}
                                studyName={selectedStudy.name}
                                newVerb="Upload"
                                iconNew={<IconCloudUpload />}
                                onNewClicked={() => this.dropzoneRef.current.open()}
                              />
                            ) : (
                              <Typography>
                                You have not uploaded any files. You may upload files by dragging
                                them into this area, or clicking the "Upload" button.
                              </Typography>
                            )}
                          </div>
                        ) : (
                          <div className={classes.fileListContainer}>
                            <IconButton
                              size="small"
                              aria-label="Refresh"
                              className={classes.iconRefresh}
                              onClick={this.fetchData}
                              title="Refresh the file list"
                            >
                              <IconRefresh />
                            </IconButton>

                            <div className={classes.fileTableContainer}>
                              <FilesTable
                                files={files}
                                selection={selection}
                                setSelection={selection => this.setState({ selection })}
                                searchText={searchText}
                                showLinkedStudies={!selectedStudy}
                                removeFromStudy={this.handleRemoveStudy}
                                activeTab={activeTab}
                              />
                            </div>
                          </div>
                        )}
                        <div
                          className={classNames(
                            classes.dropZoneIndicator,
                            dropZoneProps.isDragActive && "dropzone-isActive"
                          )}
                        />
                      </div>
                    </>
                  );
                }}
              </Dropzone>
            </div>
          }
          secondContent={
            <FilesDetailsPanel
              selectLogRxDiag={this.selectLogForRxDiagnostics}
              details={this.getLastFileSelected()}
            />
          }
        />
        <DialogWrapper
          open={showConfirmDeleteDialog}
          title={`Delete ${selection.length} File${selection.length > 1 ? "s" : ""}`}
          okAction={this.actionDelete}
          cancelAction={this.actionCancel}
          buttons={({ cancelAction, okAction }) => (
            <DeleteDialogButtons
              cancelAction={cancelAction}
              okAction={okAction}
              isStudy={Boolean(selectedStudy)}
              unlinkAction={this.actionUnlink}
            />
          )}
        >
          <Typography gutterBottom>
            Are you sure you want to delete
            {selection.length > 1 ? ` ${selection.length} files` : ` file ${selection[0]}`}?
          </Typography>
          {Boolean(selectedStudy) && <DeleteWarning type="file" />}
        </DialogWrapper>

        <FilesDuplicateDialog
          open={dupUploadNames.length > 0}
          dupFileNames={dupUploadNames}
          skip={this.skipFilesUpload}
          keepBoth={this.keepBothFilesUpload}
          replace={this.replaceFilesUpload}
        />

        {exportDialogOpen && (
          <FilesExportDialog
            open={true}
            handleExport={this.exportFiles}
            close={() => this.setState({ exportDialogOpen: false })}
            selection={selection}
            files={files}
          />
        )}

        <UploadLinkStudyDialog
          open={uploadReadyToLink}
          submitAction={this.startUpload}
          filenames={filesToUpload.map(file => file.uploadName)}
          studies={studies}
          appSelectedStudyId={selectedStudy?.id}
        />

        {studyChooseOpen && (
          <StudyChooseDialog
            open={true}
            handleClose={() => this.setState({ studyChooseOpen: false })}
            objectType="filePaths"
            objectTypeLabel="File"
            objectIds={selection}
          />
        )}

        {studyLinkOpen && (
          <StudyLinkDialog
            open={true}
            handleClose={() => this.setState({ studyLinkOpen: false })}
            objectType="filePaths"
            objectTypeLabel="File"
            selectedStudyId={selectedStudy?.id}
          />
        )}

        {studyUnlinkOpen && (
          <StudyUnlinkDialog
            open={true}
            handleClose={() => this.setState({ studyUnlinkOpen: false })}
            objectType="filePaths"
            objectTypeLabel="File"
            objectIds={selection}
            selectedStudyId={selectedStudy?.id}
          />
        )}
      </>
    );
  }
}

function filterOut(a, b) {
  return a.filter(x => !b.includes(x));
}

/* Iterates through filesToUpload, and mangles any files names in
 * namesToDedep to avoid collisions with existingNames */
function dedupUploadNames(filesToUpload, namesToDedup, existingNames) {
  return filesToUpload.map(fileToUpload => {
    if (!namesToDedup.includes(fileToUpload.uploadName)) {
      return fileToUpload;
    }

    let copyNumber = 1;
    let newName = fileToUpload.uploadName;

    while (existingNames.includes(newName)) {
      newName = createFileName(fileToUpload.uploadName, copyNumber++);
    }

    fileToUpload.uploadName = newName;

    return fileToUpload;
  });
}

function createFileName(fileName, copyNumber) {
  const ext = fileName.split(".").pop();
  const name = fileName.substr(0, fileName.lastIndexOf("."));
  return name + " (" + copyNumber + ")." + ext;
}

const mapStateToProps = ({ files, study, snackbar, workspaces }) => {
  const studySelectedId = study.selectedId;
  const selectedStudy =
    studySelectedId && study.studies.find(study => study.id === studySelectedId);

  return {
    files: studyVisibleObjects({
      studies: study.studies,
      selectedStudy,
      objects: files.fileList,
      objectKey: "files",
      objectIdKey: "name",
    }),
    isLoaded: files.isLoaded,
    fileNamesInWorkspace: files.fileList.map(file => file.name),
    notifications: snackbar.notifications,
    studies: study.studies,
    selectedWorkspace: workspaces.selectedWorkspace,
    selectedStudy,
  };
};

Files.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object),
  fileNamesInWorkspace: PropTypes.arrayOf(PropTypes.string),
  completedUploads: PropTypes.array,
  studies: PropTypes.array,
  selectedWorkspace: PropTypes.object,
  selectedStudy: PropTypes.object,
  isLoaded: PropTypes.bool,
};

export default compose(connect(mapStateToProps), withStyles(styles))(Files);
