import React from "react";
import { GithubPicker } from "react-color";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconColorLens from "@material-ui/icons/ColorLens";

import Dropdown from "../../Dropdown";

function Toggle() {
  return (
    <ListItem button>
      <ListItemIcon>
        <IconColorLens />
      </ListItemIcon>
      <ListItemText>Bubble color</ListItemText>
    </ListItem>
  );
}

const Picker = React.forwardRef(({ colorHandler }, ref) => (
  <div style={{ boxSizing: "content-box" }} ref={ref}>
    <GithubPicker onChange={colorHandler} />
  </div>
));

export default Dropdown(Toggle, Picker);
