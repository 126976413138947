import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, TextField, Chip, Typography } from "@material-ui/core";
import ColorSample from "../common/ColorSample";
import FlexCol from "../common/FlexCol";
import { RxLog } from "../../containers/RxDiag";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  option: {
    padding: 0,
    transitionDuration: "50",
    backgroundColor: "unset",
    "&[aria-selected=true]": {
      backgroundColor: "unset",
    },
  },
  paper: {
    padding: 0,
    marginTop: -3,
    marginLeft: 2,
    boxShadow: "0px 6px 12px -4px #222",
    border: `2px solid ${theme.palette.primary.main}`,
    borderTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  groupLabel: {
    color: theme.palette.primary.main,
    lineHeight: "18px",
    paddingTop: theme.spacing(1.2),
  },
}));

type Props = {
  options: RxLog[];
  selection: RxLog[];
  selectionHandler: (selection: RxLog[]) => void;
  serialColorScale: d3.ScaleOrdinal<string, string>;
  helperText?: string;
};

function LogFileSelector({
  options,
  selection,
  selectionHandler,
  serialColorScale,
  helperText,
}: Props) {
  const classes = useStyles();

  return (
    <FlexCol>
      <Autocomplete
        fullWidth
        multiple
        disableCloseOnSelect
        disableClearable
        size="small"
        classes={classes}
        options={options}
        value={selection}
        onChange={(_, selectedOptions) => selectionHandler(selectedOptions)}
        renderOption={(option, { selected }) => (
          <>
            <Checkbox size="small" checked={selected} />
            <Typography variant="body2">{option.fileName}</Typography>
          </>
        )}
        getOptionLabel={option => option.fileName}
        groupBy={option => option.rxName}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            label="RX log files"
            placeholder="Filter file names"
            helperText={helperText}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={index}
              size="small"
              icon={
                <ColorSample
                  style={{ margin: 0, marginLeft: 4 }}
                  color={serialColorScale(option.serial)}
                />
              }
              label={option.fileName}
              {...getTagProps({ index })}
              style={{ marginTop: 4 }}
            />
          ))
        }
      />
    </FlexCol>
  );
}

export default LogFileSelector;
