import { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "auto-bind/react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Chip,
  Divider,
  Link,
  Badge,
  Avatar,
  Popover,
  MenuList,
} from "@material-ui/core";
import {
  ArrowBack as IconArrowBack,
  ArrowDropDown as IconArrowDropDown,
  MoreVert as IconMoreVert,
  HighlightOff as IconHighlightOff,
  CheckCircleOutline as IconCheckCirleOutline,
  Add as IconAdd,
  Settings as SettingsIcon,
  CreateNewFolder as IconWorkspaceAdd,
  AccessTime as AccessTimeIcon,
  Help as HelpIcon,
  NewReleases as NewReleasesIcon,
} from "@material-ui/icons";

import { logout, openViewPP, reviewWhatsNew } from "../../redux/user/user-actions";
import history, { setAppPath } from "../../helpers/history";
import { createStudy } from "../../redux/study/study-actions";
import {
  selectWorkspace,
  acceptWorkspaceInvite,
  declineWorkspaceInvite,
} from "../../redux/workspace/workspace-actions";
import StudyAddDialog from "../study/StudyAddDialog";
import UTCOffsetDialog from "./UTCOffsetDialog";
import WorkspaceInvites from "../workspaces/WorkspaceInvites";
import HelpPopover from "../common/HelpPopover";
import { isInternalEmail } from "../../helpers/common";
import { CONTACT_SUPPORT_URL } from "../../constants";
import HelpingDialog from "./HelpingDialog";
import { ExperimentalFeaturesDialog, OPKeys, isFeatureEnabled } from "./ExperimentalFeaturesDialog";
import OPActivationDialog from "./OPActivationDialog";

const styles = theme => {
  return {
    logo: {
      height: "39px",
      cursor: "pointer",
      marginBottom: theme.spacing(0.5),
    },
    appBar: {
      textShadow: `1px 1px 0.5px ${theme.palette.primary.dark}`,
    },
    centerHeader: {
      display: "flex",
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    buttonChip: {
      color: "white",
      borderColor: "silver",
      marginRight: theme.spacing(1),
      "&:hover": {
        border: `1px solid ${theme.palette.common.coral}`,
      },
    },
    chip: {
      marginRight: theme.spacing(1),
      boxShadow: "1px 1px 3px -2px black",
    },
    chipDisabled: {
      marginRight: theme.spacing(1),
      color: "grey",
      borderColor: "grey",
    },
    headerButton: {
      border: "1px solid transparent",
      "&:hover": {
        border: `1px solid ${theme.palette.common.coral}`,
      },
    },
    iconButton: {
      size: "20px",
      height: "35px",
      width: "36px",
    },
    invitesButton: {
      color: "white",
      border: "1px solid transparent",
      "&:hover": {
        border: `1px solid ${theme.palette.common.coral}`,
      },
    },
    iconLinked: {
      paddingLeft: theme.spacing(0.5),
    },
    selectMenuAnchor: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      paddingLeft: theme.spacing(3),
      color: theme.palette.secondary.main,
    },
    selectLabel: {
      paddingRight: theme.spacing(1),
      textTransform: "uppercase",
    },
    selectContent: {
      color: theme.palette.common.white,
      fontWeight: 500,
    },
    iconAddStudy: {
      color: theme.palette.grey[500],
      marginLeft: -5,
      marginRight: 5,
    },
    menuSubtitle: {
      fontSize: 12,
      color: "grey",
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    disabled: {
      cursor: "initial",
      color: theme.palette.grey[500],
    },
    avatar: {
      backgroundColor: "rgb(139, 212, 223)",
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  };
};

class NavBar extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      profileMenuAnchor: null,
      workspaceMenuAnchor: null,
      studyMenuAnchor: null,
      addDialogOpen: false,
      workspaceInvitesOpen: false,
      workspaceInviteClicked: {},
      offsetDialogOpen: false,
      helpingDialogOpen: false,
      experimentalFeaturesDialogOpen: false,
      opDialogOpen: false,
    };
  }

  componentDidMount() {
    // Check for workspace invite clicked:
    const workspaceInviteClicked = {
      id: localStorage.getItem("workspaceInviteId"),
      email: localStorage.getItem("workspaceInviteEmail"),
    };
    if (workspaceInviteClicked.id) {
      this.setState({ workspaceInvitesOpen: true, workspaceInviteClicked });
    }
  }

  onStudyAdd(input) {
    this.props.dispatch(createStudy(input));
    this.setState({ addDialogOpen: false });
    this.closeStudyMenu();
  }

  handleProfileClick(event) {
    this.setState({ profileMenuAnchor: event.currentTarget });
  }

  handleWorkspaceClick() {
    this.setState({ workspaceMenuAnchor: document.getElementById("workspace-selector") });
  }

  handleWorkspaceClose() {
    this.setState({ workspaceMenuAnchor: null });
  }

  handleProfileClose() {
    this.setState({ profileMenuAnchor: null });
  }

  closeOPDialog() {
    this.setState({ opDialogOpen: false });
  }

  async logoutClicked() {
    await this.props.dispatch(logout());
    history.push("/learn");
  }

  async signInAgain() {
    await this.props.dispatch(logout());
    history.push("/signin");
  }

  selectStudy(studyId) {
    if (studyId) {
      history.push("?study=" + studyId);
    } else {
      history.push(history.location.pathname);
    }
    this.closeStudyMenu();
  }

  openStudyMenu() {
    this.setState({ studyMenuAnchor: document.getElementById("study-selector") });
  }

  closeStudyMenu() {
    this.setState({ studyMenuAnchor: null });
  }

  changeWorkspace(workspaceId) {
    this.props.dispatch(selectWorkspace(workspaceId, history.location.pathname));
    this.handleWorkspaceClose();
  }

  openWorkspaceInvites() {
    this.setState({ workspaceInvitesOpen: true });
  }

  closeWorkspaceInvites() {
    this.setState({ workspaceInvitesOpen: false, workspaceInviteClicked: {} });
    // if dialog has been dismissed, clear the invite clicked flag so the dialog doesn't keep popping up:
    localStorage.removeItem("workspaceInviteId");
    localStorage.removeItem("workspaceInviteEmail");
  }

  workspaceInvitesPending() {
    const workspaceInvites = this.props.user?.workspaceInvites;
    if (!workspaceInvites) return [];
    return workspaceInvites.filter(i => i.status === "PENDING");
  }

  handleAcceptInvite(inviteId) {
    this.props.dispatch(acceptWorkspaceInvite({ inviteId }));
    // this is a bit janky, but when a workspace invite has been clicked and
    // all invites actioned, the dialog annoyingly stays open. Awaiting the
    // dispatch didn't work, so ...
    if (this.workspaceInvitesPending().length === 1) {
      // if this is the last invite being actioned
      this.closeWorkspaceInvites();
    }
  }

  handleDeclineInvite(inviteId) {
    this.props.dispatch(declineWorkspaceInvite({ inviteId }));
    if (this.workspaceInvitesPending().length === 1) {
      // if this is the last invite being actioned
      this.closeWorkspaceInvites();
    }
  }
  getAvatarLetter() {
    const { user } = this.props;
    if (!user) return "";
    if (user?.displayName) {
      return user.displayName[0].toUpperCase();
    } else {
      return user.email ? user.email[0].toUpperCase() : "";
    }
  }
  render() {
    const {
      classes,
      sysAdminMode,
      workspaceMode,
      selectedWorkspace,
      workspaces,
      networkError,
      studies,
      selectedStudy,
      user,
      isUserAdmin,
      isUserExternal,
      studiesLoaded,
    } = this.props;

    const {
      profileMenuAnchor,
      studyMenuAnchor,
      workspaceMenuAnchor,
      addDialogOpen,
      workspaceInvitesOpen,
      workspaceInviteClicked,
      offsetDialogOpen,
      helpingDialogOpen,
      experimentalFeaturesDialogOpen,
    } = this.state;

    const opEnabled = isFeatureEnabled(OPKeys);

    const workspaceInvitesPending = this.workspaceInvitesPending();
    return (
      <>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar style={{ minHeight: 48 }}>
            {!networkError && (
              <>
                <img
                  src="/img/fathom-central-neg-sm.png"
                  alt=""
                  onClick={() => setAppPath("/home")}
                  className={classes.logo}
                />
                {selectedWorkspace && !workspaceMode && !sysAdminMode && (
                  <>
                    <Tooltip title="Select a workspace">
                      <div className={classes.selectMenuAnchor} onClick={this.handleWorkspaceClick}>
                        <Typography className={classes.selectLabel}>Workspace:</Typography>
                        <Typography className={classes.selectContent}>
                          {selectedWorkspace.name}
                        </Typography>
                        <IconArrowDropDown id="workspace-selector" />
                      </div>
                    </Tooltip>

                    {workspaceInvitesPending.length > 0 && (
                      <IconButton
                        onClick={() => this.openWorkspaceInvites()}
                        className={classes.invitesButton}
                        size="small"
                      >
                        <Badge
                          aria-label="Workspace Invites"
                          color="error"
                          variant="dot"
                          badgeContent={workspaceInvitesPending.length}
                        >
                          <Tooltip title="You have invitations to shared workspace">
                            <IconWorkspaceAdd />
                          </Tooltip>
                        </Badge>
                      </IconButton>
                    )}
                  </>
                )}

                {!workspaceMode && !sysAdminMode && studiesLoaded && (
                  <Tooltip title="Select a study within this workspace">
                    <div className={classes.selectMenuAnchor} onClick={this.openStudyMenu}>
                      <Typography className={classes.selectLabel}>Study:</Typography>
                      <Typography className={classes.selectContent}>
                        {selectedStudy ? selectedStudy.name : "Not selected"}
                      </Typography>
                      <IconArrowDropDown id="study-selector" />
                    </div>
                  </Tooltip>
                )}
                {sysAdminMode && (
                  <>
                    <IconButton
                      aria-label="Back to Home"
                      color="inherit"
                      size="small"
                      className={classes.headerButton}
                      style={{ margin: 8 }}
                      onClick={() => setAppPath("/home")}
                    >
                      <Tooltip title="Exit SysAdmin mode">
                        <IconArrowBack />
                      </Tooltip>
                    </IconButton>
                    <Typography variant="h6">SysAdmin Mode</Typography>
                  </>
                )}
              </>
            )}
            <div className={classes.centerHeader}></div>

            <Tooltip title={"Click to change UTC Offset display preference"}>
              <Chip
                label={this.props.selectedOffset}
                className={classes.buttonChip}
                variant="outlined"
                clickable
                color="secondary"
                icon={<AccessTimeIcon color="inherit" />}
                onClick={() => this.setState({ offsetDialogOpen: true })}
                onDelete={() => this.setState({ offsetDialogOpen: true })}
                deleteIcon={<IconMoreVert />}
              />
            </Tooltip>
            <Tooltip title={"What's new?"}>
              <NewReleasesIcon
                cursor="pointer"
                onClick={() => this.props.dispatch(reviewWhatsNew())}
                color="secondary"
                size="small"
                className={classes.iconButton}
              />
            </Tooltip>

            <HelpIcon
              cursor="pointer"
              onClick={() => this.setState({ helpingDialogOpen: true })}
              color="secondary"
              size="small"
              className={classes.iconButton}
            />

            <IconButton
              onClick={this.handleProfileClick}
              size="small"
              className={classes.headerButton}
            >
              <Avatar className={classes.avatar}>{this.getAvatarLetter()}</Avatar>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Menu
          anchorEl={workspaceMenuAnchor}
          getContentAnchorEl={null} // to get menu below
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={Boolean(workspaceMenuAnchor)}
          onClose={this.handleWorkspaceClose}
        >
          <Typography className={classes.menuSubtitle}> Private </Typography>
          {workspaces
            .filter(w => w.isPersonal === true)
            .map(w => (
              <MenuItem key={w.id} onClick={() => this.changeWorkspace(w.id)}>
                {w.name}
              </MenuItem>
            ))}
          <Divider />
          <Typography className={classes.menuSubtitle}> Shared </Typography>
          {workspaces
            .filter(w => w.isPersonal === false && !w.isDeleted)
            .map(w => (
              <MenuItem key={w.id} onClick={() => this.changeWorkspace(w.id)}>
                {w.name}
              </MenuItem>
            ))}
          <Divider />

          <MenuItem
            onClick={() => {
              history.push("/workspaces");
              this.handleWorkspaceClose();
            }}
          >
            <SettingsIcon style={{ marginRight: 8, marginTop: 8 }} />
            <Typography style={{ marginTop: 8 }}> Manage workspaces</Typography>
          </MenuItem>
        </Menu>
        <Popover
          open={Boolean(profileMenuAnchor)}
          anchorEl={profileMenuAnchor}
          onClose={this.handleProfileClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 16 }}
          >
            <Avatar
              style={{ height: 60, width: 60, backgroundColor: "rgb(139, 212, 223)", fontSize: 30 }}
            >
              {this.getAvatarLetter()}
            </Avatar>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: 8,
              }}
            >
              {user.displayName === user.email ? (
                <Typography style={{ fontSize: 16 }}>{user?.email}</Typography>
              ) : (
                <>
                  <Typography style={{ fontSize: 24 }}> {user?.displayName}</Typography>
                  <Typography style={{ fontSize: 14 }}> {user.email}</Typography>
                </>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
              <Typography style={{ fontSize: 10, marginTop: 6 }}>Billing account linked</Typography>
              {user.sysproContactId ? (
                <IconCheckCirleOutline
                  className={classes.iconLinked}
                  style={{ color: "#4BB543" }}
                />
              ) : (
                <IconHighlightOff className={classes.iconLinked} color="error" />
              )}
            </div>
            <HelpPopover
              tooltip="What does linking a billing account do?"
              maxWidth={500}
              iconSize={16}
              helpContent={
                <>
                  <Typography gutterBottom>
                    If your billing account is linked any devices you’ve purchased from Innovasea
                    should be visible in the devices tab
                  </Typography>
                  <Typography>
                    However, it is possible that the email address used to log in is different from
                    the one on the billing account and we could not automatically link the accounts.
                    Please <a href={CONTACT_SUPPORT_URL}>Contact Support</a> if you suspect this is
                    the case.
                  </Typography>
                </>
              }
            />
          </div>
          <MenuList>
            <Divider />
            <MenuItem onClick={this.logoutClicked}>Log out</MenuItem>

            <Divider />
            <Link onClick={() => this.setState({ helpingDialogOpen: true })}>
              <MenuItem>Help</MenuItem>
            </Link>
            <Link onClick={() => this.props.dispatch(openViewPP())}>
              <MenuItem>Privacy policy</MenuItem>
            </Link>

            <Divider />
            <Link href="/learn">
              <MenuItem>More Fathom products</MenuItem>
            </Link>
            {isUserAdmin && [
              <Divider key="1" />,
              <MenuItem
                key="2"
                onClick={() => {
                  this.handleProfileClose();
                  setAppPath("/admin/users");
                }}
              >
                SysAdmin mode
              </MenuItem>,
            ]}
            {(!isUserExternal || isUserAdmin) && [
              <MenuItem
                key="3"
                onClick={() => this.setState({ experimentalFeaturesDialogOpen: true })}
              >
                Experimental features
              </MenuItem>,
            ]}
            {opEnabled && (
              <MenuItem key="4" onClick={() => this.setState({ opDialogOpen: true })}>
                OP activation
              </MenuItem>
            )}
          </MenuList>
        </Popover>
        <Menu
          anchorEl={studyMenuAnchor}
          getContentAnchorEl={null} // to get menu below
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={Boolean(studyMenuAnchor)}
          onClose={this.closeStudyMenu}
        >
          {studies.map(study => (
            <MenuItem key={study.id} onClick={() => this.selectStudy(study.id)} dense>
              {study.name}
            </MenuItem>
          ))}
          {selectedStudy && (
            <MenuItem onClick={() => this.selectStudy(null)} dense>
              <em>Unselect</em>
            </MenuItem>
          )}
          <Divider />
          <MenuItem onClick={() => this.setState({ addDialogOpen: true })} dense>
            <IconAdd fontSize="small" className={classes.iconAddStudy} />
            Add study
          </MenuItem>
        </Menu>
        <StudyAddDialog
          open={addDialogOpen}
          onClose={() => this.setState({ addDialogOpen: false })}
          onSubmit={this.onStudyAdd}
        />
        <UTCOffsetDialog
          open={offsetDialogOpen}
          onClose={() => this.setState({ offsetDialogOpen: false })}
          changeWorkspace={this.changeWorkspace}
        />
        <HelpingDialog
          open={helpingDialogOpen}
          onClose={() => this.setState({ helpingDialogOpen: false })}
        />
        <ExperimentalFeaturesDialog
          isOpen={experimentalFeaturesDialogOpen}
          onClose={() => this.setState({ experimentalFeaturesDialogOpen: false })}
        />
        {opEnabled && (
          <OPActivationDialog open={this.state.opDialogOpen} cancelAction={this.closeOPDialog} />
        )}
        {user.email && (workspaceInvitesPending.length > 0 || workspaceInviteClicked.id) && (
          <WorkspaceInvites
            open={workspaceInvitesOpen}
            handleClose={this.closeWorkspaceInvites}
            handleAcceptInvite={inviteId => this.handleAcceptInvite(inviteId)}
            handleDeclineInvite={inviteId => this.handleDeclineInvite(inviteId)}
            workspaceInvites={workspaceInvitesPending}
            userEmail={user.email}
            workspaceInviteClicked={workspaceInviteClicked}
            signInAgain={this.signInAgain}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ user, study, workspaces }) => {
  return {
    user: user.user,
    selectedOffset: user.selectedOffset,
    isUserAdmin: user.isAdmin,
    isUserExternal: !isInternalEmail(user.user.email),
    studies: study.studies,
    studiesLoaded: study.isLoaded,
    selectedStudy: study.studies.find(s => s.id === study.selectedId),
    selectedWorkspace: workspaces.selectedWorkspace,
    workspaces: workspaces.workspaces,
  };
};

NavBar.propTypes = {
  user: PropTypes.object.isRequired,
  isUserAdmin: PropTypes.bool,
  isUserExternal: PropTypes.bool,
  adminMode: PropTypes.bool,
  selectedStudy: PropTypes.object,
  studies: PropTypes.array,
  studiesLoaded: PropTypes.bool,
  networkError: PropTypes.bool,
  workspaces: PropTypes.array,
  workspaceMode: PropTypes.bool,
  selectedWorkspace: PropTypes.object,
};

export default withStyles(styles)(connect(mapStateToProps)(NavBar));
