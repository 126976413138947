import { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "auto-bind/react";
import { connect } from "react-redux";
import { compose } from "redux";
import memoize from "memoize-one";
import { TableSearchBar, SpinnerInnovasea } from "../fathom-brella";
// MATERIAL UI
import { withStyles } from "@material-ui/core/styles";
import {
  ButtonGroup,
  Button,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from "@material-ui/core";
import {
  Add as IconAdd,
  Delete as IconDelete,
  Link as IconLink,
  Description as IconDescription,
  LinkOff as IconLinkOff,
} from "@material-ui/icons";

// COMPONENTS
import DeploymentMap from "../components/deployments/DeploymentMap";
import DeploymentTable from "../components/deployments/DeploymentTable";
import DeploymentDetailsPanel from "../components/deployments/DeploymentDetailsPanel";
import DeploymentTimeline from "../components/deployments/DeploymentTimeline";
import AddDeploymentDialog from "../components/deployments/AddDeploymentDialog";
import DialogWrapper from "../components/common/DialogWrapper";
import SpecUploadDialogs from "../components/common/SpecUploadDialogs/SpecUploadDialogs";
import MenuBar from "../components/common/MenuBar";
import { latestPosition } from "../components/deployments/utils";
import IconMenuHoriz from "../components/common/IconMenuHoriz";
import StudyEmptyMessage from "../components/study/StudyEmptyMessage";

import { studyVisibleObjects } from "../helpers/study";
import { formatDeviceOptions } from "../helpers/device";

import StudyChooseDialog from "./StudyChooseDialog";
import StudyUnlinkDialog from "./StudyUnlinkDialog";
import StudyLinkDialog from "./StudyLinkDialog";
import ResizableSplitPanel from "../components/common/ResizableSplitPanel";
import DeleteDialogButtons from "../components/common/DeleteDialogButtons";
import DeleteWarning from "../components/common/DeleteWarning";

// redux
import { readDeviceList } from "../redux/devices/devices-actions";
import { readDeploymentList, deleteDeployments } from "../redux/deployments/deployments-actions";
import { removeFromStudy } from "../redux/study/study-actions";
import { readAnimalList } from "../redux/animals/animals-actions";

const styles = theme => ({
  panel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  tablePanel: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  mapTimelinePanel: {
    height: "100%",
    position: "relative",
  },
  detailsPanel: {
    borderTop: "2px solid lightgray",
    display: "flex",
  },
  subPanel: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    padding: theme.spacing(0.5),
  },
  tab: {
    textTransform: "none",
    minWidth: 70,
  },
  deployTableContainer: {
    flexGrow: 1,
    height: "100%",
  },
  adminMenu: {
    paddingTop: theme.spacing(1.25),
    paddingRight: theme.spacing(1),
  },
  detailsPlaceHolder: {
    padding: theme.spacing(1),
  },
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  toolbarCaption: {
    fontSize: "small",
  },
  toolbarGroup: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
});

class Deployments extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      selection: [],
      viewMode: "map", // map | timeline
      addDialogOpen: false,
      deleteConfirmDialogOpen: false,
      searchText: "",
      activeTab: "all",
      scrollToId: null,
      specUploadOpen: false,
      studyChooseOpen: false,
      studyLinkOpen: false,
      studyUnlinkOpen: false,
    };

    this.getDeploymentsWithPositions = memoize(deployments =>
      deployments.filter(d => Boolean(d.latestPosition))
    );
  }

  componentDidMount() {
    this.fetchData();

    // Page load & wants a deployment initially selected
    if (this.props.location && this.props.location.search) {
      const qParams = new URLSearchParams(this.props.location.search);
      const selectedDeployId = qParams.get("initDeployId");
      qParams.delete("initDeployId");
      if (selectedDeployId) {
        this.setState({ scrollToId: selectedDeployId, selection: [selectedDeployId] });
      }
      this.props.history.replace({
        search: qParams.toString() ? `?${qParams.toString()}` : null,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedWorkspace !== prevProps.selectedWorkspace ||
      this.props.selectedStudy !== prevProps.selectedStudy
    ) {
      this.setState({ selection: [] });
    }
    // re-fetch data when workspace changes:
    if (this.props.selectedWorkspace !== prevProps.selectedWorkspace) {
      this.fetchData();
    }
  }

  fetchData() {
    this.props.dispatch(readDeploymentList());
    this.props.dispatch(readDeviceList());
    this.props.dispatch(readAnimalList()); // needed for filtering device options by tagged state
  }

  openDeleteConfirmDialog() {
    this.setState({ deleteConfirmDialogOpen: true });
  }

  handleDelete() {
    this.props.dispatch(deleteDeployments(this.state.selection));
    this.setState({ deleteConfirmDialogOpen: false });
  }

  actionUnlink() {
    this.props.dispatch(
      removeFromStudy(
        { studyId: this.props.selectedStudy.id, deploymentIds: this.state.selection },
        true
      )
    );
    this.setState({ deleteConfirmDialogOpen: false });
  }
  selectSingleDeployment(id) {
    this.setState({ selection: [id], scrollToId: id });
  }

  handleRemoveFromStudy(studyId, deploymentId) {
    if (studyId && deploymentId) {
      this.props.dispatch(removeFromStudy({ studyId, deploymentIds: [deploymentId] }, true));
    }
    return;
  }

  visibleDeploymentList() {
    const { deployments } = this.props;
    const { activeTab } = this.state;

    const date = new Date();

    return deployments.filter(
      de =>
        activeTab === "all" ||
        (activeTab === "active" && (de.end === null || de.end > date.toISOString()))
    );
  }

  render() {
    const {
      deployments,
      classes,
      deploymentsLoading,
      deviceOptions,
      stations,
      selectedStudy,
      selectedTimezone,
    } = this.props;
    const {
      selection,
      viewMode,
      addDialogOpen,
      searchText,
      deleteConfirmDialogOpen,
      scrollToId,
      specUploadOpen,
      studyChooseOpen,
      studyLinkOpen,
      activeTab,
      studyUnlinkOpen,
    } = this.state;

    const deploymentsSelected = [];
    selection.forEach(deploymentId => {
      const deployment = deployments.find(deployment => deployment.id === deploymentId);
      if (deployment) {
        deploymentsSelected.push(deployment);
      }
    });

    if (deploymentsLoading) {
      return (
        <div className={classes.loadingContainer}>
          <SpinnerInnovasea />
        </div>
      );
    }

    const deploymentsWithPositions = this.getDeploymentsWithPositions(deployments);
    const devicesToDisplay = this.visibleDeploymentList();

    return (
      <>
        <ResizableSplitPanel
          direction="horizontal"
          firstMin={700}
          firstContent={
            <div className={classes.tablePanel}>
              <MenuBar
                contentLeft={
                  <IconMenuHoriz
                    items={[
                      {
                        labelAbove: "Add",
                        icon: <IconAdd />,
                        onClick: () => this.setState({ addDialogOpen: true }),
                        hoverText: "Add new deployments",
                      },
                      {
                        labelAbove: "Import",
                        icon: <IconDescription />,
                        onClick: () => this.setState({ specUploadOpen: true }),
                        hoverText: "Import deployments from file",
                      },
                      {
                        labelAbove: "Delete",
                        icon: <IconDelete />,
                        disabled: deploymentsSelected.length === 0,
                        hoverText: "Delete the selected deployments",
                        onClick: () => this.setState({ deleteConfirmDialogOpen: true }),
                      },
                      { divider: true },
                      {
                        // (only shown outside of study context) for selecting a study to link to the currently selected deployments
                        visible: !selectedStudy,
                        labelAbove: "Link",
                        icon: <IconLink />,
                        onClick: () => this.setState({ studyChooseOpen: true }),
                        disabled: selection.length === 0,
                        hoverText:
                          selection.length === 0
                            ? "Select deployments to link to a study"
                            : "Link the selected deployments to a study",
                      },
                      {
                        // (only shown within a study context) for selecting deployments to link to the currently selected study
                        visible: Boolean(selectedStudy),
                        labelAbove: "Link",
                        icon: <IconLink />,
                        onClick: () => this.setState({ studyLinkOpen: true }),
                        hoverText: "Link deployments to this study from the workspace",
                      },
                      {
                        // (only shown within a study context) for unlinking currently selected deployments from the currently selected study
                        visible: Boolean(selectedStudy),
                        labelAbove: "Unlink",
                        icon: <IconLinkOff />,
                        onClick: () => this.setState({ studyUnlinkOpen: true }),
                        disabled: selection.length === 0,
                        hoverText:
                          selection.length === 0
                            ? "Select deployments to unlink from this study"
                            : "Unlink selected deployments from this study",
                      },
                    ]}
                  />
                }
                contentRight={
                  <>
                    <TableSearchBar
                      searchText={searchText}
                      handleSearch={searchText => {
                        this.setState({ searchText });
                      }}
                    />
                    <div className={classes.toolbarGroup}>
                      <FormLabel component="legend" className={classes.toolbarCaption}>
                        Deployment status
                      </FormLabel>
                      <RadioGroup
                        row
                        name="deviceClasses"
                        value={activeTab}
                        onChange={e => this.setState({ activeTab: e.target.value })}
                      >
                        <FormControlLabel
                          value="all"
                          control={<Radio size="small" />}
                          label="All"
                        />
                        <FormControlLabel
                          value="active"
                          control={<Radio size="small" />}
                          label="Active"
                        />
                      </RadioGroup>
                    </div>
                  </>
                }
              />
              {selectedStudy && deployments.length <= 0 ? (
                <div style={{ padding: 20 }}>
                  <StudyEmptyMessage
                    typeName="deployments"
                    studyName={selectedStudy.name}
                    onLinkClicked={() => this.setState({ studyLinkOpen: true })}
                    onNewClicked={() => this.setState({ addDialogOpen: true })}
                  />
                </div>
              ) : (
                <div className={classes.deployTableContainer}>
                  <DeploymentTable
                    deployments={devicesToDisplay}
                    stations={stations}
                    searchText={searchText}
                    selection={selection}
                    setSelection={selection =>
                      this.setState({ selection: selection, scrollToId: null })
                    }
                    scrollToId={scrollToId}
                    selectedTimezone={selectedTimezone}
                    handleRemoveFromStudy={!selectedStudy && this.handleRemoveFromStudy}
                  />
                </div>
              )}
              {deployments.length > 0 && (
                <DeploymentDetailsPanel
                  deploymentsSelected={deploymentsSelected}
                  deviceOptions={deviceOptions}
                />
              )}
            </div>
          }
          secondContent={
            <div className={classes.panel + " " + classes.mapTimelinePanel}>
              <ButtonGroup
                color="primary"
                aria-label="contained primary button group"
                style={{ position: "absolute", margin: "10px", zIndex: 5 }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.setState({ viewMode: "map" })}
                >
                  Map
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.setState({ viewMode: "timeline" })}
                >
                  Timeline
                </Button>
              </ButtonGroup>

              {viewMode === "map" ? (
                <DeploymentMap
                  deployments={deploymentsWithPositions}
                  selectedDeploymentIds={selection}
                  handleSelectDeployment={this.selectSingleDeployment}
                />
              ) : (
                <DeploymentTimeline
                  deployments={deployments}
                  selectedDeploymentIds={selection}
                  handleSelectDeployment={this.selectSingleDeployment}
                />
              )}
            </div>
          }
        />
        <AddDeploymentDialog
          stations={stations}
          open={addDialogOpen}
          toggle={() => this.setState({ addDialogOpen: false })}
          deviceOptions={deviceOptions}
          studyId={selectedStudy && selectedStudy.id}
        />
        <SpecUploadDialogs
          open={specUploadOpen}
          dataType="deployment"
          close={() => this.setState({ specUploadOpen: false })}
        />
        <DialogWrapper
          open={deleteConfirmDialogOpen}
          title="Delete Deployments"
          cancelAction={() => this.setState({ deleteConfirmDialogOpen: false })}
          okAction={this.handleDelete}
          buttons={({ cancelAction, okAction }) => (
            <DeleteDialogButtons
              cancelAction={cancelAction}
              okAction={okAction}
              isStudy={Boolean(selectedStudy)}
              unlinkAction={this.actionUnlink}
            />
          )}
        >
          <Typography gutterBottom>
            Are you sure you want to delete {selection.length} deployment
            {selection.length > 1 ? "s" : ""}?
          </Typography>
          {Boolean(selectedStudy) && <DeleteWarning type="deployment" />}
        </DialogWrapper>

        {studyChooseOpen && (
          <StudyChooseDialog
            open={true}
            handleClose={() => this.setState({ studyChooseOpen: false })}
            objectType="deploymentIds"
            objectTypeLabel="Deployment"
            objectIds={selection}
          />
        )}

        {studyLinkOpen && (
          <StudyLinkDialog
            open={true}
            handleClose={() => this.setState({ studyLinkOpen: false })}
            objectType="deploymentIds"
            objectTypeLabel="Deployment"
            selectedStudyId={selectedStudy?.id}
          />
        )}

        {studyUnlinkOpen && (
          <StudyUnlinkDialog
            open={true}
            handleClose={() => this.setState({ studyUnlinkOpen: false })}
            objectType="deploymentIds"
            objectTypeLabel="Deployment"
            objectIds={selection}
            selectedStudyId={selectedStudy?.id}
          />
        )}
      </>
    );
  }
}

Deployments.propTypes = {
  deployments: PropTypes.array,
  stations: PropTypes.array,
  deploymentPositions: PropTypes.array,
  deploymentsLoading: PropTypes.bool,
  deviceOptions: PropTypes.array,
  selectedWorkspace: PropTypes.object,
  selectedStudy: PropTypes.object,
  history: PropTypes.any,
  location: PropTypes.any,
};

const mapStateToProps = ({ deployments, user, devices, study, workspaces, animals }) => {
  const studySelectedId = study.selectedId;
  const selectedStudy =
    studySelectedId && study.studies.find(study => study.id === studySelectedId);

  const stationList = deployments.stations || [];
  const _deployments = studyVisibleObjects({
    studies: study.studies,
    selectedStudy,
    objects: deployments.deployments,
    objectKey: "deployments",
    transformedProperties: deployment => ({
      latestPosition: latestPosition(deployment),
      hasConflict: hasConflict(deployment, studySelectedId),
    }),
  });

  // Format device options based on status:
  const deviceOptions = formatDeviceOptions({
    devices: devices.devices || [],
    selectedStudy,
    deployments: _deployments,
    animals:
      selectedStudy && animals.animals
        ? selectedStudy.animals?.map(animalInStudy =>
            animals.animals?.find(animal => animal.id === animalInStudy.id)
          )
        : animals.animals,
  });

  return {
    deployments: _deployments,
    stations: stationList,
    deploymentsLoading: deployments.loading,
    deviceOptions,
    selectedStudy,
    selectedTimezone: user.selectedTimezone,
    selectedWorkspace: workspaces.selectedWorkspace,
  };
};

function hasConflict(deployment, selectedStudyId) {
  const attachmentHasConflict = selectedStudyId
    ? attachment => attachment.studyConflicts.find(sc => sc.studyId === selectedStudyId)
    : attachment => Boolean(attachment.conflict);
  const positionHasConflict = position => position.hasConflict;

  return (
    deployment.deviceAttachments.some(attachmentHasConflict) ||
    deployment.positions.some(positionHasConflict)
  );
}

export default compose(connect(mapStateToProps), withStyles(styles))(Deployments);
