import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// MATERIAL UI
import { withStyles } from "@material-ui/core/styles";
// COMPONENTS
import NavBar from "../components/common/NavBar";
import AppNavBar from "../components/common/AppNavBar";
import ErrorBoundary from "../components/common/ErrorBoundary";

const styles = () => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "100%",
  },
  content: {
    display: "flex",
    flexGrow: 1,
    position: "relative",
    overflowY: "hidden",
  },
});

const regularTabs = studySelected => [
  {
    title: "Home",
    route: "/home",
    toolTip: "See overview of your account and manage studies",
  },
  {
    title: "Files",
    route: "/files",
    toolTip: "Manage your files in the cloud",
  },
  {
    title: "Devices",
    route: "/devices",
    toolTip: "Manage your devices in the cloud",
  },
  {
    title: "Animals",
    route: "/animals",
    toolTip: "Manage your animals in the cloud",
  },
  {
    title: "Deployments",
    route: "/deployments",
    toolTip: "Manage the deployment of your receivers, transmitters, and other equipment",
  },
  {
    title: "Detections",
    route: "/detections",
    toolTip: "View your detections",
  },
  {
    title: "Detection Map",
    route: "/detection-map",
    toolTip: studySelected
      ? "View a map of my study detections"
      : "Select a study to view a map of detections",
    requiresStudy: true,
  },
  {
    title: "Receiver Diagnostics",
    route: "/rx-diag",
    toolTip: "View diagnostics for a receiver log file",
  },
  {
    title: "Detection Analysis",
    route: "/detection-analysis",
    toolTip: "Analyze efficiency of detection over distance and time",
  },
];

const adminTabs = [
  {
    title: "Users",
    route: "/admin/users",
    toolTip: "Administration of Fathom Users",
  },
  {
    title: "Workspaces",
    route: "/admin/workspaces",
    toolTip: "Administration of all workspaces",
  },
  {
    title: "Analytics",
    route: "/admin/analytics",
    toolTip: "Statistics about app usage",
  },
  {
    title: "Pipeline",
    route: "/admin/pipeline",
    toolTip: "Visibility into file pipeline failures",
  },
];

if (process.env.REACT_APP_LIVE2_WS_URL && process.env.REACT_APP_LIVE2_API_URL) {
  adminTabs.push({
    title: "Live 2.0",
    route: "/admin/live-v2",
    toolTip: "Demo of live 2.0 system",
  });
}

function MainLayout({ studySelected, history, classes, children }) {
  const sysAdminMode = history.location.pathname.startsWith("/admin");
  const workspaceMode = history.location.pathname.startsWith("/workspaces");
  const networkError = history.location.pathname.startsWith("/error");

  return (
    <div className={classes.root}>
      <ErrorBoundary>
        <NavBar
          sysAdminMode={sysAdminMode}
          workspaceMode={workspaceMode}
          networkError={networkError}
        />

        {!networkError && !workspaceMode && (
          <AppNavBar
            studySelected={studySelected}
            appPath={history.location.pathname + history.location.search}
            query={history.location.search}
            tabs={sysAdminMode ? adminTabs : regularTabs(studySelected)}
            sysAdminMode={sysAdminMode}
          />
        )}

        <div className={classes.content}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
      </ErrorBoundary>
    </div>
  );
}

MainLayout.propTypes = {
  studySelected: PropTypes.bool,
  history: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    studySelected: Boolean(state.study.selectedId),
  };
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(MainLayout)));
