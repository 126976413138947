import { forwardRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress, Typography, Card, IconButton } from "@material-ui/core";
import { SnackbarContent } from "notistack";
import { useSelector } from "react-redux";
import FlexCol from "../common/FlexCol";
import FlexRow from "../common/FlexRow";
import WarningIcon from "../common/WarningIcon";
import { Close } from "@material-ui/icons";
import { useThunkDispatch } from "../../redux/common";
import { clearDownloadDetection } from "../../redux/detections/detection-actions";

const useStyles = makeStyles(theme => ({
  linearBarColourPrimary: {
    backgroundColor: theme.palette.primary.dark,
  },
  linearColourPrimary: {
    backgroundColor: theme.palette.secondary.light,
    opacity: 0.7,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    width: 350,
    backgroundColor: "#2196f3",
    padding: theme.spacing(1),
    color: "white",
  },
}));

const STAGE_DESC = {
  PREPARE: "Preparing",
  DOWNLOAD: "Downloading",
};

const KNOWN_ERRORS = [
  { match: "S3: Memory limit", message: "memory limit exceeded" },
  { match: "S3: aborted", message: "aborted in transit" },
  { match: "Timeout", message: "timed out" },
];
function translateErrorMsg(errorMsg = "") {
  KNOWN_ERRORS.forEach(({ match, message }) => {
    if (errorMsg?.constructor === String && errorMsg.includes(match)) {
      return message;
    }
  });
  return "unknown error";
}

export const DetectionDownloadSnack = forwardRef((_, ref) => {
  const classes = useStyles();
  const dispatch = useThunkDispatch();
  const { dlState } = useSelector(({ detection }) => {
    const { downloads } = detection;
    const dlState = Object.keys(downloads).map(jobId => downloads[jobId]);
    return { dlState };
  });

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Card className={classes.card}>
        <FlexCol>
          {dlState.map(({ jobId, detCount, percentComplete, stage, errorMsg }) => (
            <FlexCol
              key={jobId}
              marginLevel={1}
              paddingLevel={1}
              style={{ backgroundColor: "rgba(255,255,255,0.1)" }}
            >
              <Typography variant="body2">
                Downloading {detCount?.toLocaleString() || ""} detections
              </Typography>
              <FlexRow itemSpacing={1} vAlign="center" fullWidth>
                <Typography variant="caption">{STAGE_DESC[stage] || stage}</Typography>
                <div style={{ flexGrow: 1 }}>
                  {
                    /* prevent "shrinking" effect when prepare complete -> download start by using separate components (this won't be necessary once up to date mui)*/
                    stage === "PREPARE" && (
                      <LinearProgress
                        classes={{
                          barColorPrimary: classes.linearBarColourPrimary,
                          colorPrimary: classes.linearColourPrimary,
                        }}
                        variant={"determinate"}
                        value={percentComplete}
                      />
                    )
                  }
                  {stage === "DOWNLOAD" && (
                    <LinearProgress
                      classes={{
                        barColorPrimary: classes.linearBarColourPrimary,
                        colorPrimary: classes.linearColourPrimary,
                      }}
                      variant={"determinate"}
                      value={percentComplete}
                    />
                  )}
                  {stage === "ERROR" && (
                    <span>
                      <WarningIcon /> Download failed: {translateErrorMsg(errorMsg)}
                    </span>
                  )}
                </div>
              </FlexRow>
              <IconButton
                size="small"
                style={{ position: "absolute", top: 0, right: 0 }}
                onClick={() => dispatch(clearDownloadDetection(jobId))}
              >
                <Close />
              </IconButton>
            </FlexCol>
          ))}
        </FlexCol>
      </Card>
    </SnackbarContent>
  );
});

DetectionDownloadSnack.propTypes = {
  message: PropTypes.string,
  variant: PropTypes.string,
  snackbarKey: PropTypes.string,
};
